import React, { Component } from "react";
import axios from "../../apiConnections/axios";
import LoadingPost from "../../images/loading.jpg";
// import playbutton from '../../Images/play-button.png';
// import WatchEpisodePlaceholderComponent from '../Placeholders/WatchEpisodePlaceholderComponent';

import Pagination from "react-js-pagination";
// import Img from 'react-cool-img';
// import '../../CustomCss/episodesLoad.css';
// import '../../CustomCss/SingleReadCategory.css';
import _ from "lodash";
import GridComponent2 from "./GridComponent2";
export default class SpecialArticle404 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      LoadingArticles: true,
      Articles: [],
      ArticlesTotalPages: 1,
      ArticlesActivePage: 1,
      itemscount: 9,
      pagination: "no",
      operator: "or",
    };
  }

  loadLatestPosts = async (PostID) => {
    await axios
      .get(
        `/get-releted-post-from-contextly/?PostId=${PostID}&timestamp=${new Date().getTime()}`,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json;charset=UTF-8",
          },
        }
      )
      .then((res) => {
        this.setState({
          Articles: res.data.data,
          LoadingArticles: false,
          ArticlesTotalPages: res.data.pagecount,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };
  componentDidMount() {
    this.loadLatestPosts("contextly_articles_section");
    this.setState({
      itemscount: this.props.itemscount,
      operator: this.props.operator,
    });
  }
  handleArticlesPageChange(pageNumber) {
    this.loadPosts(1);
    this.setState({ LoadingArticles: true, ArticlespageNumber: pageNumber });
  }

  render() {
    const { LoadingArticles, Articles, pagination, itemscount } = this.state;
    return (
      <>
        {Articles && Articles !== null ? (
          <>
            <h2 className="blue-text underline">{this.props.title}</h2>
          </>
        ) : (
          ``
        )}

        <div className="row shortcode">
          <GridComponent2
            itemsCount={itemscount}
            state={this.state}
            columns={3}
            pagination={false}
            excerpt={false}
            pageNumber="1"
            Posts={Articles}
            Loading={LoadingArticles}
            gridAds={false}
            clips={false}
            seriesSpecials={true}
          />
        </div>
        {pagination === "yes" &&
        Articles !== null &&
        !LoadingArticles &&
        Articles ? (
          <>
            <div className="center pt-2">
              <Pagination
                activePage={this.state.ArticlesActivePage}
                itemsCountPerPage={1}
                totalItemsCount={this.state.ArticlesTotalPages}
                pageRangeDisplayed={5}
                onChange={this.handleArticlesPageChange.bind(this)}
                itemClass="page-item"
                linkClass="page-link"
                innerClass="pagination watch-page-pagination"
                hideFirstLastPages
                prevPageText="<"
                nextPageText=">"
              />
            </div>
          </>
        ) : (
          ``
        )}
      </>
    );
  }
}
