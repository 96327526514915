import React, { Component } from "react";
import axios from "../../apiConnections/axios";
import { Link } from "react-router-dom";
import $ from "jquery";
let flagApi = false;
let color = "green";

class specialsCarousel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      Title: props.title,
      Loading: true,
    };
  }

  // CAROUSEL
  CarruselBehavior() {
    function Carousel() {
      let items = document.querySelectorAll(
        "#specialsCarousel2.carousel .carousel-item"
      );
      items.forEach((el) => {
        const minPerSlide = 6;
        let next = el.nextElementSibling;
        for (var i = 1; i < minPerSlide; i++) {
          if (!next) {
            // wrap carousel by using first child
            next = items[0];
          }
          let cloneChild = next.cloneNode(true);
          el.appendChild(cloneChild.children[0]);
          next = next.nextElementSibling;
        }
      });
    }

    function checkCarouselExistence() {
      if ($("#specialsCarousel2.carousel .carousel-item").length > 0) {
        Carousel();
      } else {
        setTimeout(checkCarouselExistence, 100);
      }
    }

    checkCarouselExistence();
  }

  componentDidMount = () => {
    if (!flagApi) {
      this.loadPosts();
      flagApi = true;
      this.CarruselBehavior();
    }
  };

  componentWillUnmount() {
    flagApi = false;
  }

  loadPosts = async () => {
    this.setState({ Loading: true });
    await axios
      .get(`/get-footer-slide-post?timestamp=${new Date().getTime()}`, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json;charset=UTF-8",
        },
      })
      .then((res) => {
        this.setState({ Posts: res.data.data, Loading: false });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  render() {
    const { Loading, Posts, Title } = this.state;
    return (
      <>
        <div className="row mx-auto my-auto justify-content-center mt-3">
          <div className="row mt-3">
            <div className="col-12">
              <h2 className="titleNew">{Title}</h2>
            </div>
          </div>
          <div
            id="specialsCarousel2"
            className="carousel carousel-dark slide"
            data-bs-ride="carousel"
          >
            <div className="carousel-indicators">
              {!Loading ? (
                Posts.slice(0, 1).map((post, i) => {
                  return (
                    <button
                      type="button"
                      data-bs-target="#specialsCarousel2"
                      data-bs-slide-to={i}
                      className="active"
                      key={i}
                    ></button>
                  );
                })
              ) : (
                <></>
              )}
              {!Loading ? (
                Posts.slice(1, Posts.length).map((post, i) => {
                  return (
                    <button
                      type="button"
                      data-bs-target="#specialsCarousel2"
                      data-bs-slide-to={i + 1}
                      key={i}
                    ></button>
                  );
                })
              ) : (
                <></>
              )}
            </div>
            <div className="carousel-inner" role="listbox">
              <>
                {!Loading ? (
                  Posts.slice(0, 1).map((post, i) => {
                    return (
                      <div className="carousel-item active " key={i}>
                        <div className="col-lg-3 col-md-4 col-12 p-md-2 p-3">
                          <Link to={`${post.image_link_to}`}>
                            <div className="card">
                              <img
                                style={{ border: `solid 2px ${color}` }}
                                className="img-fluid w-100 card-img-top squareImg"
                                src={post.post_image.medium_image_url}
                                alt={"LATV" + i}
                                width="1000px"
                                height="563px"
                              />
                            </div>
                          </Link>
                        </div>
                      </div>
                    );
                  })
                ) : (
                  <></>
                )}
                {!Loading ? (
                  Posts.slice(1, Posts.length).map((post, i) => {
                    return (
                      <div className="carousel-item " key={i}>
                        <div className="col-lg-3 col-md-4 col-12 p-md-2 p-3">
                          <Link to={`${post.image_link_to}`}>
                            <div className="card">
                              <img
                                style={{ border: `solid 2px ${color}` }}
                                className="img-fluid w-100 card-img-top squareImg"
                                src={post.post_image.medium_image_url}
                                alt={"LATV" + i}
                                width="1000px"
                                height="563px"
                              />
                            </div>
                          </Link>
                        </div>
                      </div>
                    );
                  })
                ) : (
                  <></>
                )}
              </>
            </div>
            {/* <a
              className="carousel-control-prev bg-transparent w-aut"
              href="#specialsCarousel2"
              role="button"
              data-bs-slide="prev"
            >
              <span
                className="carousel-control-prev-icon"
                aria-hidden="true"
              ></span>
            </a>
            <a
              className="carousel-control-next bg-transparent w-aut"
              href="#specialsCarousel2"
              role="button"
              data-bs-slide="next"
            >
              <span
                className="carousel-control-next-icon"
                aria-hidden="true"
              ></span>
            </a> */}
          </div>
        </div>
      </>
    );
  }
}
export default specialsCarousel;
