import React, { Component } from "react";
import ReactDOM from "react-dom";
import mediastaticpages from "../apiConnections/mediastaticpages";
import axiosstaticpages from "../apiConnections/axiosstaticpages";
import axios from "../apiConnections/axios";
import $ from "jquery";
// import ClipsLoadComponent from '../ShortCodes/ClipsLoadComponent';
// import EpisodesLoadComponent from '../ShortCodes/EpisodesLoadComponent';
// import PostsLoadComponent from '../ShortCodes/PostsLoadComponent';
// import Gallery4x4Component from '../Gallery4x4Component';
import "../seriesPage.css";
export default class SchedulePageComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      Loading: true,
      Loadingcss: true,
      title: "",
      content: "",
      renderedcontent: "",
      cssfile: "",
      mainPostCategory: "corporate",
      subCategories: [],
      articleTags: [],
      articleAuthor: "",
      articleTitle: "",
    };
  }
  componentDidMount() {
    this.loadData();
  }
  loadData = async () => {
    this.setState({ Loading: true });
    await axiosstaticpages
      .get(`/pages/?slug=schedule&timestamp=${new Date().getTime()}`, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json;charset=UTF-8",
        },
      })
      .then((res) => {
        this.setState({
          title: res.data[0].title.rendered,
          content: res.data[0].content.rendered,
          Loading: false,
        });
        axios
          .get(
            `/get-page-by-slug/?slug=schedule&timestamp=${new Date().getTime()}`,
            {
              headers: {
                Accept: "application/json",
                "Content-Type": "application/json;charset=UTF-8",
              },
            }
          )
          .then((res2) => {
            this.setState({
              cssfile: res2.data.data.cssfile,
              Loadingcss: false,
            });
          });
        // this.appendLotameScript();
      })
      .catch((error) => {
        console.log(error);
      });
  };
  /* Lotame Stuff*/
  createElementFromHTMLString(htmlString) {
    var div = document.createElement("div");
    div.innerHTML = htmlString.trim();
    // Change this to div.childNodes to support multiple top-level nodes.
    return div.firstChild;
  }

  appendLotameScript() {
    const scriptLotameTag = this.createElementFromHTMLString(this.lotameLoad());

    scriptLotameTag.defer = true;

    var div = document.createElement("div");
    div.innerHTML = scriptLotameTag;
    // console.log(document.getElementById('lotameTag'));
    $("#lotameTag").append(scriptLotameTag);
    // document.getElementById('lotameTag').appendChild(scriptLotameTag);
    // document.body.appendChild(div);
  }

  lotameLoad() {
    // console.log("lotame load")
    let path_name = window.location.pathname;
    let url = window.location.href;
    console.log(this.state.mainPostCategory);
    let article_tags = [];
    let article_cats = [];
    this.state.articleTags.forEach((a) => {
      article_tags.push(a);
    });
    this.state.subCategories.forEach((s) => {
      article_cats.push(s);
    });
    let data = {
      behaviors: {
        int: [],
        med: [`article category : ${this.state.mainPostCategory}`],
      },
      ruleBuilder: {
        article_tags: article_tags,
        article_cats: article_cats,
        article_title: [`${this.state.articleTitle}`],
        article_author: [`${this.state.articleAuthor}`],
      },
    };
    let dataString = JSON.stringify(data);
    const lotameFunction = `
      <script type="text/javascript">
        window.lotame_16314.cmd.push(function() {
          window.lotame_16314.page(${dataString});
          console.log("lotame despues",${dataString});
        });
      </script>
    `;
    return lotameFunction;
  }
  /* Lotame Stuff*/
  render() {
    const { Loading, content, Loadingcss, cssfile } = this.state;
    setTimeout(function () {
      var div = document.createElement("span");
      div.innerHTML = content;
      if (Loadingcss !== true) {
        $(document).ready(function () {
          $(
            `<link rel="preload" href="${
              mediastaticpages.mediaUrl
            }/wp-content/uploads/visualcomposer-assets/assets-bundles/${cssfile}.source.css?timestamp=${new Date().getTime()}" as="style" onload="this.onload=null;this.rel='stylesheet'">`
          ).appendTo("head");
          $(
            `<noscript><link rel="stylesheet" href="${
              mediastaticpages.mediaUrl
            }/wp-content/uploads/visualcomposer-assets/assets-bundles/${cssfile}.source.css?timestamp=${new Date().getTime()}"></noscript>`
          ).appendTo("head");

          //   $("<link/>", {
          //     rel: "stylesheet",
          //     type: "text/css",
          //     href: `https://contentadmin.latv.com/wp-content/uploads/visualcomposer-assets/assets-bundles/${cssfile}.source.css?timestamp=${new Date().getTime()}`
          //  }).appendTo("head");
        });
      }

      if (Loading !== true) {
        if (content !== "" && content !== null && content !== "undefined") {
          $("#content-rendered").html("");
          $("#content-rendered").append(content);
          //   var clips_section =  document.getElementById('clips_section');
          //   var episodes_section =  document.getElementById('episodes_section');
          //   var posts_section =  document.getElementById('posts_section');
          //   var series_slider_section =  document.getElementById('series_slider_section');
          //   var header_part =  document.getElementById('header_part');
          //   if (typeof(header_part) != 'undefined' && header_part != null)
          //   {
          //   var clone = $('#header_part').parent().clone();
          //   $('#header_part').remove();
          //   clone.appendTo('#header_part_render');
          //   }
          //   if (typeof(clips_section) != 'undefined' && clips_section != null)
          //   {
          //     var category = document.getElementById('clips_section').getAttribute('data-category');
          //     var title = document.getElementById('clips_section').getAttribute('data-title');
          //     var pagination = document.getElementById('clips_section').getAttribute('data-pagination');
          //     var itemscount = document.getElementById('clips_section').getAttribute('data-itemscount');
          //     var post_tag = document.getElementById('clips_section').getAttribute('data-post_tag');
          //     ReactDOM.render(<ClipsLoadComponent category={category} title={title} pagination={pagination} itemscount={itemscount}  post_tag={post_tag}/>, document.getElementById('clips_section'));
          //   }
          //   if (typeof(episodes_section) != 'undefined' && episodes_section != null)
          //   {
          //      category = document.getElementById('episodes_section').getAttribute('data-category');
          //      title = document.getElementById('episodes_section').getAttribute('data-title');
          //      pagination = document.getElementById('episodes_section').getAttribute('data-pagination');
          //      itemscount = document.getElementById('episodes_section').getAttribute('data-itemscount');
          //      post_tag = document.getElementById('episodes_section').getAttribute('data-post_tag');
          //     ReactDOM.render(<EpisodesLoadComponent category={category} title={title} pagination={pagination} itemscount={itemscount}  post_tag={post_tag}/>, document.getElementById('episodes_section'));
          //   }
          //   if (typeof(posts_section) != 'undefined' && posts_section != null)
          //   {
          //      category = document.getElementById('posts_section').getAttribute('data-category');
          //      title = document.getElementById('posts_section').getAttribute('data-title');
          //      pagination = document.getElementById('posts_section').getAttribute('data-pagination');
          //      itemscount = document.getElementById('posts_section').getAttribute('data-itemscount');
          //     ReactDOM.render(<PostsLoadComponent category={category} title={title} pagination={pagination} itemscount={itemscount}/>, document.getElementById('posts_section'));
          //   }
          //   if (typeof(series_slider_section) != 'undefined' && series_slider_section != null)
          //   {
          //     ReactDOM.render(<Gallery4x4Component />, document.getElementById('series_slider_section'));
          //   }
          //   $('#app-download').remove();
          //   $('.remove-placeholder').remove();

          //   document.querySelectorAll('.elementor-post__badge').forEach(function(a) {
          //     a.remove()
          //   })
          //   $("#wtw").change(function(){
          //     var changeval = $('#wtw').val();
          //     if (changeval==='et'){
          //       $(".time-both").hide();
          //       $(".time-et").show();
          //       $(".time-pt").hide();
          //     } else if (changeval==='pt'){
          //       $(".time-both").hide();
          //       $(".time-et").hide();
          //       $(".time-pt").show();
          //     } else if (changeval==='pet'){
          //       $(".time-both").show();
          //       $(".time-et").hide();
          //       $(".time-pt").hide();
          //     } else {
          //       $(".time-both").show();
          //       $(".time-et").hide();
          //       $(".time-pt").hide();
          //     }
          //   });
          //   $(".time-both").show();
          //   $(".time-et").hide();
          //   $(".time-pt").hide();
        } else {
          $("#content-rendered").html("");
        }
      }
    }, 1500);
    return (
      <>
        <section id="read-section" className="read-section mt-0 mt-md-5 ">
          <div className="vcv-content--blank vcv-content--boxed">
            <article
              id=""
              className="page type-page status-publish hentry entry"
            >
              <div className="entry-content" id="content-rendered">
                {/* <div id="content-rendered"> */}
                {/* dangerouslySetInnerHTML={{ __html: content }}> */}
                {/* </div> */}
              </div>
            </article>
          </div>
        </section>
      </>
    );
  }
}
