import { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
// import dragPlaceHolder from '../Images/dragPlaceHolder.jpg';
import vamosImage from "../images/Vamos-Traveling.png";
import { Link } from "react-router-dom";

function PopUpComponent() {
  const [show, setShow] = useState(false);
  const sessionKey = "hasDisplayedModal";

  const today = new Date();
  const futureDateObject = new Date("2024-09-28");
  // console.log(today < futureDateObject);

  useEffect(() => {
    if (!sessionStorage.getItem(sessionKey) && today < futureDateObject) {
      setTimeout(() => {
        setShow(true);
        sessionStorage.setItem(sessionKey, true); // Mark modal as displayed
      }, 5000);
    }
  }, []);

  const handleClose = () => setShow(false);
  //   const handleShow = () => setShow(true);

  return (
    <>
      {/* <Button variant="primary" onClick={handleShow}>
        Launch static backdrop modal
      </Button> */}

      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        // size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>Vamos Traveling</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ padding: 0 }}>
          {/* Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam volutpat, 
        augue vestibulum posuere lobortis, magna odio aliquam quam, a ornare purus 
        libero ut dolor. Maecenas et euismod ante. */}
          <img src={vamosImage} alt="" className="popUpImage w-100" />
        </Modal.Body>
        <Modal.Footer>
          {/* <Button variant="secondary" onClick={handleClose}>
            Close
          </Button> */}
          <a href={`/series/vamostraveling/`}>
            <Button variant="primary">VIEW NOW!</Button>
          </a>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default PopUpComponent;
