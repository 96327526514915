import React, { Component } from "react";
import axios from "../../apiConnections/axios";
import LoadingPost from "../../images/loading.jpg";
import playbutton from "../../images/play-button.png";
// import WatchEpisodePlaceholderComponent from "../Placeholders/WatchEpisodePlaceholderComponent";

import Pagination from "react-js-pagination";
// import Img from 'react-cool-img';
// import '../../CustomCss/episodesLoad.css';
import _ from "lodash";
export default class ArticlesLoadComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      LoadingArticles: true,
      Articles: [],
      ArticlesTotalPages: 1,
      ArticlesActivePage: 1,
      itemscount: 9,
      pagination: "no",
    };
  }
  componentDidMount() {
    this.loadArticles(1);
    this.setState({
      itemscount: this.props.itemscount,
      pagination: this.props.pagination,
    });
  }
  loadArticles = async (pageNumber) => {
    await axios
      .get(
        `/get-post-by-category?page_no=${pageNumber}&category=${
          this.props.category
        }&itemscount=${
          this.props.itemscount
        }&timestamp=${new Date().getTime()}`,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json;charset=UTF-8",
          },
        }
      )
      .then((res) => {
        this.setState({
          Articles: res.data.data,
          LoadingArticles: false,
          ArticlesTotalPages: res.data.pagecount,
          ArticlesActivePage: pageNumber,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };
  handleArticlesPageChange(pageNumber) {
    this.loadArticles(pageNumber);
    this.setState({ LoadingArticles: true, ArticlespageNumber: pageNumber });
  }

  render() {
    const { LoadingArticles, Articles, pagination, itemscount } = this.state;
    return (
      <>
        {Articles && Articles !== null ? (
          <>
            <h2 className="blue-text underline">{this.props.title}</h2>
          </>
        ) : (
          ``
        )}

        <div className="row shortcode">
          {!LoadingArticles && Articles ? (
            Articles.slice(0, itemscount).map((article) => {
              const { ID, post_title, post_image, post_slug, imgs, post_type } =
                article;
              const articleHref =
                post_type === "watch"
                  ? `../../watch/${post_slug}`
                  : `/${post_slug}/`;
              return (
                <div
                  className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12 mb-2"
                  key={ID}
                >
                  <a href={articleHref}>
                    <div className="mt-3">
                      <div className="side-video-thumbnail opt-out-border">
                        <img
                          placeholder={LoadingPost}
                          sizes={imgs.toReversed().map((image, index) => {
                            if (image[1] === undefined) {
                              return [];
                            }
                            let Bsize = "";
                            const Size = [
                              "(-webkit-min-device-pixel-ratio: 2) 50vw",
                            ];
                            const size = image[1].toString();
                            if (index === 0) {
                              Size.push(
                                "(min-width " + size + "px) " + size + "w"
                              );
                              Bsize = size - 1;
                            } else {
                              Size.push(
                                "(max-width " + Bsize + "px) " + size + "w"
                              );
                              Bsize = size - 1;
                            }
                            return Size;
                          })}
                          srcSet={imgs.toReversed().map((image, index) => {
                            if (image[1] === undefined) {
                              return [];
                            }
                            const Srcset = [];
                            const Zize = image[1].toString();
                            const link = image[0].toString();
                            Srcset.push(link + " " + Zize + "w");
                            return Srcset;
                          })}
                          src={post_image.medium_image_url}
                          loading="lazy"
                          cache
                          className="img-fluid w-100 h-auto img-sizing"
                          alt={post_image.image_alt}
                          width="1000px"
                          height="563px"
                        />
                        {post_type === "watch" ? (
                          <div>
                            <img
                              src={playbutton}
                              className="img-fluid"
                              alt={"play-button"}
                            />
                          </div>
                        ) : (
                          <></>
                        )}
                      </div>
                      <div
                        className="title-small-video mt-1"
                        title={post_title}
                        data-toggle="tooltip"
                        dangerouslySetInnerHTML={{
                          __html:
                            post_title !== null && post_title !== ""
                              ? post_title.length > 50
                                ? post_title.substr(0, 50) + `...`
                                : post_title
                              : ``,
                        }}
                      ></div>
                    </div>
                  </a>
                </div>
              );
            })
          ) : (
            <>
              {Articles !== null ? (
                <>
                  {_.times(itemscount, (i) => (
                    <></>
                    // <WatchEpisodePlaceholderComponent key={i}/>
                  ))}
                </>
              ) : (
                ``
              )}
            </>
          )}
        </div>
        {pagination === "yes" &&
        Articles !== null &&
        !LoadingArticles &&
        Articles ? (
          <>
            <div className="center pt-2">
              <Pagination
                activePage={this.state.ArticlesActivePage}
                itemsCountPerPage={1}
                totalItemsCount={this.state.ArticlesTotalPages}
                pageRangeDisplayed={5}
                onChange={this.handleArticlesPageChange.bind(this)}
                itemClass="page-item"
                linkClass="page-link"
                innerClass="pagination watch-page-pagination"
                hideFirstLastPages
                prevPageText="<"
                nextPageText=">"
              />
            </div>
          </>
        ) : (
          ``
        )}
      </>
    );
  }
}
