import React, { Component } from "react";
import axios from "../../apiConnections/axios";
import LoadingPost from "../../images/loading.jpg";
import playbutton from "../../images/play-button.png";
// import WatchClipPlaceholderComponent from "../Placeholders/WatchClipPlaceholderComponent";
import Pagination from "react-js-pagination";
// import Img from 'react-cool-img';

// import "../../CustomCss/clipsLoad.css";
import _ from "lodash";
export default class ClipsLoadComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      LoadingClips: true,
      Clips: [],
      ClipstotalPages: 1,
      ClipsactivePage: 1,
      itemscount: 9,
      pagination: "no",
    };
  }
  componentDidMount() {
    this.loadClips(1);
    this.setState({
      itemscount: this.props.itemscount,
      pagination: this.props.pagination,
    });
  }
  loadClips = async (pageNumber) => {
    await axios
      .get(
        `/get-clips-by-category?page_no=${pageNumber}&category=${
          this.props.category
        }&post_tag=${this.props.post_tag}&itemscount=${
          this.props.itemscount
        }&timestamp=${new Date().getTime()}`,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json;charset=UTF-8",
          },
        }
      )
      .then((res) => {
        this.setState({
          Clips: res.data.data,
          LoadingClips: false,
          ClipstotalPages: res.data.pagecount,
          ClipsactivePage: pageNumber,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };
  handleClipsPageChange(pageNumber) {
    this.loadClips(pageNumber);
    this.setState({ LoadingClips: true });
    this.setState({ ClipspageNumber: pageNumber });
  }

  render() {
    const { LoadingClips, Clips, pagination, itemscount } = this.state;
    return (
      <>
        {Clips && Clips !== null ? (
          <>
            <h2 className="blue-text underline">{this.props.title}</h2>
          </>
        ) : (
          ``
        )}

        <div className="row shortcode">
          {!LoadingClips && Clips ? (
            Clips.slice(0, itemscount).map((clips) => {
              const { ID, post_title, post_image, post_slug, imgs } = clips;
              return (
                <div
                  className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12 mb-2"
                  key={ID}
                >
                  <a href={`../../watch/${post_slug}/`}>
                    <div className="mt-3">
                      <div className="side-video-thumbnail opt-out-border">
                        <img
                          placeholder={LoadingPost}
                          sizes={imgs.toReversed().map((image, index) => {
                            let Bsize = "";
                            const Size = [
                              "(-webkit-min-device-pixel-ratio: 2) 50vw",
                            ];
                            const size = image[1].toString();
                            if (index === 0) {
                              Size.push(
                                "(min-width " + size + "px) " + size + "w"
                              );
                              Bsize = size - 1;
                            } else {
                              Size.push(
                                "(max-width " + Bsize + "px) " + size + "w"
                              );
                              Bsize = size - 1;
                            }
                            return Size;
                          })}
                          srcSet={imgs.toReversed().map((image, index) => {
                            const Srcset = [];
                            const Zize = image[1].toString();
                            const link = image[0].toString();
                            Srcset.push(link + " " + Zize + "w");
                            return Srcset;
                          })}
                          src={post_image.medium_image_url}
                          loading="lazy"
                          cache
                          className="img-fluid w-100 h-auto img-sizing"
                          alt={post_image.image_alt}
                          width="1000px"
                          height="563px"
                        />
                        <div>
                          <img
                            src={playbutton}
                            className="img-fluid"
                            alt={"play-button"}
                          />
                        </div>
                      </div>
                      {/* <div className="date-small-video mt-2">{publish_date}</div> */}
                      <div
                        className="title-small-video mt-1"
                        title={post_title}
                        data-toggle="tooltip"
                        dangerouslySetInnerHTML={{
                          __html:
                            post_title !== null && post_title !== ""
                              ? post_title.length > 50
                                ? post_title.substr(0, 50) + `...`
                                : post_title
                              : ``,
                        }}
                      ></div>
                    </div>
                  </a>
                </div>
              );
            })
          ) : (
            <>
              {Clips !== null ? (
                <>
                  {_.times(itemscount, (i) => (
                    <></>
                    // <WatchClipPlaceholderComponent key={i} />
                  ))}
                </>
              ) : (
                ``
              )}
            </>
          )}
        </div>
        {pagination === "yes" && Clips !== null && Clips ? (
          <div className="center pt-2">
            <Pagination
              activePage={this.state.ClipsactivePage}
              itemsCountPerPage={1}
              totalItemsCount={this.state.ClipstotalPages}
              pageRangeDisplayed={5}
              onChange={this.handleClipsPageChange.bind(this)}
              itemClass="page-item"
              linkClass="page-link"
              innerClass="pagination watch-page-pagination"
              hideFirstLastPages
              prevPageText="<"
              nextPageText=">"
            />
          </div>
        ) : (
          ``
        )}
      </>
    );
  }
}
