import "./App.css";
import {
  Route,
  BrowserRouter as Router,
  Routes,
  useParams,
  useLocation,
} from "react-router-dom";
import React, { Component, Suspense, useState, useEffect } from "react";
import Header from "./components/Header/Header";
import HomeComponent from "./components/Home/HomeComponent";
import Footer from "./components/Footer/Footer";
import SReadComponent from "./components/SingleArticle/SReadComponent";
import axios from "./apiConnections/axios";
import CategoryTemporal from "./components/Category/CategoryTemporal";
import SearchPostsComponent from "./components/Search/SearchPostsComponent";
import QueerHomeComponent from "./components/Queer/QueerHomeComponent";
import QueerSubcategoryComponent from "./components/Queer/QueerSubcategoryComponent";
import HeaderQueer from "./components/Header/HeaderQueer";
import QueerArticleDetail from "./components/Queer/QueerArticleDetail";
import TermsConditionsPageComponent from "./StaticPages/TermsConditionsPageComponent";
import ContactUsPageComponent from "./StaticPages/ContactUsPageComponent";
import PrivacyPolicyPageComponent from "./StaticPages/PrivacyPolicyPageComponent";
import AboutUsPageComponent from "./StaticPages/AboutUsPageComponent";
import JobsPageComponent from "./StaticPages/JobsPageComponent";
import BoothPageComponent from "./StaticPages/BoothPageComponent";
import SchedulePageComponent from "./schedule/SchedulePageComponent";
import DigitalSeriesPageComponent from "./components/DigitalSeries/DigitalSeriesPageComponent";
import WatchPageComponent from "./components/WatchPageComponent/WatchPageComponent";
import SingleWatchComponent from "./components/WatchPageComponent/SingleWatchComponent";
import SpecialsComponent from "./components/SharedComponents/SpeciaslComponent";
import DigitalSpecialsComponent from "./components/Specials/DigitalSpecialsComponent";
import CreatorDetailsPageComponent from "./components/CreatorVC/CreatorDetailsPageComponent";
import AuthorsSectionComponent from "./components/AuthorVC/AuthorSectionComponent";
import FooterQueer from "./components/Footer/FooterQueer";
import DownloadPageComponent from "./components/DownloadComponent/DownloadComponent";
import QueerSasSetup from "./components/Queer/QueerSasSetup";
import AdsSectionComponent from "./components/Ads/AdsFunctions/adsSectionComponent";
import LivingAndReadyComponent from "./components/LivingAndReadyComponent/LivingAndreadyComponent";
import PopUpComponent from "./components/PopUpComponent";
let flagApi = false;
// import { createBrowserHistory } from "history";

// const history = createBrowserHistory();

function App() {
  const location = useLocation();
  const [loading, setLoading] = useState(true);
  const [headerMenu, setHeaderMenu] = useState([]);
  const [secondaryMenu, setSecondaryMenu] = useState([]);
  const [footerMenu, setFooterMenu] = useState([]);

  useEffect(() => {
    loadMenus();
  }, []);

  const loadMenus = async () => {
    try {
      const response = await axios.get(
        `/get-menus?timestamp=${new Date().getTime()}`,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json;charset=UTF-8",
          },
        }
      );
      setHeaderMenu(response.data.menus.header_menu);
      setSecondaryMenu(response.data.menus.secondary_menu);
      setFooterMenu(response.data.menus.footer_menu);
      setLoading(false);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      {!loading && (
        <>
          <div id="top-content"></div>
          <Routes>
            <Route
              path="/*"
              element={
                <Header
                  urls={{}}
                  location={location.pathname}
                  menu={headerMenu}
                  secondaryMenu={secondaryMenu}
                />
              }
            />
            <Route
              path="/queer/*"
              element={
                <HeaderQueer
                  location={location}
                  urls={{}}
                  menu={headerMenu}
                  secondaryMenu={secondaryMenu}
                />
              }
            />
          </Routes>
          <Routes>
            <Route path="/" element={<HomeComponent />} />
            <Route
              exact
              path="/search/:slug"
              element={<SearchPostsComponent urls={{}} />}
            />
            <Route exact path={"/culture"}>
              <Route
                index
                element={<CategoryTemporal urls={{}} category="culture" />}
              />
              <Route
                path=":slug"
                element={<CategoryTemporal urls={{}} category="culture" />}
              />
            </Route>
            <Route exact path={"/latina"}>
              <Route
                index
                element={<CategoryTemporal urls={{}} category="latina" />}
              />
              <Route
                path=":slug"
                element={<CategoryTemporal urls={{}} category="latina" />}
              />
            </Route>
            <Route exact path={"/afrolatino"}>
              <Route
                index
                element={<CategoryTemporal urls={{}} category="culture" />}
              />
              <Route
                path=":slug"
                element={<CategoryTemporal urls={{}} category="culture" />}
              />
            </Route>
            <Route exact path={"/queer"}>
              <Route
                index
                element={<QueerHomeComponent urls={{}} category="queer" />}
              />
              <Route
                path="beauty"
                element={
                  <QueerSubcategoryComponent urls={{}} category="beauty" />
                }
              />
              <Route
                path="culture"
                element={
                  <QueerSubcategoryComponent urls={{}} category="culture" />
                }
              />
              <Route
                path="entertainment"
                element={
                  <QueerSubcategoryComponent
                    urls={{}}
                    category="entertainment"
                  />
                }
              />
              <Route
                path="fashion"
                element={
                  <QueerSubcategoryComponent urls={{}} category="fashion" />
                }
              />
              <Route
                path="food"
                element={
                  <QueerSubcategoryComponent urls={{}} category="food" />
                }
              />
              <Route
                path="health"
                element={
                  <QueerSubcategoryComponent urls={{}} category="health" />
                }
              />
              <Route
                path="lifestyle"
                element={
                  <QueerSubcategoryComponent urls={{}} category="lifestyle" />
                }
              />
              <Route
                path="music"
                element={
                  <QueerSubcategoryComponent urls={{}} category="music" />
                }
              />
              <Route
                path="news"
                element={
                  <QueerSubcategoryComponent urls={{}} category="news" />
                }
              />
              <Route
                path="sports"
                element={
                  <QueerSubcategoryComponent
                    urls={{}}
                    category="besportsauty"
                  />
                }
              />
              <Route
                exact
                path={"search/:slug"}
                element={<SearchPostsComponent urls={{}} category="queer" />}
              />
              <Route
                exact
                path={":slug"}
                element={<QueerArticleDetail urls={{}} category="queer" />}
              />
            </Route>
            <Route path="/:slug" element={<SReadComponent urls={{}} />} />
            <Route
              exact
              path={"/terms-conditions"}
              element={<TermsConditionsPageComponent urls={{}} />}
            />
            <Route
              exact
              path={"/contact-us"}
              element={<ContactUsPageComponent urls={{}} />}
            />
            <Route
              exact
              path={"/privacy-policy"}
              element={<PrivacyPolicyPageComponent urls={{}} />}
            />
            <Route
              exact
              path={"/about-us"}
              element={<AboutUsPageComponent urls={{}} />}
            />
            <Route
              exact
              path={"/jobs"}
              element={<JobsPageComponent urls={{}} />}
            />
            <Route
              exact
              path={"/booth"}
              element={<BoothPageComponent urls={{}} />}
            />
            <Route
              exact
              path={"/schedule"}
              element={<SchedulePageComponent urls={{}} />}
            />
            <Route
              exact
              path={"/series"}
              element={<WatchPageComponent urls={{}} />}
            />
            <Route
              exact
              path={"/watch/:slug"}
              element={<SingleWatchComponent urls={{}} />}
            />
            <Route
              exact
              path={"/series/:slug"}
              element={<DigitalSeriesPageComponent urls={{}} />}
            />
            <Route
              exact
              path="/weallgrowsummit"
              element={
                <DigitalSpecialsComponent
                  urls={{}}
                  category="weallgrowsummit"
                />
              }
            />
            <Route
              exact
              path="/hispanic-heritage-month"
              element={
                <DigitalSpecialsComponent
                  urls={{}}
                  category="hispanic-heritage-month"
                />
              }
            />
            <Route
              exact
              path="/quest-to-qatar"
              element={
                <DigitalSpecialsComponent urls={{}} category="quest-to-qatar" />
              }
            />
            <Route
              exact
              path="/holidays"
              element={
                <DigitalSpecialsComponent urls={{}} category="holidays" />
              }
            />
            <Route
              exact
              path="/blackhistorymonth"
              element={
                <DigitalSpecialsComponent
                  urls={{}}
                  category="blackhistorymonth"
                />
              }
            />
            <Route
              exact
              path="/bad-bunny-news"
              element={
                <DigitalSpecialsComponent urls={{}} category="bad-bunny-news" />
              }
            />
            <Route
              exact
              path="/drag-latina"
              element={
                <DigitalSpecialsComponent urls={{}} category="drag-latina" />
              }
            />
            <Route
              exact
              path="/livingyready"
              element={
                <LivingAndReadyComponent urls={{}} category="livingyready" />
              }
            />
            <Route
              exact
              path="/royal-t"
              element={
                <DigitalSpecialsComponent urls={{}} category="royal-t" />
              }
            />
            <Route
              exact
              path="/stop-the-disinformation"
              element={
                <DigitalSpecialsComponent
                  urls={{}}
                  category="stop-the-disinformation"
                />
              }
            />
            <Route
              exact
              path="/404"
              element={<DigitalSpecialsComponent urls={{}} category="404-2" />}
            />
            <Route
              exact
              path="/creator/:type/:slug"
              element={<CreatorDetailsPageComponent urls={{}} />}
            />
            <Route
              exact
              path="/author/:slug"
              element={<AuthorsSectionComponent urls={{}} />}
            />
            <Route
              exact
              path={"/download"}
              element={<DownloadPageComponent urls={{}} />}
            />
          </Routes>
          <Routes>
            <Route path="/queer/*" element={<QueerSasSetup />} />
          </Routes>
          <Routes>
            {/* <Route
              path="/*"
              element={<AdsSectionComponent location={location.pathname} />}
            /> */}
            <Route exact path="/weallgrowsummit" element={<></>} />
            <Route exact path="/hispanic-heritage-month" element={<></>} />
            <Route exact path="/quest-to-qatar" element={<></>} />
            <Route exact path="/holidays" element={<></>} />
            <Route exact path="/blackhistorymonth" element={<></>} />
            <Route exact path="/bad-bunny-news" element={<></>} />
            <Route exact path="/drag-latina" element={<></>} />
            <Route exact path="/livingyready" element={<></>} />
            <Route exact path="/royal-t" element={<></>} />
            <Route exact path="/stop-the-disinformation" element={<></>} />
            <Route exact path={"/series/:slug"} element={<></>} />
          </Routes>
          {location.pathname !== "/series/vamostraveling" &&
            location.pathname !== "/series/vamostraveling/" && (
              <PopUpComponent />
            )}
          <Routes>
            <Route
              path="/*"
              element={
                <Footer footerMenu={footerMenu} location={location.pathname} />
              }
            />
            <Route
              path="/queer/*"
              element={<FooterQueer footerMenu={footerMenu} />}
            />
          </Routes>
        </>
      )}
    </>
  );
}

export default App;

// class App extends Component {
//   state = {
//     headerMenu: [],
//     secondaryMenu: [],
//     footerMenu: [],
//     loading: true,
//   };

//   componentDidMount() {
//     if (!flagApi) {
//       this.loadMenus();
//       flagApi = true;
//     }
//   }

//   loadMenus = async () => {
//     try {
//       const response = await axios.get(
//         `/get-menus?timestamp=${new Date().getTime()}`,
//         {
//           headers: {
//             Accept: "application/json",
//             "Content-Type": "application/json;charset=UTF-8",
//           },
//         }
//       );
//       this.setState({
//         loading: false,
//         headerMenu: response.data.menus.header_menu,
//         secondaryMenu: response.data.menus.secondary_menu,
//         footerMenu: response.data.menus.footer_menu,
//       });
//     } catch (error) {
//       console.error(error);
//     }
//   };

//   render() {
//     return (
//       <>
//         <Router>
//           {!this.state.loading ? (
//             <Header
//               urls={this.state}
//               menu={this.state.headerMenu}
//               secondaryMenu={this.state.secondaryMenu}
//             />
//           ) : (
//             <></>
//           )}
//           <Routes>
//             <Route
//               exact
//               path="/"
//               element={
//                 <>
//                   <HomeComponent />
//                 </>
//               }
//             />
//             <Route
//               exact
//               path="/search/:slug"
//               element={
//                 <>
//                   <SearchPostsComponent urls={this.state} />
//                 </>
//               }
//             />
//             <Route
//               exact
//               path={"/culture"}
//               element={
//                 <>
//                   <CategoryTemporal urls={this.state} category="culture" />
//                 </>
//               }
//             />
//             <Route
//               exact
//               path={"/latina"}
//               element={
//                 <>
//                   <CategoryTemporal urls={this.state} category="latina" />
//                 </>
//               }
//             />
//             <Route
//               exact
//               path={"/afrolatino"}
//               element={
//                 <>
//                   <CategoryTemporal urls={this.state} category="afrolatino" />
//                 </>
//               }
//             />
//             <Route
//               exact
//               path={"/queer"}
//               element={
//                 <>
//                   <QueerHomeComponent urls={this.state} category="queer" />
//                 </>
//               }
//             />
//             <Route
//               path={"/:slug"}
//               element={
//                 <>
//                   <SReadComponent urls={this.state} />
//                 </>
//               }
//             />
//           </Routes>
//           {!this.state.loading ? (
//             <Footer footerMenu={this.state.footerMenu} />
//           ) : (
//             <></>
//           )}
//         </Router>
//       </>
//     );
//   }
// }

// export default App;
