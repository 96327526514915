import React, { Component } from "react";
import axios from "../apiConnections/axiosstaticpages";
import $ from "jquery";
import { Helmet } from "react-helmet";
// import "../../CustomCss/privacypolicy.css";
import { Html5Entities } from "html-entities";

export default class PrivacyPolicyPageComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      Loading: true,
      title: "",
      content: "",
      mainPostCategory: "corporate",
      subCategories: [],
      articleTags: [],
      articleAuthor: "",
      articleTitle: "",
    };
  }
  componentDidMount() {
    this.loadAbout();
    // $('[data-toggle="tooltip"]').tooltip();
    window.scrollTo(0, 0);
    // ReactGA.initialize('UA-3906388-4'); //UA-3906388-4
    // ReactGA.pageview(window.location.pathname + window.location.search);
  }
  // componentWillUnmount() {
  //   window.location.reload();
  // }
  loadAbout = async () => {
    this.setState({ Loading: true });
    await axios
      .get(`/pages/?slug=privacy-policy&timestamp=${new Date().getTime()}`, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json;charset=UTF-8",
        },
      })
      .then((res) => {
        this.setState({ title: res.data[0].title.rendered });
        this.setState({ content: res.data[0].content.rendered });
        this.setState({ Loading: false });
        // $('[data-toggle="tooltip"]').tooltip();
        // $("[rel=tooltip]").tooltip({ trigger: "hover" });
        this.appendLotameScript();
      })
      .catch((error) => {
        console.log(error);
      });
  };
  /* Lotame Stuff*/
  createElementFromHTMLString(htmlString) {
    var div = document.createElement("div");
    div.innerHTML = htmlString.trim();
    // Change this to div.childNodes to support multiple top-level nodes.
    return div.firstChild;
  }

  appendLotameScript() {
    const scriptLotameTag = this.createElementFromHTMLString(this.lotameLoad());

    scriptLotameTag.defer = true;

    var div = document.createElement("div");
    div.innerHTML = scriptLotameTag;
    // console.log(document.getElementById('lotameTag'));
    $("#lotameTag").append(scriptLotameTag);
    // document.getElementById('lotameTag').appendChild(scriptLotameTag);
    // document.body.appendChild(div);
  }

  lotameLoad() {
    // console.log("lotame load")
    let path_name = window.location.pathname;
    let url = window.location.href;
    let article_tags = [];
    let article_cats = [];
    this.state.articleTags.forEach((a) => {
      article_tags.push(a);
    });
    this.state.subCategories.forEach((s) => {
      article_cats.push(s);
    });
    let data = {
      behaviors: {
        int: [],
        med: [`article category : ${this.state.mainPostCategory}`],
      },
      ruleBuilder: {
        article_tags: article_tags,
        article_cats: article_cats,
        article_title: [`${this.state.articleTitle}`],
        article_author: [`${this.state.articleAuthor}`],
      },
    };
    let dataString = JSON.stringify(data);
    const lotameFunction = `
      <script type="text/javascript">
        window.lotame_16314.cmd.push(function() {
          window.lotame_16314.page(${dataString});
        });
      </script>
    `;
    return lotameFunction;
  }
  /* Lotame Stuff*/
  render() {
    const { Loading, title, content } = this.state;
    // const htmlEntities = new Html5Entities();

    return (
      <>
        {/* <Helmet>
          <title>{`${htmlEntities.decode(title)} | LATV`}</title>
          <meta name="title" content={`${htmlEntities.decode(title)} | LATV`} />
          <meta
            name="description"
            content={`Protecting your private information is our priority. This Statement of Privacy applies to LATV NETWORKS.com and LATV NETWORKS and governs data collection and usage. For the purposes of this Privacy&#8230;`}
          />
        </Helmet> */}
        <section id="read-section" className="read-section mb-5">
          <div className="container">
            <div className="row">
              <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                {/* <h2 className="about-heading">
                            {!Loading ? (title) : ``}
                            </h2> */}
                {!Loading ? (
                  <>
                    <p
                      className="about-info"
                      dangerouslySetInnerHTML={{ __html: content }}
                    ></p>
                  </>
                ) : (
                  ``
                )}
              </div>
            </div>
          </div>
        </section>
      </>
    );
  }
}
