import React, { Component } from "react";
import logoImage from "../../images/LATV-new-logo.png";
import $ from "jquery";
import { Modal } from "react-bootstrap";
import SmartAppBanner from "../SmartAppBanner/SmartAppBanner";
import { Link } from "react-router-dom";
let flagApi = false;
let test = "latinationmedia";

class Header extends Component {
  constructor(props) {
    super(props);
    console.log(props);
    this.state = {
      Loading: true,
      Menus: [],
      SecondaryMenu: "",
      posts: [],
      tags: [],
      content: "",
      status: "start",
      showHide: false,
      searchText: "",
      mobileDevice: false,
      showMenu: false,
      showDropdown: false,
      igSlug: "",
      ttkSlug: "",
    };
  }

  loadMenu = async () => {
    const content = this.renderItem(this.props.menu);
    const secondaryContent = this.renderSecondaryItem(this.props.secondaryMenu);
    this.setState({
      content: content,
      Menus: this.props.menu,
      SecondaryMenu: secondaryContent,
      Loading: false,
    });
  };
  searchType = (e) => {
    const { value } = e.target;
    this.setState({ searchText: value });
  };
  handleSearch = (e) => {
    e.preventDefault();
    window.location.href =
      window.location.origin + `/search/${this.state.searchText}`;
  };

  shoudlRedirect(url) {
    if (window.innerWidth >= 992) {
      window.location.href = url;
    } else {
    }
  }

  renderItem = (menus) => {
    const menusContent = menus.map((menu, i) => {
      var url;
      if (window.innerWidth >= 992) {
        url = menu.url;
      } else {
        url = "#";
      }
      var pathname = url;
      if (menu.children.length !== 0) {
        return (
          <li className="nav-item dropdown" key={menu.ID}>
            <a
              href={url}
              className="nav-link dropdown-toggle latv-network-menu-title"
              id="navbardrop"
              data-hover="dropdown"
              title={menu.title}
              aria-expanded="false"
              rel="noreferrer"
            >
              {menu.title}
            </a>
            <div className="dropdown-menu megamenu">
              <p className="megamenu-title">{menu.title}</p>
              <div className="megamenu-grid">
                {Array(5)
                  .fill([])
                  .map((_, i) => {
                    const columnItems = menu.children.slice(i * 6, i * 6 + 6);

                    // Determine if the column has content
                    const hasContent = columnItems.length > 0;

                    return (
                      <div
                        className={`megamenu-column ${
                          hasContent ? "has-content" : ""
                        }`}
                        key={i}
                      >
                        <ul>
                          {columnItems.map((subMenu) => (
                            <li key={subMenu.ID} title={subMenu.title}>
                              <a className="dropdown-item" href={subMenu.url}>
                                {subMenu.title}
                              </a>
                            </li>
                          ))}
                        </ul>
                      </div>
                    );
                  })}
              </div>
            </div>
          </li>

          // <li className="nav-item dropdown" key={menu.ID}>
          //   <a
          //     href={url}
          //     className="nav-link dropdown-toggle latv-network-menu-title"
          //     id="navbardrop"
          //     data-hover="dropdown"
          //     title={menu.title}
          //     aria-expanded="false"
          //     rel="noreferrer"
          //   >
          //     {menu.title}
          //   </a>
          //   <div className="dropdown-menu megamenu">
          //     <div className="megamenu-grid">
          //       {menu.children
          //         .reduce((resultArray, item, index) => {
          //           const chunkIndex = Math.floor(index / 6); // Groups of 6 items

          //           if (!resultArray[chunkIndex]) {
          //             resultArray[chunkIndex] = []; // Start a new group
          //           }

          //           resultArray[chunkIndex].push(item); // Add item to the group

          //           return resultArray;
          //         }, [])
          //         .map((group, i) => (
          //           <div className="megamenu-column" key={i}>
          //             <ul>
          //               {group.map((subMenu, j) => (
          //                 <li key={subMenu.ID} title={subMenu.title}>
          //                   <a className="dropdown-item" href={subMenu.url}>
          //                     {subMenu.title}
          //                   </a>
          //                 </li>
          //               ))}
          //             </ul>
          //           </div>
          //         ))}
          //     </div>
          //   </div>
          // </li>
        );
      } else {
        return (
          <li href={pathname} className="nav-item" key={i}>
            <a href={pathname} title={menu.title}>
              <button className="nav-link" title={menu.title}>
                {menu.title}
              </button>
            </a>
          </li>
        );
      }
    });

    return menusContent;
  };

  handleModalShowHide() {
    this.setState({ showHide: !this.state.showHide });
  }

  renderSecondaryItem = (menus) => {
    const menusContent = menus.map((menu, i) => {
      const url = menu.url;
      const menuTitle = menu.title;
      return (
        <span>
          <a className="quickMenuItem" href={url}>
            {menuTitle}
          </a>
          &nbsp;{i !== menus.length - 1 ? "|" : ""}&nbsp;
        </span>
      );
    });
    return menusContent;
  };

  isSlug(slug) {
    return this.props.location.startsWith(slug);
  }

  componentDidMount = () => {
    if (!flagApi) {
      let igSlug;
      switch (true) {
        case this.isSlug("/culture"):
          igSlug = "latinationculture";
          break;
        case this.isSlug("/latina"):
          igSlug = "latinationlatina";
          break;
        case this.isSlug("/afrolatino"):
          igSlug = "latinationroots";
          break;

        default:
          igSlug = "latinationmedia";
          break;
      }
      let ttkSlug;
      switch (true) {
        case this.isSlug("/latina"):
          ttkSlug = "latinationlatina";
          break;
        case this.isSlug("/afrolatino"):
          ttkSlug = "latinationroots";
          break;

        default:
          ttkSlug = "latinationmedia";
          break;
      }
      this.setState({ igSlug: igSlug, ttkSlug: ttkSlug });
      this.loadMenu();

      $(document).click((event) => {
        const isNavbarCollapseTarget = $(event.target).closest(
          ".navbar-collapse"
        ).length;
        const isNavbarCollapseOpen = $(".navbar-collapse").hasClass("show");

        if (!isNavbarCollapseTarget && isNavbarCollapseOpen) {
          $(".navbar-toggler").click(); // Trigger the toggle button click
        }
      });
      $(".navbar-toggler").click(() => {
        $("#navbarCollapse").toggleClass("show");
      });

      flagApi = true;
    }
  };

  render() {
    return (
      <>
        <SmartAppBanner />
        <div className="sticky-top ">
          <div className="supraHeader container-fluid d-none d-lg-block">
            <div className="row">
              <div className="col-lg-10 supraTagline">
                <p>Media Representing The LatiNation</p>
              </div>
              <div className="col-lg-2 socialNetworks">
                <a
                  href={"https://www.instagram.com/" + this.state.igSlug}
                  target="_blank"
                  rel="noreferrer"
                  data-toggle="tooltip"
                  title=""
                  data-original-title="Instagram"
                >
                  <i className="btn fa-brands fa fa-instagram white-txt"></i>
                </a>
                <a
                  href={"https://www.tiktok.com/@" + this.state.ttkSlug}
                  target="_blank"
                  rel="noreferrer"
                  title="Instagram"
                >
                  <i className="btn fa-brands fa fa-tiktok white-txt"></i>
                </a>
                <a
                  href="https://www.facebook.com/LatvNetwork/"
                  target="_blank"
                  rel="noreferrer"
                  data-toggle="tooltip"
                  title=""
                  data-original-title="Facebook"
                >
                  <i className="btn fa-brands fa fa-facebook white-txt"></i>
                </a>
                <a
                  href="https://www.youtube.com/user/LATVNetwork"
                  target="_blank"
                  rel="noreferrer"
                  data-toggle="tooltip"
                  title=""
                  data-original-title="Youtube"
                >
                  <i className="btn fa-brands fa fa-youtube white-txt"></i>
                </a>
                <a
                  href="https://twitter.com/latvnetwork"
                  target="_blank"
                  rel="noreferrer"
                  data-toggle="tooltip"
                  title=""
                  data-original-title="Twitter"
                >
                  <i className="btn fa-brands fa fa-twitter white-txt"></i>
                </a>
              </div>
            </div>
          </div>
          <div className="headerMenu">
            <nav className="navbar navbar-expand-lg bg-body-tertiary">
              <div className="container-fluid nav-background">
                <a className="navbar-brand" href="/">
                  <img
                    src={logoImage}
                    className="img-fluid"
                    alt="LATV"
                    width="auto"
                    height="40"
                  />
                </a>
                <button
                  className="navbar-toggler"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#navbarSupportedContent"
                  aria-controls="navbarSupportedContent"
                  aria-expanded="false"
                  aria-label="Toggle navigation"
                >
                  <span className="navbar-toggler-icon"></span>
                </button>
                <div
                  className="collapse navbar-collapse"
                  id="navbarSupportedContent"
                >
                  <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
                    {!this.state.Loading ? <>{this.state.content}</> : <></>}
                    <li className="nav-item searchIcon">
                      <button
                        className=""
                        onClick={() => this.handleModalShowHide()}
                        data-toggle="tooltip"
                        title="Search"
                      >
                        <p>
                          <i className="fa fa-search"></i>
                        </p>
                      </button>
                    </li>
                    <li className="nav-item d-none d-lg-block">
                      <a href={`/download`} className="watchNow">
                        WATCH NOW
                      </a>
                    </li>
                    <li className="nav-item d-block d-lg-none col-12 text-center socialNav">
                      <div className="socialNetworks">
                        <a
                          href={
                            "https://www.instagram.com/" + this.state.igSlug
                          }
                          target="_blank"
                          rel="noreferrer"
                          data-toggle="tooltip"
                          title=""
                          data-original-title="Instagram"
                        >
                          <i className="btn fa-brands fa fa-instagram"></i>
                        </a>
                        <a
                          href={"https://www.tiktok.com/@" + this.state.ttkSlug}
                          target="_blank"
                          rel="noreferrer"
                          data-toggle="tooltip"
                          title=""
                          data-original-title="Instagram"
                        >
                          <i className="btn fa-brands fa fa-tiktok"></i>
                        </a>
                        <a
                          href="https://www.facebook.com/LatvNetwork/"
                          target="_blank"
                          rel="noreferrer"
                          data-toggle="tooltip"
                          title=""
                          data-original-title="Facebook"
                        >
                          <i className="btn fa-brands fa fa-facebook"></i>
                        </a>
                        <a
                          href="https://www.youtube.com/user/LATVNetwork"
                          target="_blank"
                          rel="noreferrer"
                          data-toggle="tooltip"
                          title=""
                          data-original-title="Youtube"
                        >
                          <i className="btn fa-brands fa fa-youtube"></i>
                        </a>
                        <a
                          href="https://twitter.com/latvnetwork"
                          target="_blank"
                          rel="noreferrer"
                          data-toggle="tooltip"
                          title=""
                          data-original-title="Twitter"
                        >
                          <i className="btn fa-brands fa fa-twitter"></i>
                        </a>
                      </div>
                    </li>
                  </ul>

                  {/* <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
                                <li className="nav-item dropdown">
                                    <a className="nav-link dropdown-toggle latv-network-menu-title" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">CULTURE</a>
                                    <ul className="dropdown-menu">
                                        <li><a className="dropdown-item" href="#">Action</a></li>
                                        <li><a className="dropdown-item" href="#">Another action</a></li>
                                        <li><a className="dropdown-item" href="#">Something else here</a></li>
                                    </ul>
                                </li>
                                <li className="nav-item dropdown">
                                    <a className="nav-link dropdown-toggle latv-network-menu-title" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">QUEER</a>
                                    <ul className="dropdown-menu">
                                        <li><a className="dropdown-item" href="#">Action</a></li>
                                        <li><a className="dropdown-item" href="#">Another action</a></li>
                                        <li><a className="dropdown-item" href="#">Something else here</a></li>
                                    </ul>
                                </li>
                                <li className="nav-item dropdown">
                                    <a className="nav-link dropdown-toggle latv-network-menu-title" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">LATINA</a>
                                    <ul className="dropdown-menu">
                                        <li><a className="dropdown-item" href="#">Action</a></li>
                                        <li><a className="dropdown-item" href="#">Another action</a></li>
                                        <li><a className="dropdown-item" href="#">Something else here</a></li>
                                    </ul>
                                </li>
                                <li className="nav-item dropdown">
                                    <a className="nav-link dropdown-toggle latv-network-menu-title" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">ROOTS</a>
                                    <ul className="dropdown-menu">
                                        <li><a className="dropdown-item" href="#">Action</a></li>
                                        <li><a className="dropdown-item" href="#">Another action</a></li>
                                        <li><a className="dropdown-item" href="#">Something else here</a></li>
                                    </ul>
                                </li>
                                <li className="nav-item dropdown">
                                    <a className="nav-link dropdown-toggle latv-network-menu-title" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">SERIES</a>
                                    <ul className="dropdown-menu">
                                        <li><a className="dropdown-item" href="#">Action</a></li>
                                        <li><a className="dropdown-item" href="#">Another action</a></li>
                                        <li><a className="dropdown-item" href="#">Something else here</a></li>
                                    </ul>
                                </li>
                                <li className="nav-item dropdown">
                                    <a className="nav-link dropdown-toggle latv-network-menu-title" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">SPECIALS</a>
                                    <ul className="dropdown-menu">
                                        <li><a className="dropdown-item" href="#">Action</a></li>
                                        <li><a className="dropdown-item" href="#">Another action</a></li>
                                        <li><a className="dropdown-item" href="#">Something else here</a></li>
                                    </ul>
                                </li>
                                <li className="nav-item dropdown">
                                    <a className="nav-link dropdown-toggle latv-network-menu-title" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">MORE</a>
                                    <ul className="dropdown-menu dropdown-menu-end">
                                        <li><a className="dropdown-item" href="#">Action</a></li>
                                        <li><a className="dropdown-item" href="#">Another action</a></li>
                                        <li><a className="dropdown-item" href="#">Something else here</a></li>
                                    </ul>
                                </li>
                                <li className="nav-item d-none d-lg-block">
                                    <i className="fa-solid fa-search"></i>
                                </li>
                                <li className="nav-item d-none d-lg-block">
                                    <a href="#" className="watchNow">WATCH NOW</a>
                                </li>
                                <li className="nav-item d-block d-lg-none col-12 text-center socialNav">
                                    <div className="socialNetworks">
                                        <a href="https://www.instagram.com/latvnetwork/" target="_blank" rel="noreferrer" data-toggle="tooltip" title="" data-original-title="Instagram"><i className="btn fa-brands fa fa-instagram"></i></a>
                                        <a href="https://www.facebook.com/LatvNetwork/" target="_blank" rel="noreferrer" data-toggle="tooltip" title="" data-original-title="Facebook"><i className="btn fa-brands fa fa-facebook"></i></a>
                                        <a href="https://www.youtube.com/user/LATVNetwork" target="_blank" rel="noreferrer" data-toggle="tooltip" title="" data-original-title="Youtube"><i className="btn fa-brands fa fa-youtube"></i></a>
                                        <a href="https://twitter.com/latvnetwork" target="_blank" rel="noreferrer" data-toggle="tooltip" title="" data-original-title="Twitter"><i className="btn fa-brands fa fa-twitter"></i></a>
                                    </div>
                                </li>
                            </ul> */}
                </div>
              </div>
            </nav>
            <div className="mobileQuickMenu d-block d-lg-none">
              <span>
                <a className="quickMenuItem" href="https://latv.com/culture">
                  CULTURE
                </a>
                &nbsp;|&nbsp;
              </span>
              <span>
                <a className="quickMenuItem" href="https://latv.com/queer">
                  QUEER
                </a>
                &nbsp;|&nbsp;
              </span>
              <span>
                <a className="quickMenuItem" href="https://latv.com/latina">
                  LATINA
                </a>
                &nbsp;|&nbsp;
              </span>
              <span>
                <a className="quickMenuItem" href="https://latv.com/afrolatino">
                  AFROLATINO
                </a>
                &nbsp;|&nbsp;
              </span>
              <span>
                <a className="quickMenuItem" href="https://latv.com/series">
                  SERIES
                </a>
                &nbsp;|&nbsp;
              </span>
              <span>
                <a className="quickMenuItem" href="https://latv.com/schedule">
                  SCHEDULE
                </a>
                &nbsp;&nbsp;
              </span>
            </div>
          </div>
        </div>

        <Modal
          show={this.state.showHide}
          dialogClassName="my-modal"
          animation={false}
        >
          <Modal.Header closeButton onClick={() => this.handleModalShowHide()}>
            <Modal.Title>Search</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <form onSubmit={() => this.handleSearch()}>
              <div className="container">
                <div className="row">
                  <div className="col-md-11">
                    <div className="form-group">
                      <input
                        type="text"
                        className="form-control"
                        onChange={this.searchType}
                        id="s"
                        name="s"
                        placeholder="Search Keywords..."
                        autoComplete="off"
                      />
                    </div>
                  </div>
                  <div className="col-md-1">
                    <button
                      type="submit"
                      onClick={this.handleSearch}
                      className="search-btn"
                    >
                      <i className="fa fa-search"></i>
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </Modal.Body>
        </Modal>
      </>
    );
  }
}
export default Header;
