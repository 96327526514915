import React, { Component } from "react";
// import ReactDOM from 'react-dom';
//import axios from '../apiConnections/axios';
import mediastaticpages from "../../apiConnections/mediastaticpages";
import { useParams } from "react-router-dom";

import $ from "jquery";
import axios from "../../apiConnections/axios";
// import Carousel from "../Carousel/CarouselComponent"
// import '../../CustomCss/seriesPage.css';
// import '../../CustomCss/modal.css';
// import '../../CustomCss/creators.css';

import facebookIcon from "../../images/icons/facebook-ico.png";
import instaIcon from "../../images/icons/instagram-ico.png";
import youtubeIcon from "../../images/icons/youtube-ico.png";
import linkedinIcon from "../../images/icons/linkedin-ico.png";
import twitterIcon from "../../images/icons/twitter-ico.png";
import tiktokIcon from "../../images/icons/tiktok-ico.png";

// import '../../CustomCss/vceBackground.min.css';

function withParams(Component) {
  return (props) => <Component {...props} params={useParams()} />;
}

export class CreatorDetailsPageComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      Loading: true,
      Loadingcss: true,
      title: "",
      content: "",
      renderedcontent: "",
      showHide: false,
    };
  }
  handleModalShowHide() {
    this.setState({ showHide: !this.state.showHide });
  }
  componentDidMount() {
    this.loadData();
    setTimeout(() => {
      $(document).on("click", ".submitBTN", () => {
        this.setState({ showHide: !this.state.showHide });
      });

      var refreshContentSc = document.createElement("script");
      $(refreshContentSc).text(
        "localStorage.setItem('startDate_sas_97466', 'start'); localStorage.setItem('temp_sas_97466', 'sas_97466'); localStorage.setItem('tempcount_sas_97466', 0);   setInterval(function(){ if(!$('#sas_97466').length){ return true; }; var countsAds=0; var top_of_element = $('#sas_97466').offset().top; var bottom_of_element = $('#sas_97466').offset().top + $('#sas_97466').outerHeight();var bottom_of_screen = $(window).scrollTop() + $(window).innerHeight();var top_of_screen = $(window).scrollTop(); if ((bottom_of_screen > top_of_element) && (top_of_screen < bottom_of_element)){ var NowDate = new Date(); var lastsyncdata=localStorage.getItem('startDate_sas_97466');  if(lastsyncdata=='start'){ console.log(lastsyncdata); localStorage.setItem('startDate_sas_97466', new Date());  return true;} var lastsync =new Date(localStorage.getItem('startDate_sas_97466')); var Diffseconds = (NowDate.getTime() - lastsync.getTime()) / 1000;  var loopCount=localStorage.getItem('tempcount_sas_97466'); var tempGlobalScrollAdsIdsGet = localStorage.getItem('temp_sas_97466'); if(loopCount<5 && Diffseconds>30){ sas.refresh('sas_97466');  localStorage.setItem('tempcount_sas_97466', (Number(localStorage.getItem('tempcount_sas_97466'))+1)); localStorage.setItem('startDate_sas_97466', new Date()); }}},1000)"
      );
      $("#CustomAdsRefreshscript").append(refreshContentSc);

      var refreshContentSc1 = document.createElement("script");
      $(refreshContentSc1).text(
        "localStorage.setItem('startDate_sas_97469', 'start'); localStorage.setItem('temp_sas_97469', 'sas_97469'); localStorage.setItem('tempcount_sas_97469', 0);   setInterval(function(){ if(!$('#sas_97469').length){ return true; }; var countsAds=0; var top_of_element = $('#sas_97469').offset().top; var bottom_of_element = $('#sas_97469').offset().top + $('#sas_97469').outerHeight();var bottom_of_screen = $(window).scrollTop() + $(window).innerHeight();var top_of_screen = $(window).scrollTop(); if ((bottom_of_screen > top_of_element) && (top_of_screen < bottom_of_element)){ var NowDate = new Date(); var lastsyncdata=localStorage.getItem('startDate_sas_97469'); if(lastsyncdata=='start'){ localStorage.setItem('startDate_sas_97469', new Date()); return true;} var lastsync =new Date(localStorage.getItem('startDate_sas_97469')); var Diffseconds = (NowDate.getTime() - lastsync.getTime()) / 1000;  var loopCount=localStorage.getItem('tempcount_sas_97469'); var tempGlobalScrollAdsIdsGet = localStorage.getItem('temp_sas_97469'); if(loopCount<5 && Diffseconds>30){ sas.refresh('sas_97469'); localStorage.setItem('tempcount_sas_97469', (Number(localStorage.getItem('tempcount_sas_97469'))+1)); localStorage.setItem('startDate_sas_97469', new Date()); }}},1000)"
      );
      $("#CustomAdsRefreshscript").append(refreshContentSc1);

      var refreshContentSc2 = document.createElement("script");
      $(refreshContentSc2).text(
        "localStorage.setItem('startDate_sas_97587', 'start'); localStorage.setItem('temp_sas_97587', 'sas_97587'); localStorage.setItem('tempcount_sas_97587', 0);   setInterval(function(){ if(!$('#sas_97587').length){ return true; }; var countsAds=0; var top_of_element = $('#sas_97587').offset().top; var bottom_of_element = $('#sas_97587').offset().top + $('#sas_97587').outerHeight();var bottom_of_screen = $(window).scrollTop() + $(window).innerHeight();var top_of_screen = $(window).scrollTop(); if ((bottom_of_screen > top_of_element) && (top_of_screen < bottom_of_element)){ var NowDate = new Date(); var lastsyncdata=localStorage.getItem('startDate_sas_97587'); if(lastsyncdata=='start'){ localStorage.setItem('startDate_sas_97587', new Date()); return true;} var lastsync =new Date(localStorage.getItem('startDate_sas_97587')); var Diffseconds = (NowDate.getTime() - lastsync.getTime()) / 1000;  var loopCount=localStorage.getItem('tempcount_sas_97587'); var tempGlobalScrollAdsIdsGet = localStorage.getItem('temp_sas_97587'); if(loopCount<5 && Diffseconds>30){ sas.refresh('sas_97587');  localStorage.setItem('tempcount_sas_97587', (Number(localStorage.getItem('tempcount_sas_97587'))+1)); localStorage.setItem('startDate_sas_97587', new Date()); }}},1000)"
      );
      $("#CustomAdsRefreshscript").append(refreshContentSc2);

      var refreshContentSc3 = document.createElement("script");
      $(refreshContentSc3).text(
        "localStorage.setItem('startDate_sas_97588', 'start'); localStorage.setItem('temp_sas_97588', 'sas_97588'); localStorage.setItem('tempcount_sas_97588', 0);   setInterval(function(){ if(!$('#sas_97588').length){ return true; }; var countsAds=0; var top_of_element = $('#sas_97588').offset().top; var bottom_of_element = $('#sas_97588').offset().top + $('#sas_97588').outerHeight();var bottom_of_screen = $(window).scrollTop() + $(window).innerHeight();var top_of_screen = $(window).scrollTop(); if ((bottom_of_screen > top_of_element) && (top_of_screen < bottom_of_element)){ var NowDate = new Date(); var lastsyncdata=localStorage.getItem('startDate_sas_97588'); if(lastsyncdata=='start'){ localStorage.setItem('startDate_sas_97588', new Date());  return true;} var lastsync =new Date(localStorage.getItem('startDate_sas_97588')); var Diffseconds = (NowDate.getTime() - lastsync.getTime()) / 1000;  var loopCount=localStorage.getItem('tempcount_sas_97588'); var tempGlobalScrollAdsIdsGet = localStorage.getItem('temp_sas_97588'); if(loopCount<5 && Diffseconds>30){ sas.refresh('sas_97588'); localStorage.setItem('tempcount_sas_97588', (Number(localStorage.getItem('tempcount_sas_97588'))+1)); localStorage.setItem('startDate_sas_97588', new Date()); }}},1000)"
      );
      $("#CustomAdsRefreshscript").append(refreshContentSc3);

      var refreshContentSc4 = document.createElement("script");
      $(refreshContentSc4).text(
        "localStorage.setItem('startDate_sas_97721', 'start'); localStorage.setItem('temp_sas_97721', 'sas_97721'); localStorage.setItem('tempcount_sas_97721', 0);   setInterval(function(){ if(!$('#sas_97721').length){ return true; }; var countsAds=0; var top_of_element = $('#sas_97721').offset().top; var bottom_of_element = $('#sas_97721').offset().top + $('#sas_97721').outerHeight();var bottom_of_screen = $(window).scrollTop() + $(window).innerHeight();var top_of_screen = $(window).scrollTop(); if ((bottom_of_screen > top_of_element) && (top_of_screen < bottom_of_element)){ var NowDate = new Date(); var lastsyncdata=localStorage.getItem('startDate_sas_97721'); if(lastsyncdata=='start'){ localStorage.setItem('startDate_sas_97721', new Date()); return true;} var lastsync =new Date(localStorage.getItem('startDate_sas_97721')); var Diffseconds = (NowDate.getTime() - lastsync.getTime()) / 1000;  var loopCount=localStorage.getItem('tempcount_sas_97721'); var tempGlobalScrollAdsIdsGet = localStorage.getItem('temp_sas_97721'); if(loopCount<5 && Diffseconds>30){ sas.refresh('sas_97721');  localStorage.setItem('tempcount_sas_97721', (Number(localStorage.getItem('tempcount_sas_97721'))+1)); localStorage.setItem('startDate_sas_97721', new Date()); }}},1000)"
      );
      $("#CustomAdsRefreshscript").append(refreshContentSc4);
      var refreshContentSc5 = document.createElement("script");
      $(refreshContentSc5).text(
        "localStorage.setItem('startDate_sas_97722', 'start'); localStorage.setItem('temp_sas_97722', 'sas_97722'); localStorage.setItem('tempcount_sas_97722', 0);   setInterval(function(){ if(!$('#sas_97722').length){ return true; }; var countsAds=0; var top_of_element = $('#sas_97722').offset().top; var bottom_of_element = $('#sas_97722').offset().top + $('#sas_97722').outerHeight();var bottom_of_screen = $(window).scrollTop() + $(window).innerHeight();var top_of_screen = $(window).scrollTop(); if ((bottom_of_screen > top_of_element) && (top_of_screen < bottom_of_element)){ var NowDate = new Date(); var lastsyncdata=localStorage.getItem('startDate_sas_97722'); if(lastsyncdata=='start'){ localStorage.setItem('startDate_sas_97722', new Date()); return true;} var lastsync =new Date(localStorage.getItem('startDate_sas_97722')); var Diffseconds = (NowDate.getTime() - lastsync.getTime()) / 1000;  var loopCount=localStorage.getItem('tempcount_sas_97722'); var tempGlobalScrollAdsIdsGet = localStorage.getItem('temp_sas_97722'); if(loopCount<5 && Diffseconds>30){ sas.refresh('sas_97722');  localStorage.setItem('tempcount_sas_97722', (Number(localStorage.getItem('tempcount_sas_97722'))+1)); localStorage.setItem('startDate_sas_97722', new Date()); }}},1000)"
      );
      $("#CustomAdsRefreshscript").append(refreshContentSc5);

      var refreshContentSc6 = document.createElement("script");
      $(refreshContentSc6).text(
        "localStorage.setItem('startDate_sas_97723', 'start'); localStorage.setItem('temp_sas_97723', 'sas_97723'); localStorage.setItem('tempcount_sas_97723', 0);   setInterval(function(){ if(!$('#sas_97723').length){ return true; }; var countsAds=0; var top_of_element = $('#sas_97723').offset().top; var bottom_of_element = $('#sas_97723').offset().top + $('#sas_97723').outerHeight();var bottom_of_screen = $(window).scrollTop() + $(window).innerHeight();var top_of_screen = $(window).scrollTop(); if ((bottom_of_screen > top_of_element) && (top_of_screen < bottom_of_element)){ var NowDate = new Date(); var lastsyncdata=localStorage.getItem('startDate_sas_97723'); if(lastsyncdata=='start'){ localStorage.setItem('startDate_sas_97723', new Date());  return true;} var lastsync =new Date(localStorage.getItem('startDate_sas_97723')); var Diffseconds = (NowDate.getTime() - lastsync.getTime()) / 1000;  var loopCount=localStorage.getItem('tempcount_sas_97723'); var tempGlobalScrollAdsIdsGet = localStorage.getItem('temp_sas_97723'); if(loopCount<5 && Diffseconds>30){ sas.refresh('sas_97723');  localStorage.setItem('tempcount_sas_97723', (Number(localStorage.getItem('tempcount_sas_97723'))+1)); localStorage.setItem('startDate_sas_97723', new Date()); }}},1000)"
      );
      $("#CustomAdsRefreshscript").append(refreshContentSc6);

      var refreshContentSc7 = document.createElement("script");
      $(refreshContentSc7).text(
        "localStorage.setItem('startDate_sas_974692', 'start'); localStorage.setItem('temp_sas_974692', 'sas_974692'); localStorage.setItem('tempcount_sas_974692', 0);   setInterval(function(){ if(!$('#sas_974692').length){ return true; }; var countsAds=0; var top_of_element = $('#sas_974692').offset().top; var bottom_of_element = $('#sas_974692').offset().top + $('#sas_974692').outerHeight();var bottom_of_screen = $(window).scrollTop() + $(window).innerHeight();var top_of_screen = $(window).scrollTop(); if ((bottom_of_screen > top_of_element) && (top_of_screen < bottom_of_element)){ var NowDate = new Date(); var lastsyncdata=localStorage.getItem('startDate_sas_974692'); if(lastsyncdata=='start'){ localStorage.setItem('startDate_sas_974692', new Date());  return true;} var lastsync =new Date(localStorage.getItem('startDate_sas_974692')); var Diffseconds = (NowDate.getTime() - lastsync.getTime()) / 1000;  var loopCount=localStorage.getItem('tempcount_sas_974692'); var tempGlobalScrollAdsIdsGet = localStorage.getItem('temp_sas_974692'); if(loopCount<5 && Diffseconds>30){ sas.refresh('sas_974692');  localStorage.setItem('tempcount_sas_974692', (Number(localStorage.getItem('tempcount_sas_974692'))+1)); localStorage.setItem('startDate_sas_974692', new Date()); }}},1000)"
      );
      $("#CustomAdsRefreshscript").append(refreshContentSc7);

      var refreshContentSc8 = document.createElement("script");
      $(refreshContentSc8).text(
        "localStorage.setItem('startDate_sas_97587_2', 'start'); localStorage.setItem('temp_sas_97587_2', 'sas_97587_2'); localStorage.setItem('tempcount_sas_97587_2', 0);   setInterval(function(){ if(!$('#sas_97587_2').length){ return true; }; var countsAds=0; var top_of_element = $('#sas_97587_2').offset().top; var bottom_of_element = $('#sas_97587_2').offset().top + $('#sas_97587_2').outerHeight();var bottom_of_screen = $(window).scrollTop() + $(window).innerHeight();var top_of_screen = $(window).scrollTop(); if ((bottom_of_screen > top_of_element) && (top_of_screen < bottom_of_element)){ var NowDate = new Date(); var lastsyncdata=localStorage.getItem('startDate_sas_97587_2'); if(lastsyncdata=='start'){ localStorage.setItem('startDate_sas_97587_2', new Date()); return true;} var lastsync =new Date(localStorage.getItem('startDate_sas_97587_2')); var Diffseconds = (NowDate.getTime() - lastsync.getTime()) / 1000;  var loopCount=localStorage.getItem('tempcount_sas_97587_2'); var tempGlobalScrollAdsIdsGet = localStorage.getItem('temp_sas_97587_2'); if(loopCount<5 && Diffseconds>30){  sas.refresh('sas_97587_2');  localStorage.setItem('tempcount_sas_97587_2', (Number(localStorage.getItem('tempcount_sas_97587_2'))+1)); localStorage.setItem('startDate_sas_97587_2', new Date()); }}},1000)"
      );
      $("#CustomAdsRefreshscript").append(refreshContentSc8);
    }, 5000);
  }
  //   componentDidUpdate(prevProps) {
  //     if (prevProps.match.params.slug !== this.props.match.params.slug || prevProps.match.params.type !== this.props.match.params.type) {
  //       this.setState({ Loading: true });
  //       this.loadData();
  //       document.body.scrollTop = 0;
  //       document.documentElement.scrollTop = 0;
  //       window.location.href = `./creator/${this.props.match.params.type}/${this.props.match.params.slug}`;
  //     }
  //   }
  //   componentWillUnmount() {
  //     window.location.reload();
  //   }
  loadData = async () => {
    this.setState({ Loading: true });
    var pageslug = this.props.params.slug;
    await axios
      .get(`/get-creators-by-slug/?slug=${pageslug}`, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json;charset=UTF-8",
        },
      })
      .then((res) => {
        this.setState({
          title: "title",
          content: this.getContent(res.data.data),
          Loading: false,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  getContent(data) {
    var type = this.props.params.type;
    console.log(type);
    let logoUrl = "";
    let backgroundImage = "";
    let fontFamily = "";
    let textColor = "";
    let followProfile = "";
    let meetTitle = "";
    let flagDate = new Date("2024-01-01");
    let flagDate2 = new Date("2024-06-05");
    let dateTime = new Date(data.publish_datetime).toLocaleDateString("en-US", {
      month: "2-digit",
      day: "2-digit",
      year: "numeric",
    });
    let newDateTime = new Date(dateTime);
    if (type === "guest" || type === "expert") {
      logoUrl =
        "https://contentadmin.latv.com/wp-content/uploads/2022/03/LATV-Livingyready-logo.jpg";
      backgroundImage =
        "https://contentadmin.latv.com/wp-content/uploads/2022/03/latv-livingyready-bgd-scaled.jpg";
      fontFamily = "DK Die Bruecke,Arial,sans-serif!important";
      textColor = "#523199 !important";
      followProfile =
        '<div class="col-md-5 icon-descriptions"><p class="icons-title">FOLLOW OUR GUESTS</p><p class="icons-text">Learn more about each of out guests, each week on Living y ready </p></div>';
      meetTitle = "Meet Our " + type;
    } else if (type === "storyteller" && flagDate2 < newDateTime) {
      logoUrl =
        "https://contentadmin.latv.com/wp-content/uploads/2024/06/LATV-Storytellers-2024-logo.png";
      backgroundImage =
        "https://contentadmin.latv.com/wp-content/uploads/2024/06/latv-latino-storytellers-background-scaled.jpg";
      fontFamily = "'Montserrat', sans-serif !important";
      textColor = "#ffffff !important";
    } else if (
      type === "storyteller" &&
      flagDate < newDateTime &&
      newDateTime < flagDate2
    ) {
      logoUrl =
        "https://contentadmin.latv.com/wp-content/uploads/2024/01/BHM-Final-Design-Storytellersstitle-1.png";
      backgroundImage =
        "https://contentadmin.latv.com/wp-content/uploads/2024/01/BHM-Final-Design-BACKGROUND1920_2400-edited.jpg";
      fontFamily = "'Montserrat', sans-serif !important";
      textColor = "#ffffff !important";
    } else if (type === "storyteller" && flagDate > newDateTime) {
      logoUrl =
        "https://contentadmin.latv.com/wp-content/uploads/2023/09/LATV-HHM-Storytellers-logo.png";
      backgroundImage =
        "https://contentadmin.latv.com/wp-content/uploads/2023/09/LATV-HHM-Storytellers-Microsite-bgd-2023-scaled.jpg";
      fontFamily = "'Montserrat', sans-serif !important";
      textColor = "#ffffff !important";
    } else if (type === "stop-the-dis") {
      logoUrl =
        "https://contentadmin.latv.com/wp-content/uploads/2024/02/Stop-The-DIS-Information-logo-footer-wStars.png";
      backgroundImage =
        "https://contentadmin.latv.com/wp-content/uploads/2024/02/Stop-The-Dis-noLogo-scaled.jpg";
      fontFamily = "'Montserrat', sans-serif !important";
      textColor = "#ffffff !important";
    } else if (type === "vamostraveling") {
      logoUrl =
        "https://contentadmin.latv.com/wp-content/uploads/2024/07/Vamos-Traveling-Logo-sDisney.png";
      backgroundImage =
        "https://contentadmin.latv.com/wp-content/uploads/2024/07/vamostraveling-background-2.jpg";
      fontFamily = "'Moderno' !important";
      textColor = "#ffffff !important";
    }

    // const  logoUrl= "https://contentadmin.latv.com/wp-content/uploads/2022/03/LATV-Livingyready-logo.jpg";
    return `
          <style>
            #content-rendered{
              background-image: url("${backgroundImage}");
            }
            .content-description h1, .content-description h2, .meet-title{
              font-family: ${fontFamily}; 
            }
            .meet-title{
              color: ${textColor}; 
            }
            </style>
          <div class="row content-container">
            <div class="col-md-6 offset-md-3">
            <!--<img class="vce-single-image vcv-lozad" data-src="https://contentadmin.latv.com/wp-content/uploads/2022/06/LATV-Livingyready-logo-1000x224.jpg" width="1000" height="224" srcset="https://contentadmin.latv.com/wp-content/uploads/2022/06/LATV-Livingyready-logo-320x72.jpg 320w,https://contentadmin.latv.com/wp-content/uploads/2022/06/LATV-Livingyready-logo-480x108.jpg 480w,https://contentadmin.latv.com/wp-content/uploads/2022/06/LATV-Livingyready-logo-800x179.jpg 800w,https://contentadmin.latv.com/wp-content/uploads/2022/06/LATV-Livingyready-logo-1000x224.jpg 1000w" src="" data-img-src="https://contentadmin.latv.com/wp-content/uploads/2022/03/LATV-Livingyready-logo.jpg" alt="LATV - Living y Ready" title="LATV - Living y Ready">-->
            <img class="vce-single-image vcv-lozad w-100" src="${logoUrl}">
            </div>
          </div>

          <div class="row row-special">
          <div class="col-md-10 offset-md-1">
            <h2 class="meet-title">
              ${meetTitle}
            </h2>
          </div></div>
          
          <br>
          
          <div class="row row-special">
            <div class="col-md-10 offset-md-1">
              <div class="row">
                <div class="col-md-6">
                  <img class="w-100" src="${data.post_image.custom_image_url}">
                </div>
                <div class="col-md-6 content-description">
                  ${data.acf.description}
                  <div class="icon-images">
                      <a target="_blank" href="${
                        data.acf.social_facebook
                      }" style="${
      data.acf.social_facebook !== "" ? "display:initial" : "display:none"
    }"><img src="${facebookIcon}"></a>
                      <a target="_blank" href="${
                        data.acf.social_instagram
                      }" style="${
      data.acf.social_instagram !== "" ? "display:initial" : "display:none"
    }"><img src="${instaIcon}"></a>
                      <a target="_blank" href="${
                        data.acf.social_youtube
                      }" style="${
      data.acf.social_youtube !== "" ? "display:initial" : "display:none"
    }"><img src="${youtubeIcon}"></a>
                      <a target="_blank" href="${
                        data.acf.social_twitter
                      }" style="${
      data.acf.social_twitter !== "" ? "display:initial" : "display:none"
    }"><img src="${linkedinIcon}"></a>
                      <a target="_blank" href="${
                        data.acf.social_linkedin
                      }" style="${
      data.acf.social_linkedin !== "" ? "display:initial" : "display:none"
    }"><img src="${twitterIcon}"></a>
                      <a target="_blank" href="${
                        data.acf.social_tiktok
                      }" style="${
      data.acf.social_tiktok !== "" ? "display:initial" : "display:none"
    }"><img src="${tiktokIcon}"></a>
                    </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6"></div>
                <div class="col-md-6">
                  <div class="row">
                    <div class="col-md-7 icon-images">
                    </div>
                    ${followProfile}
                  </div>
                </div>
              </div>

            </div>
          </div>
          
          `;
  }

  render() {
    const { Loading, Loadingcss, content, cssfile } = this.state;
    setTimeout(function () {
      var div = document.createElement("span");
      div.innerHTML = content;
      if (Loadingcss !== true) {
        $(document).ready(function () {
          $(
            `<link rel="preload" href="${
              mediastaticpages.mediaUrl
            }/wp-content/uploads/visualcomposer-assets/assets-bundles/${cssfile}.source.css?timestamp=${new Date().getTime()}" as="style" onload="this.onload=null;this.rel='stylesheet'">`
          ).appendTo("head");
          $(
            `<noscript><link rel="stylesheet" href="${
              mediastaticpages.mediaUrl
            }/wp-content/uploads/visualcomposer-assets/assets-bundles/${cssfile}.source.css?timestamp=${new Date().getTime()}"></noscript>`
          ).appendTo("head");
        });
      }

      if (Loading !== true) {
        if (content !== "" && content !== null && content !== "undefined") {
          $("#content-rendered").html("");
          $("#content-rendered").append(content);
          var header_part = document.getElementById("header_part");
          if (typeof header_part != "undefined" && header_part != null) {
            var clone = $("#header_part").parent().clone();
            $("#header_part").remove();
            clone.appendTo("#header_part_render");
          }

          $("#app-download").remove();
          $(".remove-placeholder").remove();
        } else {
          $("#content-rendered").html("");
        }
      }
    }, 500);
    return (
      <>
        <section
          id="read-section digitalseries"
          className="read-section mt-0 mt-md-5"
        >
          <div className="vcv-content--blank vcv-content--boxed">
            <article
              id=""
              className="page type-page status-publish hentry entry"
            >
              <div className="entry-content" id="content-rendered">
                {/* <div id="content-rendered"> */}
                {/* dangerouslySetInnerHTML={{ __html: content }}> */}
                {/* </div> */}
              </div>
            </article>
          </div>
          <div id="CustomAdsRefreshscript"></div>
        </section>
      </>
    );
  }
}
export default withParams(CreatorDetailsPageComponent);
