import React, { Component } from "react";
import axios from "../../apiConnections/axios";
import { useParams } from "react-router-dom";
import $ from "jquery";
import { Link } from "react-router-dom";
import "../../NewCss/lgbtPlaceholder.css";
import { getPageId } from "../Ads/pagesId/smartPageId.js";
import VideoComponent from "../VideoComponent/VideoComponent.js";
import SpecialsCarousel from "../Specials/SpecialsCarousel.js";
import SubscribeComponent from "../SubscribeComponent/SubscribeComponent.js";
import ArticlesSectionQueer from "../Articles/ArticlesComponent.js";
import ArticlesSubcategoryComponent from "../Articles/ArticlesSubcategoryComponent.js";
import { Helmet } from "react-helmet";

function withParams(Component) {
  return (props) => <Component {...props} params={useParams()} />;
}

class QueerSubcategoryComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      width: window.innerWidth,
      Loading: true,
      Posts: [],
      color: "",
      Tags: [],
      category: "queer",
      subcategory: "",
      sasSetup: false,
    };
  }

  componentDidMount() {
    // var category = this.props.match.params.cat;
    // this.handleResize();
    // this.resizeListener = window.addEventListener('resize', this.handleResize);
    // var subcategory = this.props.params.slug;

    var subcategory = this.props.category;
    this.setState({
      subcategory: subcategory,
    });
    this.loadPosts(subcategory);
  }

  componentDidUpdate(prevProps) {
    // Typical usage (don't forget to compare props):
    if (this.props.category !== prevProps.category) {
      var subcategory = this.props.category;
      this.setState({
        subcategory: subcategory,
      });
      this.loadPosts(subcategory);
    }
  }

  // componentDidUpdate() {
  //   console.log(this.state.subcategory, this.props.category);
  //   if (this.state.subcategory !== this.props.category) {
  //     this.setState({ Loading: true });
  //     // document.body.scrollTop = 0;
  //     // document.documentElement.scrollTop = 0;
  //     // window.location.href = `./${this.props.match.params.slug}`;
  //     var subcategory = this.props.category;
  //     this.loadPosts(subcategory);
  //   }
  // }

  handleResize = () => {
    let divider;
    if (window.innerWidth >= 821) {
      divider = 4;
    } else if (window.innerWidth < 821 && window.innerWidth >= 465) {
      divider = 2;
    } else if (window.innerWidth < 465) {
      divider = 1;
    }
    this.setState({
      width: window.innerWidth / divider,
      height: (window.innerWidth / divider) * (16 / 9),
    });
    // console.log(this.state.width,this.state.height)
    // var subcategory = this.props.match.params.slug;
    // this.loadPosts(subcategory);
  };

  // reLoadPosts = async () => {
  //   let newPosts = this.state.Posts;
  //       this.setState({ Posts: newPosts, Loading: false});
  //       console.log(this.state);
  //       $('#myCarousel').carousel({
  //         interval: 10000
  //       })
  //       if(window.innerWidth > 465){
  //         setTimeout(() => {
  //           $('.data-cloned').remove();
  //           $('.carousel .carousel-item').each(function () {
  //             var minPerSlide;
  //             if(window.innerWidth >= 820){
  //               minPerSlide = 2;
  //             }else if(window.innerWidth < 820 && window.innerWidth > 465){
  //               minPerSlide = 0;
  //             }
  //             var next = $(this).next();
  //             if (!next.length) {
  //               next = $(this).siblings(':first');
  //             }
  //             console.log("next1",next.children(':first-child'))
  //             next.children(':first-child').clone().appendTo($(this)).addClass('data-cloned');

  //             for (var i = 0; i < minPerSlide; i++) {
  //               next = next.next();
  //               if (!next.length) {
  //                 next = $(this).siblings(':first');
  //               }
  //               console.log("next2",next.children(':first-child'))

  //               next.children(':first-child').clone().appendTo($(this)).addClass('data-cloned');
  //             }
  //             if ($(window).width() < 750) {
  //               $('.caro-image').addClass('offset-2');
  //             }
  //           });
  //         }, 300);
  //       }
  // }

  asignColors = () => {
    const categories = new Set();
    const results = [];
    const colors = ["pink", "blue", "orange", "yellow", "green", "red"];
    this.state.Posts.slice(0, this.state.Posts.length).map((post, i) => {
      return categories.add(post.post_category[0].cat_name);
    });
    //the set its transformed to an array for easier management and looping
    const catArray = Array.from(categories);
    catArray.slice(0, catArray.length).map((category, i) => {
      return results.push({
        category: category,
        color: colors[i],
      });
    });
    this.setState({ CategoriesColors: results });
  };

  randomColor = () => {
    const colors = ["pink", "blue", "orange", "yellow", "green", "red"];
    const randomIndex = Math.floor(Math.random() * colors.length);
    // console.log(randomIndex);
    // console.log(colors[randomIndex]);
    this.setState({
      color: colors[randomIndex],
    });
  };

  LoadTags = async () => {
    // console.log(this.state)
    let shortedTags = this.state.Tags.slice(0, 4);
    const content2 = this.renderTags(shortedTags);
    this.setState({
      content2: content2,
    });
  };

  renderTags = (tags) => {
    // console.log(tags)
    const Tags = tags.map((tag, j) => {
      // var url = subMenu.url;
      // var pathname = url.slice(29);
      return (
        <li class="nav-item queer-item">
          <Link
            data-toggle="pill"
            to={`#`}
            className="nav-link pink-color white-background queerPills"
            id={`tag-${j}`}
            title={tag.tag_name}
          >
            {tag.tag_name}
          </Link>
        </li>
      );
    });

    // console.log(subMenusContent)
    return Tags;
  };

  loadPosts = async (subCategory) => {
    const category = this.state.category;
    this.setState({
      Loading: true,
    });
    await axios
      .get(
        `/get-post-by-category?page_no=1&category=${category}-${subCategory}&timestamp=${new Date().getTime()}`,
        // `/get-post-by-category?page_no=1&category=${category}&timestamp=${new Date().getTime()}`,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json;charset=UTF-8",
          },
        }
      )
      .then((res) => {
        this.setState({
          Posts: res.data.data,
          Loading: false,
          Tags: res.data.data[0].tags,
        });
        this.StructuredDataBreadcrumbList(subCategory);
        this.LoadTags();
        this.randomColor();
        // const extractedSrc = this.getVideoScript(res.data.data[0].post_content);
        // console.log(res.data.data[0].post_content);
        // //Get Id of script from src
        // const scriptId = this.getScriptId(extractedSrc);
        // console.log(scriptId, extractedSrc);
        // this.setState({
        //   TrendingVideoId: scriptId,
        //   TrendingVideoSrc: extractedSrc,
        // });
        // this.appendVideoScript(extractedSrc);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  appendVideoScript = (TrendingVideoSrc) => {
    var scriptJW = document.createElement("script");
    scriptJW.src = TrendingVideoSrc;
    scriptJW.defer = true;
    const videoDiv = document.querySelector('[id^="botr_"]');
    // const videoDiv = document.getElementById("botr_");
    document.body.appendChild(scriptJW);
  };

  getScriptId(srcString) {
    const scriptId = srcString
      .substring(srcString.indexOf("rs/") + 3, srcString.lastIndexOf(".js"))
      .replaceAll(/-/gi, "_");

    return scriptId;
  }

  getVideoScript(htmlString) {
    // Create a temporary div element to parse the HTML string
    const tempDiv = document.createElement("div");
    tempDiv.innerHTML = htmlString;

    // Find the script tag
    const scriptTag = tempDiv.querySelector(
      "script[src^='https://cdn.jwplayer.com/players/']"
    );

    // Extract the src attribute value
    const srcValue = scriptTag ? scriptTag.getAttribute("src") : null;

    return srcValue;
  }

  StructuredDataBreadcrumbList = (subCat) => {
    const structuredData = {
      "@context": "https://schema.org",
      "@type": "BreadcrumbList",
      itemListElement: [
        {
          "@type": "ListItem",
          position: 1,
          item: {
            "@id": "https://latv.com/",
            name: "Home",
          },
        },
        {
          "@type": "ListItem",
          position: 2,
          item: {
            "@id": `https://latv.com/queer/`,
            name: "queer",
          },
        },
        {
          "@type": "ListItem",
          position: 3,
          item: {
            "@id": `https://latv.com/queer/${subCat}`,
            name: subCat,
          },
        },
      ],
    };
    this.setState({
      breadcrumbsListScript: structuredData,
    });
  };

  render() {
    const {
      Loading,
      Posts,
      Pages,
      width,
      height,
      CategoriesColors,
      TrendingVideoId,
      subcategory,
      color,
      breadcrumbsListScript,
    } = this.state;
    return (
      <>
        <Helmet>
          {/* <script type="application/ld+json">
            {JSON.stringify(articleScript)}
          </script>
          <script type="application/ld+json">
            {JSON.stringify(listScript)}
          </script> */}
          <script type="application/ld+json">
            {JSON.stringify(breadcrumbsListScript)}
          </script>
        </Helmet>
        <section id="queer-subcategory">
          <div className="container queer">
            <h1 className="titleNewSubcat desktop">{subcategory}</h1>
            <div className="row">
              {!Loading ? (
                <>
                  <div className="col-xl-8 col-lg-8 col-md-12 col-sm-12 col-12">
                    <div className={"row image-container full-border-" + color}>
                      <div style={{ padding: 0 }}>
                        <img
                          className="img-fluid w-100 h-auto img-sizing"
                          srcSet={Posts[0].imgs
                            .toReversed()
                            .map((image, index) => {
                              if (image[1] === undefined) {
                                return [];
                              }
                              const Srcset = [];
                              const Zize = image[1].toString();
                              const link = image[0].toString();
                              Srcset.push(link + " " + Zize + "w");
                              return Srcset;
                            })}
                          src={Posts[0].post_image.full_image_url}
                          alt={Posts[0].post_image.image_alt}
                          width="1000px"
                          height="563px"
                        />
                      </div>
                      {/* <div id={`botr_${TrendingVideoId}_div`}></div> */}
                      <div className="image-text">
                        <p>
                          {/* <a href="#" className={this.state.color + " category"}>
                          {this.props.match.params.slug.replace("-", " ")}
                        </a> */}
                        </p>

                        <a className="lh-initial">
                          <h2
                            className={
                              "line-clamp main-post-title border-" + color
                            }
                            dangerouslySetInnerHTML={{
                              __html:
                                Posts[0].post_title !== null
                                  ? Posts[0].post_title.length > 70
                                    ? Posts[0].post_title.substr(0, 70) + `...`
                                    : Posts[0].post_title
                                  : ``,
                            }}
                          >
                            {/* {Posts[0].post_title} */}
                          </h2>
                        </a>
                      </div>
                    </div>
                    <div
                      className="row"
                      style={{ marginTop: "10px", marginBottom: "10px" }}
                    >
                      <div className="col-xl-6 col-lg-4 col-md-6 col-sm-12 col-12 author-section">
                        <p className="author-link">
                          By{" "}
                          <a
                            href={`/author/${Posts[0].author_login}/`}
                            className="author-name"
                            target="_blank"
                            rel="noreferrer"
                          >
                            {" "}
                            {Posts[0].author_name}
                          </a>
                        </p>
                      </div>
                      <div className="col-xl-6 col-lg-8 col-md-6 col-sm-12 col-12">
                        <ul
                          class="nav nav-pills nav-fill no-scroll"
                          id="MainPostArtTags"
                        >
                          {!this.state.Loading ? (
                            <>{this.state.content2}</>
                          ) : (
                            <></>
                          )}
                        </ul>
                      </div>
                    </div>
                    <div
                      className="row"
                      style={{ marginTop: "10px", marginBottom: "10px" }}
                    >
                      <div className="col">
                        <p className="queer">{Posts[0].short_description}</p>
                        <div style={{ textAlign: "end" }}>
                          <Link
                            to={
                              Posts[0].post_type === "watch"
                                ? `/queer/watch/${Posts[0].post_slug}`
                                : `/queer/${Posts[0].post_slug}/`
                            }
                            style={{ color: "#ED3897" }}
                          >
                            {" "}
                            READ MORE
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12">
                    <div className="row">
                      {Posts.slice(1, 4).map((post, index) => {
                        const {
                          ID,
                          post_category,
                          post_image,
                          post_title,
                          post_slug,
                          publish_date,
                          post_type,
                          imgs,
                          short_description,
                        } = post;
                        return (
                          <div
                            className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12"
                            style={{ marginBottom: "15px" }}
                          >
                            <div
                              className={"square-duo full-border-" + color}
                              style={{ margin: "auto", position: "relative" }}
                            >
                              <Link to={`/queer/${post_slug}/`}>
                                <div>
                                  <img
                                    sizes="(max-width: 480px) 100vw, (max-width: 768px) 50vw, 33vw img-sizing"
                                    srcSet={imgs
                                      .toReversed()
                                      .map((image, index) => {
                                        if (image[1] === undefined) {
                                          return [];
                                        }
                                        const Srcset = [];
                                        const Zize = image[1].toString();
                                        const link = image[0].toString();
                                        Srcset.push(link + " " + Zize + "w");
                                        return Srcset;
                                      })}
                                    src={post_image.medium_image_url}
                                    // cache
                                    className="img-fluid img-responsive w-100 "
                                    alt="alt"
                                    width="1000px"
                                    height="563px"
                                  />
                                  <div className="image-text-second">
                                    {/* <p>
                                      <a
                                        href="#"
                                        className={color + " slide-category"}
                                      >
                                        {post.post_category[0].cat_name}
                                      </a>
                                    </p> */}

                                    <a className="lh-initial">
                                      <h2
                                        className={
                                          "line-clamp second-post-title border-" +
                                          color
                                        }
                                        dangerouslySetInnerHTML={{
                                          __html:
                                            post_title !== null
                                              ? post_title.length > 70
                                                ? post_title.substr(0, 70) +
                                                  `...`
                                                : post_title
                                              : ``,
                                        }}
                                      ></h2>
                                    </a>
                                  </div>
                                </div>
                              </Link>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <section id="main" className="container-xl">
                    <div className="row mt-5">
                      <div className="col-md-8 col-12">
                        <div className="row p-3">
                          <div
                            className="img-fluid image-placeholder placeholder img-sizing p-0"
                            alt=""
                          ></div>
                        </div>
                      </div>
                      <div className="col-md-4 col-12 p-3">
                        <div className="row mb-3">
                          <div
                            className="img-fluid image-placeholder placeholder img-sizing p-0"
                            alt=""
                          ></div>
                        </div>
                        <div className="row mb-2">
                          <div
                            className="img-fluid image-placeholder placeholder img-sizing p-0"
                            alt=""
                          ></div>
                        </div>
                        <div className="row">
                          <div
                            className="img-fluid image-placeholder placeholder img-sizing p-0"
                            alt=""
                          ></div>
                        </div>
                      </div>
                    </div>
                    <div className="row mt-5 mb-5">
                      <div className="row col-md-2 col-12"></div>
                      <div className="row col-md-8 col-12 ">
                        <div className="row mb-3">
                          <div
                            className="img-fluid image-placeholder placeholder img-sizing p-0"
                            alt=""
                          ></div>
                        </div>
                        <div className="row mb-3">
                          <h2 className="title-placeholder placeholder"></h2>
                        </div>
                        <div className="row mb-2">
                          <div className="main-box-content text-placeholder placeholder"></div>
                        </div>
                        <div className="row">
                          <div className="main-box-content text-placeholder placeholder"></div>
                        </div>
                      </div>
                      <div className="row col-md-2 col-12"></div>
                    </div>
                  </section>
                </>
              )}
            </div>
          </div>
          <VideoComponent />
          <SpecialsCarousel title={"Specials"} />
          <SubscribeComponent />
          {!Loading ? (
            <ArticlesSubcategoryComponent slug={subcategory} />
          ) : (
            <></>
          )}
          {/* <SubCategoriesArticlesQueerComponent
          slug={this.props.match.params.slug}
        /> */}
        </section>
      </>
    );
  }
}
export default QueerSubcategoryComponent;
