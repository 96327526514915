import logoImage from "../../images/LATV-new-logo.png";
import queerLogo from "../../images/image2.svg";
import queerLogo2 from "../../images/image3.png";
// import queerLogo from "../../Images/image2.svg";
// import queerLogo2 from "../../Images/image3.png";
import React, { Component } from "react";
import { Link } from "react-router-dom";
import axios from "../../apiConnections/axios";
import $ from "jquery";
import { Modal } from "react-bootstrap";
import "../../NewCss/lgbtPlaceholder.css";
import SmartAppBanner from "../SmartAppBanner/SmartAppBanner";
// import Img from "react-cool-img";
// import "../../NewCss/Home.css";
// export const config = { amp: 'hybrid' }

// const AmpMode = ({ children, ampOnly }) => {
//   const isAmp = useAmp()
//   if (ampOnly) {
//     return isAmp ? children : null
//   }
//   return isAmp ? null : children
// }

// const injectGA = () => {
//   if (typeof window == 'undefined') {
//     return;
//   }
//   window.dataLayer = window.dataLayer || [];
//   function gtag() {
//     window.dataLayer.push(arguments);
//   }
//   gtag('js', new Date());
//   gtag('config', 'UA-3906388-4');
// };

class HeaderQueer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      Loading: true,
      Menus: [],
      SecondaryMenu: "",
      posts: [],
      tags: [],
      content: "",
      status: "start",
      // show: false
      showHide: false,
      searchText: "",
      mobileDevice: false,
      subCat: [],
      category: [],
    };
    this.toggleSidebar = this.toggleSidebar.bind(this);
    //this.testFunction = this.testFunction.bind(this)
  }

  handleModalShowHide() {
    this.setState({ showHide: !this.state.showHide });
  }

  do_load = () => {
    // console.log("entro en el nuevo header")
    var s1 = document.createElement("script"),
      el = document.getElementsByTagName("script")[0];
    s1.defer = true;
    s1.src = "https://ced.sascdn.com/tag/3928/smart.js";
    el.parentNode.insertBefore(s1, el);
    var mySidepanelxpos = $("#top-menu").height();
    $("#mySidepanel").css("top", 178 + "px");
    // if (window.location.protocol == 'http:') {
    //     window.location.href =  window.location.href.replace('http:', 'https:');
    // }
    // else if (window.location.protocol == "https:") {

    // }
  };
  searchType = (e) => {
    const { value } = e.target;
    this.setState({ searchText: value });
  };
  handleSearch = (e) => {
    e.preventDefault();
    window.location.href =
      window.location.origin +
      `/${this.state.category}/search/${this.state.searchText}`;
  };

  componentDidMount() {
    // crear funcion que determine si el path tiene categoria y llame al endpoint usando el PRIMER valor del arreglo resultante al hacer split de /
    this.getCategory(this.props.location.pathname);
    this.loadMenu();
    // this.mobileListener = window.addEventListener('mobile', this.handleMobileDevices);
    this.handleMobileDevices();
    window.addEventListener("resize", this.handleMobileDevices);

    var self = this;
    setTimeout(function () {
      if (self.state.status === "start") {
        self.state.status = "loading";
        self.do_load();
      }
    }, 2000);
  }

  awaitLoadMenu = () => {
    setTimeout(() => {
      this.loadMenu();
    }, 100);
  };

  loadMenu = async () => {
    const content = this.renderItem(this.props.menu);
    const content2 = this.renderSubCategory(this.props.menu);
    const secondaryContent = this.renderSecondaryItem(this.props.secondaryMenu);
    var firstSlash = this.props.location.pathname.split("/");
    var mainCategory = firstSlash[1];
    var slashIndex = this.props.location.pathname.lastIndexOf("/") + 1;
    var subCat = this.props.location.pathname.substr(slashIndex);
    const self = this;
    setTimeout(function () {
      const content2 = self.renderTopAd();
      var div = document.createElement("span");
      div.innerHTML = content2;
      $("#bannerAd_sas_119619_23456").html("");
      $("#bannerAd_sas_119619_23456").append(content2);
    }, 100);
    if (subCat === mainCategory) {
      subCat = "";
    } else {
      subCat = " - " + subCat.toUpperCase();
    }
    this.setState({
      subCat: subCat,
      content: content,
      content2: content2,
      Menus: this.props.menu,
      SecondaryMenu: secondaryContent,
      Loading: false,
    });
  };

  loadPosts = async (category) => {
    await axios
      .get(
        `/get-post-by-category?page_no=1&category=${category}&timestamp=${new Date().getTime()}`,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json;charset=UTF-8",
          },
        }
      )
      .then((res) => {
        this.setState({
          posts: res.data.data,
        });
        this.getTags(res.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  toggleSidebar = () => {
    $("#mySidepanel").toggleClass("side-open");
  };

  handleMobileDevices = () => {
    if (window.innerWidth < 1024) {
      this.setState({ mobileDevice: true });
    } else {
      this.setState({ mobileDevice: false });
    }
  };

  getCategory = (path) => {
    const selectedCategory = path.split("/");
    this.setState({ category: selectedCategory[1] });
    //   selectedCategory[1] && this.loadPosts(selectedCategory[1]);
    // };
    // getTags = (response) => {
    //   let selectedTags = [];
    //   let tagsObject = [];
    //   let tagsName = [];
    //   let tagsUrl = [];
    //   let tagInfo = [];
    //   let postType = [];
    //   const topPosts = response.slice(0, 6);
    //   topPosts.map((item) =>
    //     item.tags.length > 0
    //       ? (selectedTags.push(item.tags),
    //         tagsUrl.push(item.post_slug),
    //         postType.push(item.post_type))
    //       : null
    //   );
    //   selectedTags.map((elem) => tagsObject.push(elem.slice(0, 1)));
    //   const objectLength = Object.keys(tagsObject).length;
    //   for (let i = 0; i < objectLength; i++) {
    //     tagsObject[i].map((result) => tagsName.push(result.tag_name));
    //   }
    //   for (let k = 0; k <= 4; k++) {
    //     tagInfo[k] = { name: tagsName[k], url: tagsUrl[k], type: postType[k] };
    //   }
    //   tagInfo = tagInfo.filter(
    //     (value, index, self) =>
    //       index === self.findIndex((t) => t.name === value.name)
    //   );
    //   this.setState({ tags: tagInfo.slice(0, 3) });
  };

  renderItem = (menus) => {
    // const shareUrl = this.props.urls.site_postfix;
    const menusContent = menus.map((menu, i) => {
      var firstSlash = this.props.location.pathname.split("/");
      var mainCategory = firstSlash[1];
      var lastIndex = menu.url.lastIndexOf("/") + 1;
      var subCategory = menu.url.substr(lastIndex);
      var url = menu.url;
      var pathname = url.slice("/")[1];
      // const { location } = this.props.location;
      // var pathname = 'http://staging.latv.com' + new URL(url).pathname;
      // console.log('menu', menu.url.slice(16))
      // console.log('path', location.pathname)
      if (pathname === this.props.location || i === 6) {
        return <></>;
      }
      if (menu.children.length !== 0) {
        if (subCategory === mainCategory) {
          return <></>;
        }
        return (
          <li className="nav-item dropdown" key={menu.ID}>
            <Link
              to={url}
              className="nav-link"
              id="navbardrop"
              title={menu.title}
            >
              {menu.title}
            </Link>
            {/* <Link to={pathname} className="nav-link" id="navbardrop" title={menu.title} >
              {menu.title}
            </Link> */}
          </li>
        );
      } else {
        return (
          <li href={pathname} className="nav-item" key={i}>
            <Link to={pathname} title={menu.title}>
              <button className="nav-link" title={menu.title}>
                {menu.title}
              </button>
            </Link>
          </li>
        );
      }
    });

    return menusContent;
  };

  renderSubCategory = (menus) => {
    var slashIndex = this.props.location.pathname.lastIndexOf("/") + 1;
    var subCat = this.props.location.pathname.substr(slashIndex);
    // const shareUrl = this.props.urls.site_postfix;
    const subMenus = menus.find((element) => element.title === "QUEER");
    const subMenusContent = subMenus.children.map((subMenu, j) => {
      var url = subMenu.url;
      // var pathname = url.slice(29); //staging
      var pathname = url.slice(17);
      if (j === 0) {
        return (
          <>
            <li className="nav-item">
              <Link
                data-toggle="pill"
                to={`/queer`}
                onClick={this.awaitLoadMenu}
                className={
                  "queer" === subCat
                    ? "nav-link white latvPills active"
                    : "nav-link white latvPills"
                }
                id="navbardrop"
                title="Latv Queer"
              >
                Latv Queer
              </Link>
            </li>
            <li className="nav-item">
              <Link
                data-toggle="pill"
                to={`/${pathname}`}
                onClick={this.awaitLoadMenu}
                className={
                  subCat === subMenu.title.toLowerCase()
                    ? "nav-link white latvPills active"
                    : "nav-link white latvPills"
                }
                id={`navbardrop-${j}`}
                title={subMenu.title}
              >
                {subMenu.title}
              </Link>
            </li>
          </>
        );
      } else {
        return (
          <li className="nav-item">
            <Link
              data-toggle="pill"
              to={`/${pathname}`}
              onClick={this.awaitLoadMenu}
              className={
                subCat === subMenu.title.toLowerCase()
                  ? "nav-link white latvPills active"
                  : "nav-link white latvPills"
              }
              id={`navbardrop-${j}`}
              title={subMenu.title}
            >
              {subMenu.title}
            </Link>
          </li>
        );
      }
    });
    return subMenusContent;
  };

  renderSecondaryItem = (menus) => {
    const menusContent = menus.map((menu, i) => {
      const url = menu.url;
      const menuTitle = menu.title;
      // console.log(url,menuTitle)
      return (
        <span>
          <a className="quickMenuItem" href={url}>
            {menuTitle}
          </a>
          &nbsp;{i !== menus.length - 1 ? "|" : ""}&nbsp;
        </span>
      );
    });
    return menusContent;
  };

  renderTopAd() {
    const topAdFunction = `
    <script>sas.cmd.push(function() 
    {sas.call('std', {siteId:391880,pageId:1701583,formatId:119619,tagId:'bannerAd_sas_119619_23456'}, { resetTimestamp: true });});</script>
    `;
    return topAdFunction;
  }

  render() {
    return (
      <>
        <SmartAppBanner />
        <div className="headerMenu">
          <nav className="navbar navbar-expand-lg z-10">
            <div className="container-fluid">
              <Link className="navbar-brand d-flex" to={`/`} title={"LATV"}>
                <img
                  placeholder={logoImage}
                  src={logoImage}
                  cache
                  className={
                    this.state.mobileDevice
                      ? "img-fluid img-fluid-mobile"
                      : "img-fluid"
                  }
                  alt="LATV-logo"
                  title="LATV"
                  loading="lazy"
                  data-toggle="tooltip"
                />
                <span className="d-none d-sm-none d-md-none d-lg-block d-xl-block">
                  Media Representing The LatiNation
                </span>
              </Link>
              <button
                className="navbar-toggler"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#navbarSupportedContent"
                aria-controls="navbarSupportedContent"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <span className="navbar-toggler-icon"></span>
              </button>
              <div
                className="collapse navbar-collapse-queer"
                tabIndex="-1"
                id="navbarSupportedContent"
                aria-labelledby="navbarNav"
                style={{ zIndex: "5" }}
              >
                <div className="offcanvas-body">
                  <ul className="navbar-nav flex-grow-1 pe-3">
                    {!this.state.Loading ? <>{this.state.content}</> : <></>}
                    {/* <li className="nav-item searchIcon">
                      <button
                        className=""
                        onClick={() => this.handleModalShowHide()}
                        data-toggle="tooltip"
                        title="Search"
                      >
                        <p>
                          <i className="fa fa-search"></i>
                        </p>
                      </button>
                    </li> */}
                    {/* <li className="nav-item latvApp">
                      <a className="latvAppBtn d-flex" href={`/download`}>WATCH <span className="d-none d-sm-block d-md-block d-lg-none d-xl-block">NOW</span></a>
                    </li> */}
                    <li className="nav-item d-block d-sm-block-d-md-none d-lg-none col-12 text-center socialNav">
                      <div className="socialNetworks">
                        <a
                          href="https://www.instagram.com/latinationqueer"
                          target={"_blank"}
                          rel="noreferrer"
                          data-toggle="tooltip"
                          title="Instagram"
                        >
                          <i className="btn fa-brands fa fa-instagram"></i>
                        </a>
                        <a
                          href={"https://www.tiktok.com/@latinationqueer"}
                          target="_blank"
                          rel="noreferrer"
                          data-toggle="tooltip"
                          title=""
                          data-original-title="Instagram"
                        >
                          <i className="btn fa-brands fa fa-tiktok"></i>
                        </a>
                        <a
                          href="https://www.facebook.com/LatvNetwork/"
                          target={"_blank"}
                          rel="noreferrer"
                          data-toggle="tooltip"
                          title="Facebook"
                        >
                          <i className="btn fa-brands fa fa-facebook"></i>
                        </a>
                        <a
                          href="https://www.youtube.com/user/LATVNetwork"
                          target={"_blank"}
                          rel="noreferrer"
                          data-toggle="tooltip"
                          title="Youtube"
                        >
                          <i className="btn fa-brands fa fa-youtube"></i>
                        </a>
                        <a
                          href="https://twitter.com/latvnetwork"
                          target={"_blank"}
                          rel="noreferrer"
                          data-toggle="tooltip"
                          title="Twitter"
                        >
                          <i className="btn fa-brands fa fa-twitter"></i>
                        </a>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </nav>
          {/* <div className="mobileQuickMenu">
            {!this.state.Loading ? <>{this.state.SecondaryMenu}</> : <></>}
          </div> */}
        </div>
        <div className="sticky-top">
          <div className="midHeader d-block d-sm-block d-md-block d-lg-block ">
            <div className="row midLogo">
              {this.state.mobileDevice ? (
                <>
                  <div className="col">
                    <h2>
                      <Link to={`/queer/`} id="logo" title="Latv Queer">
                        <img
                          placeholder={queerLogo}
                          src={queerLogo}
                          cache
                          className={
                            this.state.mobileDevice
                              ? "img-fluid img-fluid-mobile"
                              : "img-fluid"
                          }
                          alt={`LATV QUEER${this.state.subCat}`}
                          title={`LATV QUEER${this.state.subCat}`}
                          loading="lazy"
                          data-toggle="tooltip"
                        />
                      </Link>
                    </h2>
                  </div>

                  <div className="col nav-item searchIconMobile">
                    <button
                      className=""
                      onClick={() => this.handleModalShowHide()}
                      data-toggle="tooltip"
                      title="Search"
                    >
                      <i className="fa fa-search"></i>
                    </button>
                  </div>
                </>
              ) : (
                <>
                  <div className="nav-item searchIconMobile col">
                    {/* <button
                      className=""
                      onClick={() => this.handleModalShowHide()}
                      data-toggle="tooltip"
                      title="Search"
                    >
                      <i className="fa fa-search"></i>
                    </button> */}
                  </div>
                  <h2 className="newLogoH2 col">
                    <Link to={`/queer/`} id="logo" title="Latv Queer">
                      <img
                        placeholder={queerLogo}
                        src={queerLogo2}
                        cache
                        className={
                          this.state.mobileDevice
                            ? "img-fluid img-fluid-mobile"
                            : "img-fluid newLogo"
                        }
                        alt={`LATV QUEER${this.state.subCat}`}
                        title={`LATV QUEER${this.state.subCat}`}
                        loading="lazy"
                        data-toggle="tooltip"
                      />
                    </Link>
                  </h2>
                  <div className="nav-item searchIconMobile col">
                    <button
                      className=""
                      onClick={() => this.handleModalShowHide()}
                      data-toggle="tooltip"
                      title="Search"
                    >
                      <i className="fa fa-search"></i>
                    </button>
                  </div>
                  {/* <SasAdComponent sasId={"119619"}/> */}
                  {/* <div className="top-adverties">
                <div id="bannerAd_sas_119619_23456" className="Ad119619"></div>
              </div> */}
                </>
              )}
            </div>
          </div>
          <div
            id="headerQueer"
            className="supraHeaderQueer d-block d-sm-block d-md-block d-lg-block"
          >
            <div className="row">
              <div className="col-lg-11 col-sm-12 text-start queerPills2 socialNetworks">
                <ul className="nav nav-pills nav-fill no-scroll">
                  {!this.state.Loading ? <>{this.state.content2}</> : <></>}
                </ul>
              </div>
              <div className="col-1 nav-item searchIcon">
                {/* <button
                  className=""
                  onClick={() => this.handleModalShowHide()}
                  data-toggle="tooltip"
                  title="Search"
                >
                  <p>
                    <i className="fa fa-search"></i>
                  </p>
                </button> */}
              </div>
            </div>
          </div>
        </div>

        <Modal
          show={this.state.showHide}
          dialogClassName="my-modal"
          animation={false}
        >
          <Modal.Header closeButton onClick={() => this.handleModalShowHide()}>
            <Modal.Title>Search</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <form onSubmit={() => this.handleSearch()}>
              <div className="container">
                <div className="row">
                  <div className="col-md-11">
                    <div className="form-group">
                      <input
                        type="text"
                        className="form-control"
                        onChange={this.searchType}
                        id="s"
                        name="s"
                        placeholder="Search Keywords..."
                        autoComplete="off"
                      />
                    </div>
                  </div>
                  <div className="col-md-1">
                    <button
                      type="submit"
                      onClick={this.handleSearch}
                      className="search-btn"
                    >
                      <i className="fa fa-search"></i>
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </Modal.Body>
        </Modal>
      </>
    );
  }
}
export default HeaderQueer;
