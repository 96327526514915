import React, { Component } from "react";
import logo from "../../images/LATV-new-logo-white.png";
import MailchimpNewFormComponent from "../SharedComponents/MailchimpNewFormComponent";
const actualYear = new Date().getFullYear();
class FooterQueer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      Loading: true,
      Menus: [],
      igSlug: "latinationqueer",
    };
  }

  render() {
    return (
      <>
        <section id="footer-section-queer" className="footer-section-queer">
          <div className="row mt-3">
            <MailchimpNewFormComponent category={"/queer"} type={"footer"} />
            <div className="d-none d-sm-none d-md-block d-lg-block d-xl-block col-lg-8 col-md-7 col-sm-8">
              <a href="/">
                <div className="footer-logo ">
                  <img
                    className="footer-logo-img no-js-wQYACoR+I8i5xH2AAAAAElFTkSuQmCC"
                    src={logo}
                    alt="LATV-logo"
                    data-toggle="tooltip"
                    title=""
                    data-original-title="Latv"
                  />
                </div>
              </a>
            </div>
            <div className="d-none d-sm-none d-md-block d-lg-block d-xl-block col-lg-4 col-md-5 col-sm-4">
              <ul className="footer-menu list-unstyled card-columns">
                <li className="f_list_item">
                  <a href="https://latv.com/about-us">ABOUT US</a>
                </li>
                <li className="f_list_item">
                  <a href="https://partners.latv.com/cookie">
                    ADVERTISE WITH US
                  </a>
                </li>
                <li className="f_list_item">
                  <a href="https://latv.com/contact-us">CONTACT US</a>
                </li>
                <li className="f_list_item">
                  <a href="https://latv.com/jobs">JOBS</a>
                </li>
                <li className="f_list_item">
                  <a href="https://latv.com/privacy-policy">PRIVACY POLICY</a>
                </li>
                <li className="f_list_item">
                  <a href="https://latv.com/schedule">SCHEDULE</a>
                </li>
                <li className="f_list_item">
                  <a href="https://latv.com/terms-conditions">
                    TERMS &amp; CONDITIONS
                  </a>
                </li>
                <li className="f_list_item">
                  <a href="https://latv.com/schedule">WHERE TO WATCH</a>
                </li>
              </ul>
            </div>
            <div className="d-block d-sm-block d-md-none col-12">
              <ul className="list-unstyled  card-columns centerText">
                <li className="f_list_item">
                  <a href="https://latv.com/about-us">ABOUT US</a>
                </li>
                <li className="f_list_item">
                  <a href="https://partners.latv.com/cookie">
                    ADVERTISE WITH US
                  </a>
                </li>
                <li className="f_list_item">
                  <a href="https://latv.com/contact-us">CONTACT US</a>
                </li>
                <li className="f_list_item">
                  <a href="https://latv.com/jobs">JOBS</a>
                </li>
                <li className="f_list_item">
                  <a href="https://latv.com/privacy-policy">PRIVACY POLICY</a>
                </li>
                <li className="f_list_item">
                  <a href="https://latv.com/schedule">SCHEDULE</a>
                </li>
                <li className="f_list_item">
                  <a href="https://latv.com/terms-conditions">
                    TERMS &amp; CONDITIONS
                  </a>
                </li>
                <li className="f_list_item">
                  <a href="https://latv.com/schedule">WHERE TO WATCH</a>
                </li>
              </ul>
            </div>
            <div className="d-block d-sm-block d-md-none col-lg-8 col-md-7 col-sm-8">
              <a href="/">
                <div className="footer-logo centerText">
                  <img
                    className="footer-logo-img no-js-wQYACoR+I8i5xH2AAAAAElFTkSuQmCC"
                    src={logo}
                    alt="LATV-logo"
                    data-toggle="tooltip"
                    title=""
                    data-original-title="Latv"
                  />
                </div>
              </a>
            </div>
          </div>
        </section>
        <section id="post-footer" className="container-fluid">
          <div className="row">
            <div className="col-md-9 col-sm-6 col-12 ">
              <div className="col-md-9 col-sm-6 col-12">
                Media Representing The LatiNation
              </div>
              <div className="col main-box-content">
                LATV Networks, LLC © {actualYear}
              </div>
            </div>
            <div className="col-md-3 col-sm-6 col-12  socialNetworks">
              <a
                href={"https://www.instagram.com/" + this.state.igSlug}
                target="_blank"
                rel="noreferrer"
                title="Instagram"
              >
                <i className="btn fa-brands fa fa-instagram"></i>
              </a>
              <a
                href={"https://www.tiktok.com/@" + this.state.igSlug}
                target="_blank"
                rel="noreferrer"
                title="Instagram"
              >
                <i className="btn fa-brands fa fa-tiktok"></i>
              </a>
              <a
                href="https://www.facebook.com/LatvNetwork/"
                target="_blank"
                rel="noreferrer"
                title="Facebook"
              >
                <i className="btn fa-brands fa fa-facebook"></i>
              </a>
              <a
                href="https://www.youtube.com/user/LATVNetwork"
                target="_blank"
                rel="noreferrer"
                title="Youtube"
              >
                <i className="btn fa-brands fa fa-youtube"></i>
              </a>
              <a
                href="https://twitter.com/latvnetwork"
                target="_blank"
                rel="noreferrer"
                title="Twitter"
              >
                <i className="btn fa-brands fa fa-twitter"></i>
              </a>
            </div>
          </div>
        </section>
      </>
    );
  }
}
export default FooterQueer;
