import React, { Component } from "react";
import $ from "jquery";
import axios from "../../apiConnections/axios.js";
import { useParams } from "react-router-dom";
import { Link, Redirect } from "react-router-dom";
import ImageResize from "../ImageResize/ImageResize.js";
import SpecialsComponent from "../SharedComponents/SpeciaslComponent.js";
import InfiniteScroll from "react-infinite-scroll-component";
import loadingSVG from "../../images/loadingSVG.svg";
import GridArticleComponent from "../SharedComponents/GridArticleComponent.js";
import GridComponent from "../SharedComponents/GridComponent.js";
import { getPageId } from "../Ads/pagesId/smartPageId.js";
import { getAdFunction } from "../Ads/AdsFunctions/getAdFunction.js";
import { initializeSas } from "../Ads/AdsFunctions/initilizeSas.js";
import { renderAd } from "../Ads/AdsFunctions/renderAd.js";
import SpecialsCarousel from "../Specials/SpecialsCarousel.js";
import {
  EmailIcon,
  EmailShareButton,
  FacebookIcon,
  FacebookShareButton,
  LinkedinIcon,
  LinkedinShareButton,
  TwitterIcon,
  TwitterShareButton,
} from "react-share";
import TrendingVideo from "../SharedComponents/TrendingVideo.js";
import MailchimpFormComponent from "../SharedComponents/MailchimpFormComponent.js";
import GridComponent2 from "../SharedComponents/GridComponent2.js";
import "../../styles/ads-default.css";
import { Helmet } from "react-helmet";
let flagApi = false;

function withParams(Component) {
  return (props) => <Component {...props} params={useParams()} />;
}

class QueerArticleDetail extends Component {
  insta_token =
    "IGQWRQWlNCMTB6bnlOQkc5NG5iMGZA3OU81b2lYa0dNVmlFb09neHZAMVlp0T1VrR2NhN3diMTBsckp6UzRuQTZAfMU9XOVNSNkRxVExFTjczdGg0aGZAUTkdnQnpkWlVhNzBRMmNNSDlCSDV2b0VBVXlNRVBabVFFUjgZD";
  constructor(props) {
    super(props);
    this.state = {
      SiteId: "391880",
      PageId: 0,
      Loading: true,
      LoadingAll: true,
      Post: [],
      LatestPosts: [],
      ReletedPaginatioPost: [],
      pageNumber: 1,
      searchSlug: "",
      status: "start",
      hasMore: true,
      mainPostId: "",
      category: "queer",
      mainPostCategory: "",
      mainPostCategorySlug: "",
      subCategories: [],
      articleTags: [],
      articleAuthor: "",
      articleTitle: "",
      totalPages: 2,
      reletedGridData: [],
      visibility: false,
      randomID: "",
      GlobalsasDynamiccount: 0,
      GlobalstartDate: "",
      GlobalAdsRefreshCount: 0,
      GlobalScrollAdsIds: [],
      TrendingVideoId: "acKr2ukJ_owsYDZVC",
      TrendingVideoSrc:
        "https://cdn.jwplayer.com/players/acKr2ukJ-owsYDZVC.js?search=__CONTEXTUAL__",
      igScript:
        '<script async defer src="//www.instagram.com/embed.js"></script>',
      twScript:
        '<script async defer src="https://platform.twitter.com/widgets.js" charset="utf-8"></script>',
      ptScript:
        '<script async defer src="//assets.pinterest.com/js/pinit.js"></script>',
      hasSpecials: false,
    };
  }

  do_load = () => {
    //Trending Video script
    var scriptTrendingVid = document.createElement("script");
    scriptTrendingVid.src = this.state.TrendingVideoSrc;
    scriptTrendingVid.defer = true;
    document.body.appendChild(scriptTrendingVid);
    // const { Post } = this.state;
    // const scriptArray = Post?.post_content_scriptJWP_array ?? [];
    // scriptArray.forEach((scriptString) => {
    //   this.appendScriptElementFromString(scriptString);
    // });
  };

  afterFirstPost() {
    // console.log("afterfirtspost")
    const mainPost = this.state.Post;
    if (
      mainPost.post_title !== "" &&
      mainPost.post_title !== null &&
      mainPost.post_title !== "undefined"
    ) {
      var MyDiv = document.getElementById("content-rendered");
      // console.log(MyDiv)
      if (MyDiv !== null) {
        // console.log("entra a mydiv")
        var arr = MyDiv.getElementsByTagName("script");
        var arrIframe = MyDiv.getElementsByTagName("iframe");
        for (var n = 0; n < arr.length; n++) {
          if (arr[n].src.includes("https://cdn.jwplayer.com/players/")) {
            // console.log("includes script?")
            var filename = arr[n].src
              .substring(arr[n].src.lastIndexOf("/") + 1)
              .split(".");
            if (filename && filename !== undefined) {
              const querySelector =
                'p script[src*="https://cdn.jwplayer.com/players/"]';
              const scriptTags = document.querySelectorAll(querySelector);
              scriptTags.forEach((scriptTag) => {
                const s = new XMLSerializer();
                const newScriptTag = s.serializeToString(scriptTag);
                this.appendScriptElementFromString(newScriptTag);
              });
            }
          }
        }
        for (var m = 0; m < arrIframe.length; m++) {
          if (arrIframe[m].src.includes("https://cdn.jwplayer.com/players/")) {
            // console.log("includes player")
            var filename2 = arrIframe[m].src
              .substring(arrIframe[m].src.lastIndexOf("/") + 1)
              .split(".");
            const filenameCero = filename2[0];
            if (filename2 && filename2 !== undefined) {
              const querySelector =
                'div iframe[src*="https://cdn.jwplayer.com/players/"]';
              const iframetTags = document.querySelectorAll(querySelector);
              // console.log(iframetTags)
              iframetTags.forEach((iframeTag) => {
                // console.log(iframeTag);
                // const sriptSrc = "https://cdn.jwplayer.com/players/" + filenameCero + ".html";
                // const script = document.createElement('script');
                // script.src = sriptSrc;
                // console.log(script);
                // const s = new XMLSerializer();
                // const newScriptTag = s.serializeToString(script)
                // this.appendScriptElementFromStringIframe(newScriptTag);
                const sriptSrc =
                  "https://cdn.jwplayer.com/players/" + filenameCero + ".js";
                const script = document.createElement("script");
                script.src = sriptSrc;
                // Replace the script tag with the container div
                iframeTag.parentNode.replaceWith(script);
                var arr = MyDiv.getElementsByTagName("script");
                // console.log(arrIframe)
                for (var n = 0; n < arr.length; n++) {
                  if (
                    arr[n].src.includes("https://cdn.jwplayer.com/players/")
                  ) {
                    // console.log("includes script?")
                    var filename = arr[n].src
                      .substring(arr[n].src.lastIndexOf("/") + 1)
                      .split(".");
                    if (filename && filename !== undefined) {
                      const querySelector =
                        'script[src*="https://cdn.jwplayer.com/players/"]';
                      const scriptTags =
                        document.querySelectorAll(querySelector);
                      scriptTags.forEach((scriptTag) => {
                        const s = new XMLSerializer();
                        const newScriptTag = s.serializeToString(scriptTag);
                        this.appendScriptElementFromString(newScriptTag);
                      });
                    }
                  }
                }
              });
            }
          }
        }
      }
    } else {
      $("#content-rendered").html("");
    }
  }

  afterFollowingPost(currentPostArr) {
    let currentPost = currentPostArr[0];
    var div = document.createElement("span");
    div.innerHTML = currentPost.post_content;
    if (
      currentPost.post_title !== "" &&
      currentPost.post_title !== null &&
      currentPost.post_title !== "undefined"
    ) {
      var MyDiv = document.getElementById(currentPost.ID);
      if (MyDiv !== null) {
        var arr = MyDiv.getElementsByTagName("script");
        var arrIframe = MyDiv.getElementsByTagName("iframe");
        for (var n = 0; n < arr.length; n++) {
          if (arr[n].src.includes("https://cdn.jwplayer.com/players/")) {
            var filename = arr[n].src
              .substring(arr[n].src.lastIndexOf("/") + 1)
              .split(".");
            if (filename && filename !== undefined) {
              const querySelector =
                'p script[src*="https://cdn.jwplayer.com/players/"]';
              const scriptTags = document.querySelectorAll(querySelector);
              scriptTags.forEach((scriptTag) => {
                const s = new XMLSerializer();
                const newScriptTag = s.serializeToString(scriptTag);
                this.appendScriptElementFromString(newScriptTag);
              });
            }
          }
        }
        for (var m = 0; m < arrIframe.length; m++) {
          if (arrIframe[m].src.includes("https://cdn.jwplayer.com/players/")) {
            var filename2 = arrIframe[m].src
              .substring(arrIframe[m].src.lastIndexOf("/") + 1)
              .split(".");
            const filenameCero = filename2[0];
            if (filename2 && filename2 !== undefined) {
              const querySelector =
                'div iframe[src*="https://cdn.jwplayer.com/players/"]';
              const iframetTags = document.querySelectorAll(querySelector);
              iframetTags.forEach((iframeTag) => {
                // console.log(iframeTag);
                // const sriptSrc = "https://cdn.jwplayer.com/players/" + filenameCero + ".html";
                // const script = document.createElement('script');
                // script.src = sriptSrc;
                // console.log(script);
                // const s = new XMLSerializer();
                // const newScriptTag = s.serializeToString(script)
                // this.appendScriptElementFromStringIframe(newScriptTag);
                const sriptSrc =
                  "https://cdn.jwplayer.com/players/" + filenameCero + ".js";
                const script = document.createElement("script");
                script.src = sriptSrc;
                // Replace the script tag with the container div
                iframeTag.parentNode.replaceWith(script);
                var arr = MyDiv.getElementsByTagName("script");
                for (var n = 0; n < arr.length; n++) {
                  if (
                    arr[n].src.includes("https://cdn.jwplayer.com/players/")
                  ) {
                    var filename = arr[n].src
                      .substring(arr[n].src.lastIndexOf("/") + 1)
                      .split(".");
                    if (filename && filename !== undefined) {
                      const querySelector =
                        'script[src*="https://cdn.jwplayer.com/players/"]';
                      const scriptTags =
                        document.querySelectorAll(querySelector);
                      scriptTags.forEach((scriptTag) => {
                        const s = new XMLSerializer();
                        const newScriptTag = s.serializeToString(scriptTag);
                        this.appendScriptElementFromString(newScriptTag);
                      });
                    }
                  }
                }
              });
            }
          }
        }
      }
    } else {
      $("#content-rendered").html("");
    }
  }

  appendScriptElementFromString(scriptString) {
    const scriptTag = this.createElementFromHTMLString(scriptString);
    scriptTag.defer = true;
    const scriptSrc = scriptTag.src;
    //Get Id of script from src
    const scriptId = this.getScriptId(scriptSrc);

    const scriptDom = $(`script[src*="${scriptSrc}"]`);
    var divJW = document.createElement("div");
    divJW.id = `botr_${scriptId}_div`;
    scriptDom.replaceWith(divJW);

    var scriptJW = document.createElement("script");
    scriptJW.src = scriptSrc;
    scriptJW.defer = true;
    document.body.appendChild(scriptJW);
  }

  appendTiktokScript(url, isRemove = false) {
    if (isRemove) {
      var tkJs = $(`script[src*="${url}"]`);
      tkJs.remove();
    }
    const scriptTtkTag = this.createElementFromHTMLString(
      `<script async src="${url}"></script>`
    );
    scriptTtkTag.defer = true;
    const scriptTtkSrc = scriptTtkTag.src;
    const scriptTtkDom = $(`script[src*="${scriptTtkSrc}"]`);
    var divTtk = document.createElement("div");
    scriptTtkDom.replaceWith(divTtk);
    var scriptTtk = document.createElement("script");
    scriptTtk.src = scriptTtkSrc;
    scriptTtk.defer = true;
    document.body.appendChild(scriptTtk);
  }

  getScriptId(srcString) {
    const scriptId = srcString
      .substring(srcString.indexOf("rs/") + 3, srcString.lastIndexOf(".js"))
      .replaceAll(/-/gi, "_");

    return scriptId;
  }

  createElementFromHTMLString(htmlString) {
    var div = document.createElement("div");
    div.innerHTML = htmlString.trim();
    // Change this to div.childNodes to support multiple top-level nodes.
    return div.firstChild;
  }

  createScriptFromUrl(url) {
    return `<script src="${url}" defer></script>`;
  }

  createDivElementFromId(scriptId) {
    var divJW = document.createElement("div");
    divJW.id = `botr_${scriptId}_div`;
    return divJW;
  }

  LoadTags = async (Tags) => {
    let shortedTags = Tags.slice(0, 4);
    const content2 = this.renderTags(shortedTags);
    const content3 = this.renderTags(Tags);
    this.setState({
      content2: content2,
      content3: content3,
    });
  };

  renderTags = (tags) => {
    // console.log(tags)
    var mainCategory = this.state.category;
    const Tags = tags.map((subMenu, j) => {
      // var url = subMenu.url;
      // var pathname = url.slice(29);
      switch (mainCategory) {
        case "queer":
          return (
            <>
              <li className="nav-item queer-item">
                <Link
                  data-toggle="pill"
                  to={`#`}
                  className={"nav-link pink-color white-background queerPills"}
                  id={`tag-${j}`}
                  title={subMenu}
                >
                  {subMenu}
                </Link>
              </li>
            </>
          );
        case "afrolatino":
          return (
            <>
              <li className="nav-item queer-item">
                <Link
                  data-toggle="pill"
                  to={`#`}
                  className={"nav-link white roootsPills"}
                  id={`tag-${j}`}
                  title={subMenu}
                >
                  {subMenu}
                </Link>
              </li>
            </>
          );
        default:
          break;
      }
      // return (
      //   <li className="nav-item queer-item" style={{paddingBottom: '10px'}}>
      //     <Link data-toggle="pill" to={`#`} className="nav-link white queerPills" title={subMenu} >
      //       {subMenu}
      //     </Link>
      //   </li>
      // );
    });

    // console.log(subMenusContent)
    return Tags;
  };

  randomColor = () => {
    const colors = ["pink", "blue", "orange", "yellow", "green", "red"];
    const randomIndex = Math.floor(Math.random() * colors.length);
    // console.log(randomIndex);
    // console.log(colors[randomIndex]);
    this.setState({
      color: colors[randomIndex],
    });
  };

  returnRandomColor = () => {
    const colors = ["pink", "blue", "orange", "yellow", "green", "red"];
    const randomIndex = Math.floor(Math.random() * colors.length);
    // console.log(randomIndex);
    // console.log(colors[randomIndex]);
    return colors[randomIndex];
  };

  appendLotameScript() {
    const scriptLotameTag = this.createElementFromHTMLString(this.lotameLoad());
    scriptLotameTag.defer = true;
    var div = document.createElement("div");
    div.innerHTML = scriptLotameTag;
    $("#lotameTag").append(scriptLotameTag);
  }

  lotameLoad() {
    let path_name = window.location.pathname;
    let url = window.location.href;
    let article_tags = [];
    let article_cats = [];
    let int = [
      `author: ${this.state.articleAuthor}`,
      `main category: ${this.state.mainPostCategorySlug}`,
    ];
    this.state.articleTags.forEach((a) => {
      article_tags.push(a);
    });
    this.state.subCategories.forEach((s) => {
      article_cats.push(s);
    });
    this.state.subCategories.forEach((s) => {
      int.push(s);
    });
    // console.log(int)
    let data = {
      behaviors: {
        int: int,
        med: [`article category : ${this.state.mainPostCategorySlug}`],
      },
      ruleBuilder: {
        article_tags: article_tags,
        article_cats: article_cats,
        article_title: [`${this.state.articleTitle}`],
        article_author: [`${this.state.articleAuthor}`],
      },
    };
    let dataString = JSON.stringify(data);
    const lotameFunction = `
          <script type="text/javascript">
            window.lotame_16314.cmd.push(function() {
              window.lotame_16314.page(${dataString});
            });
          </script>
        `;
    return lotameFunction;
  }

  componentDidMount() {
    if (!flagApi) {
      initializeSas();
      this.setState({
        mainPostCategory: this.props.category,
        mainPostCategorySlug: this.props.category,
      });
      this.loadPosts();
      window.scrollTo(0, 0);
      flagApi = true;
    }
  }

  componentWillUnmount() {
    flagApi = false;
  }

  loadPosts = async () => {
    const categories = ["culture", "lgbtq", "latina", "afrolatino"];
    var category = this.state.category;
    await axios
      .get(
        `/get-post-by-slug2?slug=${
          this.props.params.slug
        }&timestamp=${new Date().getTime()}`,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json;charset=UTF-8",
          },
        }
      )
      .then(async (res) => {
        let post_cat_slug = res.data.data.ads_cat.cat_slug;
        let post_cat_name = res.data.data.ads_cat.cat_name;
        let subcategorias = [];
        res.data.data.post_category.forEach((e) => {
          subcategorias.push(e.cat_slug);
        });
        if (!post_cat_slug) {
          post_cat_slug = res.data.data.post_category[0].cat_slug;
          post_cat_name = res.data.data.post_category[0].cat_name;
        }
        const pageId = getPageId("queer");
        this.setState({
          Post: res.data.data,
          mainPostId: res.data.data.ID,
          mainPostCategory: post_cat_name,
          mainPostCategorySlug: post_cat_slug,
          articleTags: res.data.data.post_tags,
          subCategories: subcategorias,
          articleTitle: res.data.data.post_title,
          articleAuthor: res.data.data.author_name,
          PageId: pageId,
        });
        this.StructuredDataBreadcrumbList(res.data.data);
        this.loadPosts2(this.state.mainPostCategory);
        this.loadSeries();
        setTimeout(function () {
          self.afterFirstPost();
        }, 1000);
        setTimeout(function () {
          self.do_load();
        }, 1000);
        const foundTw =
          res.data.data.post_content?.indexOf("twitter-tweet") !== -1;
        const foundIg =
          res.data.data.post_content?.indexOf("instagram-media") !== -1;
        let hasTriggeredIg;
        let hasTriggeredTw;
        if (foundIg && !hasTriggeredIg) {
          this.appendScriptElementFromString(this.state.igScript);
          setTimeout(function () {
            window.instgrm.Embeds.process();
          }, 500);
          hasTriggeredIg = true;
        }
        if (foundTw && !hasTriggeredTw) {
          this.appendScriptElementFromString(this.state.twScript);
          setTimeout(function () {
            window.twttr.widgets.load();
          }, 500);
          hasTriggeredTw = true;
        }

        const categoryPrefix1 = "latv-";
        const categoryPrefix2 = "latv ";
        const extraCategories = ["queer", "roots"];
        const tagCategories = categories.concat(extraCategories);
        const categoriesWithPrefix1 = tagCategories.map(
          (category) => `${categoryPrefix1}${category}`
        );
        const categoriesWithPrefix2 = tagCategories.map(
          (category) => `${categoryPrefix2}${category}`
        );
        const categoriesWithPrefix = categoriesWithPrefix1.concat(
          categoriesWithPrefix2
        );
        var videoTag = null;
        res.data.data.post_tags.map((tag) => {
          const iDx = categoriesWithPrefix.indexOf(tag);
          if (iDx > -1) {
            videoTag = tag
              .replace("queer", "lgbtq")
              .replace("roots", "afrolatino")
              .replaceAll(" ", "-");
          }
          return null;
        });

        if (videoTag) {
          var apipath = `/get-pilar-playlist-by-slug?slug=${videoTag}`;
          await axios
            .get(`${apipath}`, {
              headers: {
                Accept: "application/json",
                "Content-Type": "application/json;charset=UTF-8",
              },
            })
            .then((resTrending) => {
              if (!resTrending.data.data) {
                return;
              }
              const scriptTag = this.createElementFromHTMLString(
                resTrending.data.data.post_content
              );
              scriptTag.defer = true;
              const scriptSrc = scriptTag.src;
              //Get Id of script from src
              const scriptId = this.getScriptId(scriptSrc);
              this.setState({
                // TrendingVideoId: scriptId,
                // TrendingVideoSrc: scriptSrc,
                Loading: false,
              });
            })
            .catch((error) => {
              console.log(error);
              this.setState({ Loading: false });
            });
        } else {
          this.setState({ Loading: false });
        }
        this.LoadTags(res.data.data.post_tags);
        this.randomColor();
        this.loadLatestPosts(res.data.data.ID);
        this.appendLotameScript();
        const self = this;
        setTimeout(function () {
          const content = getAdFunction(pageId);
          var div = document.createElement("span");
          div.innerHTML = content;

          $("#ads-rendered").html("");
          $("#ads-rendered").append(content);
        }, 100);
        setTimeout(function () {
          const content = renderAd(119314, pageId);
          var div = document.createElement("span");
          div.innerHTML = content;
          $("#bannerAd_sas_119314_11111").html("");
          $("#bannerAd_sas_119314_11111").append(content);
        }, 100);
        setTimeout(function () {
          const content = renderAd(119620, pageId);
          var div = document.createElement("span");
          div.innerHTML = content;
          $("#bannerAd_sas_119620_11111").html("");
          $("#bannerAd_sas_119620_11111").append(content);
        }, 100);
        setTimeout(function () {
          const content = renderAd(119313, pageId);
          var div = document.createElement("span");
          div.innerHTML = content;
          $("#bannerAd_sas_119313_11111").html("");
          $("#bannerAd_sas_119313_11111").append(content);
        }, 100);
        setTimeout(function () {
          const content = renderAd(119311, pageId);
          var div = document.createElement("span");
          div.innerHTML = content;
          $("#bannerAd_sas_119311_11111").html("");
          $("#bannerAd_sas_119311_11111").append(content);
        }, 100);
        setTimeout(function () {
          const content = renderAd(119312, pageId);
          var div = document.createElement("span");
          div.innerHTML = content;
          $("#bannerAd_sas_119312_11111").html("");
          $("#bannerAd_sas_119312_11111").append(content);
        }, 100);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  loadSeries = async () => {
    this.setState({ LoadingSeries: true });
    const category = this.state.mainPostCategory;
    await axios
      .get(`/get-special-pages?page_type=special&category=${category}`, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json;charset=UTF-8",
        },
      })
      .then((res) => {
        if (res.data === false) {
          this.setState({ hasSpecials: false });
        } else {
          this.setState({ hasSpecials: true });
        }
        this.setState({ LoadingSeries: false });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  loadPosts2 = async (category) => {
    // console.log(category);
    await axios
      .get(
        `/get-post-by-category?page_no=1&category=${category}&timestamp=${new Date().getTime()}`,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json;charset=UTF-8",
          },
        }
      )
      .then((res) => {
        // const self = this;
        // setTimeout(function () {
        //   const content = self.renderTopAd(119280);
        //   var div = document.createElement("span");
        //   div.innerHTML = content;
        //   $("#bannerAd_sas_119280_11111").html("");
        //   $("#bannerAd_sas_119280_11111").append(content);
        // }, 100);
        this.setState({
          Posts: res.data.data,
          Loading: false,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  loadLatestPosts = async (PostID) => {
    await axios
      .get(
        `/get-releted-post-from-contextly/?PostId=${PostID}&timestamp=${new Date().getTime()}`,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json;charset=UTF-8",
          },
        }
      )
      .then((res) => {
        this.setState({ LatestPosts: res.data.data });
        this.setState({ LoadingAll: false });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  appendAdsToPost(post) {
    const pageId = this.state.PageId;
    const siteId = this.state.SiteId;

    const top_content_formats = [];
    const bot_content_formats = ["119620"];
    const side_formats = ["119313", "119311", "119312"];

    top_content_formats.forEach((format) => {
      this.appendAd(post.randDivIdTop, format, pageId, siteId, "top");
    });
    bot_content_formats.forEach((format) => {
      this.appendAd(post.randDivIdBot, format, pageId, siteId, "bot");
    });
    side_formats.forEach((format) => {
      this.appendAd(post.randDivIdSide, format, pageId, siteId, "side");
    });
  }

  //Type can be top/bot/side
  appendAd(divId, format, pageId, siteId, type) {
    var rndId = parseInt(Math.random() * 1000000);
    rndId = `sas_${format}_${rndId}`;
    var art = document.createElement("div");
    $(art).addClass(`${type}-adverties`);
    // Create a container in the article with the SMART tag
    var smartDiv = document.createElement("div");
    smartDiv.id = rndId;
    var smartTag = document.createElement("script");
    $(smartTag).text(
      `sas.cmd.push(function() {sas.call('std', {siteId:${siteId},pageId:${pageId},formatId:${format},tagId:${rndId}});});`
    );
    art.appendChild(smartDiv);
    art.appendChild(smartTag);
    $("#" + divId).append(art);

    // currentAdsIds.push(rndId);

    var refreshContentSc = document.createElement("script");
    $(refreshContentSc).text(
      "localStorage.setItem('startDate" +
        rndId +
        "', 'start'); localStorage.setItem('temp" +
        rndId +
        "', '" +
        rndId +
        "'); localStorage.setItem('tempcount" +
        rndId +
        "', 0);   setInterval(function(){var countsAds=0; var top_of_element = $('#" +
        rndId +
        "').offset().top; var bottom_of_element = $('#" +
        rndId +
        "').offset().top + $('#" +
        rndId +
        "').outerHeight();var bottom_of_screen = $(window).scrollTop() + $(window).innerHeight();var top_of_screen = $(window).scrollTop(); if ((bottom_of_screen > top_of_element) && (top_of_screen < bottom_of_element)){ var NowDate = new Date(); var lastsyncdata=localStorage.getItem('startDate" +
        rndId +
        "'); if(lastsyncdata=='start'){ localStorage.setItem('startDate" +
        rndId +
        "', new Date());  return true;} var lastsync =new Date(localStorage.getItem('startDate" +
        rndId +
        "')); var Diffseconds = (NowDate.getTime() - lastsync.getTime()) / 1000;  var loopCount=localStorage.getItem('tempcount" +
        rndId +
        "');  var tempGlobalScrollAdsIdsGet = localStorage.getItem('temp" +
        rndId +
        "');   if(loopCount<5 && Diffseconds>30){ sas.cmd.push(function() {sas.refresh(" +
        rndId +
        ");}); localStorage.setItem('tempcount" +
        rndId +
        "', (Number(localStorage.getItem('tempcount" +
        rndId +
        "'))+1)); localStorage.setItem('startDate" +
        rndId +
        "', new Date()); }}},1000)"
    );
    $("#" + divId).append(refreshContentSc);
  }

  loadReletedPaginatioPosts = async (category) => {
    if (this.state.totalPages < this.state.pageNumber) {
      this.setState({ hasMore: false });
      return;
    }
    var customCat = this.state.mainPostCategorySlug;
    var mainPostId = this.state.mainPostId;
    var pageNumber = this.state.pageNumber;
    await axios
      .get(
        `/get-full-post-by-category/?category=${customCat}&mainPostId=${mainPostId}&page_no=${pageNumber}&timestamp=${new Date().getTime()}`,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json;charset=UTF-8",
          },
        }
      )
      .then((res) => {
        let currentPost = res.data.data;
        //Initialize div Ids
        const paginationPosts = [].concat(res.data.data);
        paginationPosts.forEach((post) => {
          post.randDivIdTop = parseInt(Math.random() * 1000000000);
          post.randDivIdBot = parseInt(Math.random() * 1000000000);
          post.randDivIdSide = parseInt(Math.random() * 1000000000);
          post.randomColor = this.returnRandomColor();
        });
        this.setState({
          ReletedPaginatioPost:
            this.state.ReletedPaginatioPost.concat(paginationPosts),
          pageNumber: pageNumber + 1,
          totalPages: res.data.pagecount,
        });
        const self = this;
        setTimeout(function () {
          self.afterFollowingPost(currentPost);
        }, 1000);
        const foundTw =
          res.data.data.post_content?.indexOf("twitter-tweet") !== -1;
        const foundIg =
          res.data.data.post_content?.indexOf("instagram-media") !== -1;
        const foundTiktok =
          res.data.data.post_content?.indexOf("tiktok-embed") !== -1;
        let hasTriggeredIg;
        let hasTriggeredTw;
        let hasTriggeredTiktok;
        if (foundIg && !hasTriggeredIg) {
          this.appendScriptElementFromString(this.state.igScript);
          setTimeout(function () {
            window.instgrm.Embeds.process();
          }, 1500);
          hasTriggeredIg = true;
        }
        // if (foundTw && !hasTriggeredTw) {
        //   this.appendTwitterScript(this.state.twScript);
        //   setTimeout(function () {
        //     window.twttr.widgets.load();
        //   }, 1500);
        //   hasTriggeredTw = true;
        // }
        // if (foundTiktok && !hasTriggeredTiktok) {
        //   console.log("hizo el append Tiktok");
        //   this.this.appendTiktokScript("https://www.tiktok.com/embed.js");
        //   hasTriggeredTiktok = true;
        // }
        this.appendAdsToPost(paginationPosts[0]);

        var GoogleAnalyticsRequest = document.createElement("script");
        $(GoogleAnalyticsRequest).text(
          "gtag('event','page_view',{'page_title':'" +
            paginationPosts[0].post_title +
            "','page_location':'https://latv.com/" +
            paginationPosts[0].post_slug +
            "','page_path':'/" +
            paginationPosts[0].post_slug +
            "'});"
        );
        $("#" + paginationPosts[0].randDivIdSide).append(
          GoogleAnalyticsRequest
        );
      })
      .catch((error) => {
        console.log(error);
      });
    this.appendTiktokScript("https://www.tiktok.com/embed.js", true);
  };

  StructuredDataBreadcrumbList = (postData) => {
    const structuredData = {
      "@context": "https://schema.org",
      "@type": "BreadcrumbList",
      itemListElement: [
        {
          "@type": "ListItem",
          position: 1,
          item: {
            "@id": "https://latv.com/",
            name: "Home",
          },
        },
        {
          "@type": "ListItem",
          position: 2,
          item: {
            "@id": `https://latv.com/queer/`,
            name: "queer",
          },
        },
        {
          "@type": "ListItem",
          position: 3,
          item: {
            "@id": `https://latv.com/queer/${postData.post_slug}/`,
            name: postData.post_title,
          },
        },
      ],
    };
    this.setState({
      breadcrumbsListScript: structuredData,
    });
  };

  render() {
    const {
      Loading,
      Post,
      Posts,
      LatestPosts,
      TrendingVideoId,
      mainPostCategorySlug,
      color,
      category,
      breadcrumbsListScript,
    } = this.state;
    const shareUrl = this.props.urls.site_url + this.props.urls.site_postfix;
    setTimeout(function () {
      $("blockquote , a").addClass(category);
    }, 500);
    return (
      <>
        <Helmet>
          {/* <script type="application/ld+json">
            {JSON.stringify(articleScript)}
          </script>
          <script type="application/ld+json">
            {JSON.stringify(listScript)}
          </script> */}
          <script type="application/ld+json">
            {JSON.stringify(breadcrumbsListScript)}
          </script>
        </Helmet>
        <section id="read-section" className="read-section">
          <div className="container queer">
            {!Loading ? (
              <>
                <div className="row">
                  <>
                    <div
                      className="col-xl-8 col-lg-8 col-md-8 col-sm-12 col-12"
                      key={Post.ID}
                    >
                      <div
                        className="main-img-box"
                        style={{
                          borderLeft: `10px solid ${color}`,
                          marginLeft: "-12px",
                          paddingLeft: "12px",
                        }}
                      >
                        <h1
                          className="super-heading"
                          data-toggle="tooltip"
                          title={Post.post_title}
                          dangerouslySetInnerHTML={{
                            __html:
                              Post.post_title !== null && Post.post_title !== ""
                                ? Post.post_title.length > 120
                                  ? Post.post_title.substr(0, 120) + `...`
                                  : Post.post_title
                                : ``,
                          }}
                        ></h1>
                        <div className="col-xl-6 col-lg-4 col-md-6 col-sm-12">
                          {/* <p><a style={{color: 'black'}}>By</a> {Post.author_name}</p> */}
                          <p className="author-link">
                            By
                            <a
                              href={`/author/${Post.author_login}/`}
                              // className={`author-name-${mainPostCategorySlug}`}
                              className={`author-name `}
                              target="_blank"
                              rel="noreferrer"
                            >
                              {" "}
                              {Post.author_name}
                            </a>
                          </p>
                        </div>
                        <div
                          className={
                            "d-none d-xl-block d-lg-block d-md-block d-sm-none  row image-container full-border-" +
                            color
                          }
                        >
                          <div style={{ padding: "0" }}>
                            <ImageResize
                              state={this.state}
                              serie={Post}
                              class="img-fluid w-100 h-auto img-sizing"
                              alter={Post.post_image.image_alt}
                            />
                          </div>
                        </div>
                        <div className="d-none d-xl-block d-lg-block d-md-block d-sm-none col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                          <ul
                            className="nav nav-pills nav-fill no-scroll"
                            id="MainPostArtTags"
                          >
                            {!this.state.Loading ? (
                              <>{this.state.content2}</>
                            ) : (
                              <></>
                            )}
                          </ul>
                        </div>
                      </div>
                      <div
                        style={{ padding: "0" }}
                        className="d-sm-block d-md-none d-lg-none d-xl-none mobile-img"
                      >
                        <ImageResize
                          state={this.state}
                          serie={Post}
                          class="img-fluid w-100 h-auto img-sizing"
                          alter={Post.post_image.image_alt}
                        />
                      </div>
                      <div className="d-sm-block d-md-none d-lg-none d-xl-none col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                        <ul
                          className="nav nav-pills nav-fill no-scroll"
                          id="MainPostArtTags"
                        >
                          {!this.state.Loading ? (
                            <>{this.state.content2}</>
                          ) : (
                            <></>
                          )}
                        </ul>
                      </div>
                      <div
                        className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12"
                        style={{ paddingLeft: "12px", paddingRight: "12px" }}
                      >
                        <div className="main-box-content" id="content-rendered">
                          <div
                            dangerouslySetInnerHTML={{
                              __html: Post.post_content,
                            }}
                          ></div>
                        </div>
                      </div>
                      <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                        <h2 className={`titleNew`}>ARTICLE TAGS</h2>
                        <ul
                          className="nav nav-pills nav-fill art-tags"
                          id="postArticleTags"
                        >
                          {!this.state.Loading ? (
                            <>{this.state.content3}</>
                          ) : (
                            <></>
                          )}
                        </ul>
                      </div>
                      {/* <div
                      className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12"
                      style={{ paddingLeft: "12px", paddingRight: "12px" }}
                    >
                      <div className="social-sharing social-container">
                        <FacebookShareButton
                          url={`${shareUrl}/${this.props.params.slug}`}
                          quote={"LATV - " + Post.post_title}
                          hashtag={
                            Post.post_category[0] &&
                            Post.post_category[0].cat_name !== null
                              ? `#LATV-${Post.post_category[0].cat_name}`
                              : `#LATV-${Post.author_name}`
                          }
                          className={"social-btn"}
                        >
                          <FacebookIcon size={32} />
                        </FacebookShareButton>
                        <TwitterShareButton
                          url={`${shareUrl}/${this.props.params.slug}`}
                          quote={"LATV -" + Post.post_title}
                          hashtag={
                            Post.post_category[0] &&
                            Post.post_category[0].cat_name !== null
                              ? `#LATV-${Post.post_category[0].cat_name}`
                              : `#LATV-${Post.author_name}`
                          }
                          className={"social-btn"}
                        >
                          <TwitterIcon size={32} />
                        </TwitterShareButton>
                        <LinkedinShareButton
                          url={`${shareUrl}/${this.props.params.slug}`}
                          quote={"LATV -" + Post.post_title}
                          hashtag={
                            Post.post_category[0] &&
                            Post.post_category[0].cat_name !== null
                              ? `#LATV-${Post.post_category[0].cat_name}`
                              : `#LATV-${Post.author_name}`
                          }
                          className={"social-btn"}
                        >
                          <LinkedinIcon size={32} />
                        </LinkedinShareButton>
                        <EmailShareButton
                          url={`${shareUrl}/${this.props.params.slug}`}
                          quote={"LATV -" + Post.post_title}
                          hashtag={
                            Post.post_category[0] &&
                            Post.post_category[0].cat_name !== null
                              ? `#LATV-${Post.post_category[0].cat_name}`
                              : `#LATV-${Post.author_name}`
                          }
                          className={"social-btn"}
                        >
                          <EmailIcon size={32} />
                        </EmailShareButton>
                      </div>
                    </div> */}
                    </div>
                    <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12">
                      <div className="side-video-box ">
                        <div className="top-adverties"></div>
                        <h2 className="titleNew">TRENDING Articles</h2>
                        <div
                          className="row"
                          style={{ paddingLeft: "12px", paddingRight: "12px" }}
                        >
                          {Posts?.slice(0, 3).map((post, index) => {
                            const {
                              ID,
                              post_category,
                              post_image,
                              post_title,
                              post_slug,
                              publish_date,
                              post_type,
                              imgs,
                              short_description,
                            } = post;
                            const postSlugVerified = post_slug.includes(
                              "https://latv.com/"
                            )
                              ? post_slug.slice(17)
                              : post_slug;
                            return (
                              <div
                                className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12"
                                style={{ marginBottom: "15px" }}
                              >
                                <div
                                  className={"square-duo full-border-" + color}
                                  style={{
                                    margin: "auto",
                                    position: "relative",
                                  }}
                                >
                                  <a
                                    href={
                                      !!post_category.find(
                                        (element) =>
                                          element.cat_name === "QUEER"
                                      )
                                        ? post_type === "watch"
                                          ? `/queer/watch/${postSlugVerified}`
                                          : `/queer/${postSlugVerified}/`
                                        : post_type === "watch"
                                        ? `/watch/${postSlugVerified}`
                                        : `/${postSlugVerified}/`
                                    }
                                  >
                                    <div>
                                      <img
                                        sizes="(max-width: 480px) 100vw, (max-width: 768px) 50vw, 33vw"
                                        srcSet={imgs
                                          .toReversed()
                                          .map((image, index) => {
                                            if (image[1] === undefined) {
                                              return [];
                                            }
                                            const Srcset = [];
                                            const Zize = image[1].toString();
                                            const link = image[0].toString();
                                            Srcset.push(
                                              link + " " + Zize + "w"
                                            );
                                            return Srcset;
                                          })}
                                        src={post_image.medium_image_url}
                                        width="1000px"
                                        height="563px"
                                        // cache
                                        className="img-fluid img-responsive w-100 img-sizing"
                                        alt="alt"
                                      />
                                      <div className="image-text-second">
                                        {/* <p>
                                      <a
                                        href="#"
                                        className={color + " slide-category"}
                                      >
                                        {post.post_category[0].cat_name}
                                      </a>
                                    </p> */}

                                        <a className="lh-initial">
                                          <h2
                                            className={
                                              "line-clamp second-post-title border-" +
                                              color
                                            }
                                            dangerouslySetInnerHTML={{
                                              __html:
                                                post_title !== null
                                                  ? post_title.length > 70
                                                    ? post_title.substr(0, 70) +
                                                      `...`
                                                    : post_title
                                                  : ``,
                                            }}
                                          ></h2>
                                        </a>
                                      </div>
                                    </div>
                                  </a>
                                </div>
                              </div>
                            );
                          })}
                        </div>
                        <h2 className="titleNew">SEARCH</h2>
                        <div>
                          <form onSubmit={() => this.handleSearch()}>
                            <div className="container queer">
                              <div className="row">
                                <div
                                  className="col-md-11"
                                  style={{ padding: "0" }}
                                >
                                  <div className="form-group">
                                    <input
                                      type="text"
                                      className="form-control gray-back no-border"
                                      onChange={this.searchType}
                                      id="s"
                                      name="s"
                                      placeholder="Type here..."
                                      autoComplete="off"
                                    />
                                  </div>
                                </div>
                                <div className="col-md-1">
                                  <button
                                    type="submit"
                                    onClick={this.handleSearch}
                                    className="search-btn"
                                  >
                                    <i className="fa fa-search"></i>
                                  </button>
                                </div>
                              </div>
                            </div>
                          </form>
                        </div>
                        <h2 className="titleNew">TRENDING VIDEO</h2>
                        <div className="side-video-img mt-4">
                          <TrendingVideo videoId={TrendingVideoId} />
                        </div>
                        <div className="side-video-img mt-4">
                          <MailchimpFormComponent
                            category={mainPostCategorySlug}
                          />
                        </div>
                        <h2 className="titleNew">SHARE THIS CONTENT</h2>
                        <div
                          className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12"
                          style={{ paddingLeft: "12px", paddingRight: "12px" }}
                        >
                          <div className="social-sharing social-container-queer">
                            <FacebookShareButton
                              url={`${shareUrl}/${this.props.params.slug}`}
                              quote={"LATV - " + Post.post_title}
                              hashtag={
                                Post.post_category[0] &&
                                Post.post_category[0].cat_name !== null
                                  ? `#LATV-${Post.post_category[0].cat_name}`
                                  : `#LATV-${Post.author_name}`
                              }
                              className={"social-btn"}
                            >
                              <FacebookIcon size={42} />
                            </FacebookShareButton>
                            <TwitterShareButton
                              url={`${shareUrl}/${this.props.params.slug}`}
                              quote={"LATV -" + Post.post_title}
                              hashtag={
                                Post.post_category[0] &&
                                Post.post_category[0].cat_name !== null
                                  ? `#LATV-${Post.post_category[0].cat_name}`
                                  : `#LATV-${Post.author_name}`
                              }
                              className={"social-btn"}
                            >
                              <TwitterIcon size={42} />
                            </TwitterShareButton>
                            <LinkedinShareButton
                              url={`${shareUrl}/${this.props.params.slug}`}
                              quote={"LATV -" + Post.post_title}
                              hashtag={
                                Post.post_category[0] &&
                                Post.post_category[0].cat_name !== null
                                  ? `#LATV-${Post.post_category[0].cat_name}`
                                  : `#LATV-${Post.author_name}`
                              }
                              className={"social-btn"}
                            >
                              <LinkedinIcon size={42} />
                            </LinkedinShareButton>
                            <EmailShareButton
                              url={`${shareUrl}/${this.props.params.slug}`}
                              quote={"LATV -" + Post.post_title}
                              hashtag={
                                Post.post_category[0] &&
                                Post.post_category[0].cat_name !== null
                                  ? `#LATV-${Post.post_category[0].cat_name}`
                                  : `#LATV-${Post.author_name}`
                              }
                              className={"social-btn"}
                            >
                              <EmailIcon size={42} />
                            </EmailShareButton>
                          </div>
                        </div>
                        {/* <InstaFeeds token={this.insta_token} limit={9} category={'network'}/> */}
                        <div>
                          <div
                            className="top-adverties"
                            style={
                              {
                                // position: "sticky",
                                // top: 0,
                              }
                            }
                          >
                            <div id="bannerAd_sas_119313_11111"></div>
                          </div>
                          <div className="top-adverties">
                            <div id="bannerAd_sas_119311_11111"></div>
                          </div>
                          <div className="top-adverties">
                            <div id="bannerAd_sas_119312_11111"></div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                      <h2 className="titleNew">
                        {LatestPosts.length >= 1 ? `RELATED ARTICLES` : ``}
                      </h2>
                      <div
                        className="row"
                        style={{ paddingLeft: "12px", paddingRight: "12px" }}
                      >
                        {LatestPosts.slice(0, 3).map((post, index) => {
                          const {
                            ID,
                            post_category,
                            post_image,
                            post_title,
                            post_slug,
                            publish_date,
                            post_type,
                            imgs,
                            short_description,
                          } = post;
                          const postSlugVerified = post_slug.includes(
                            "https://latv.com/"
                          )
                            ? post_slug.slice(17)
                            : post_slug;
                          return (
                            <div
                              className="col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12"
                              style={{ marginBottom: "15px" }}
                            >
                              <div
                                className={"square-duo full-border-" + color}
                                style={{ margin: "auto", position: "relative" }}
                              >
                                <a
                                  href={
                                    !!post_category.find(
                                      (element) => element.cat_name === "QUEER"
                                    )
                                      ? post_type === "watch"
                                        ? `/queer/watch/${postSlugVerified}`
                                        : `/queer/${postSlugVerified}/`
                                      : post_type === "watch"
                                      ? `/watch/${postSlugVerified}`
                                      : `/${postSlugVerified}/`
                                  }
                                >
                                  <div>
                                    <img
                                      sizes="(max-width: 480px) 100vw, (max-width: 768px) 50vw, 33vw"
                                      srcSet={imgs
                                        .toReversed()
                                        .map((image, index) => {
                                          if (image[1] === undefined) {
                                            return [];
                                          }
                                          const Srcset = [];
                                          const Zize = image[1].toString();
                                          const link = image[0].toString();
                                          Srcset.push(link + " " + Zize + "w");
                                          return Srcset;
                                        })}
                                      src={post_image.medium_image_url}
                                      // cache
                                      width="1000px"
                                      height="563px"
                                      className="img-fluid img-responsive w-100 img-sizing"
                                      alt="alt"
                                    />
                                    <div className="image-text-second">
                                      {/* <p>
                                      <a
                                        href="#"
                                        className={color + " slide-category"}
                                      >
                                        {post.post_category[0].cat_name}
                                      </a>
                                    </p> */}

                                      <a className="lh-initial">
                                        <h2
                                          className={
                                            "line-clamp second-post-title border-" +
                                            color
                                          }
                                          dangerouslySetInnerHTML={{
                                            __html:
                                              post_title !== null
                                                ? post_title.length > 70
                                                  ? post_title.substr(0, 70) +
                                                    `...`
                                                  : post_title
                                                : ``,
                                          }}
                                        ></h2>
                                      </a>
                                    </div>
                                  </div>
                                </a>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                    <SpecialsCarousel title={"Specials"} />
                    <div
                      style={{ backgroundColor: "#b87598", height: "200px" }}
                    ></div>
                  </>
                </div>
                <InfiniteScroll
                  dataLength={this.state.ReletedPaginatioPost.length}
                  next={this.loadReletedPaginatioPosts}
                  hasMore={this.state.hasMore}
                  className="row"
                  loader={
                    <div
                      className="col-md-8"
                      style={{
                        textAlign: "center",
                        padding: "20px",
                      }}
                    >
                      <div style={{ textAlign: "center" }}>
                        <img
                          placeholder={loadingSVG}
                          src={loadingSVG}
                          className="img-fluid img-responsive  bottom-img-spinner img-sizing"
                          alt="alt"
                        />
                      </div>
                    </div>
                  }
                  endMessage={
                    <div className="col-md-12">
                      <p style={{ textAlign: "center" }}>
                        <b>Yay! You have seen it all</b>
                      </p>
                    </div>
                  }
                >
                  {this.state.ReletedPaginatioPost.map((rPost, index) => (
                    // <div className="top-adverties">
                    //   <p style={{ visibility: "hidden" }}>sas ads top</p>
                    //   <div id={rPost.randDivIdTop}> </div>
                    // </div>
                    <>
                      <div
                        className="col-xl-8 col-lg-8 col-md-8 col-sm-12 col-12"
                        style={{ marginTop: "15px" }}
                        key={rPost.ID}
                        id={rPost.ID}
                      >
                        <div
                          className="main-img-box"
                          style={{
                            borderLeft: `10px solid ${rPost.randomColor}`,
                            marginLeft: "-12px",
                            paddingLeft: "12px",
                          }}
                        >
                          <h1
                            className="super-heading"
                            data-toggle="tooltip"
                            title={rPost.post_title}
                            dangerouslySetInnerHTML={{
                              __html:
                                rPost.post_title !== null &&
                                Post.post_title !== ""
                                  ? rPost.post_title.length > 120
                                    ? rPost.post_title.substr(0, 120) + `...`
                                    : rPost.post_title
                                  : ``,
                            }}
                          ></h1>
                          <div className="col-xl-6 col-lg-4 col-md-6 col-sm-12">
                            {/* <p><a style={{color: 'black'}}>By</a> {Post.author_name}</p> */}
                            <p className="author-link">
                              By
                              <a
                                href={`/author/${rPost.author_login}/`}
                                // className={`author-name-${mainPostCategorySlug}`}
                                className={`author-name `}
                                target="_blank"
                                rel="noreferrer"
                              >
                                {" "}
                                {rPost.author_name}
                              </a>
                            </p>
                          </div>
                          <div
                            className={
                              "d-none d-xl-block d-lg-block d-md-block d-sm-none row image-container full-border-" +
                              rPost.randomColor
                            }
                          >
                            <div style={{ padding: "0" }}>
                              <ImageResize
                                state={this.state}
                                serie={rPost}
                                class="img-fluid w-100 h-auto img-sizing"
                                alter={rPost.post_image.image_alt}
                              />
                            </div>
                          </div>
                          <div className="d-none d-xl-block d-lg-block d-md-block d-sm-none col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                            <ul
                              className="nav nav-pills nav-fill no-scroll"
                              id="MainPostArtTags"
                            >
                              {!this.state.Loading ? (
                                <>
                                  {this.renderTags(rPost.post_tags.slice(0, 4))}
                                </>
                              ) : (
                                <></>
                              )}
                            </ul>
                          </div>
                        </div>
                        <div
                          style={{ padding: "0" }}
                          className="d-sm-block d-md-none d-lg-none d-xl-none mobile-img"
                        >
                          <ImageResize
                            state={this.state}
                            serie={rPost}
                            class="img-fluid w-100 h-auto img-sizing"
                            alter={rPost.post_image.image_alt}
                          />
                        </div>
                        <div className="d-sm-block d-md-none d-lg-none d-xl-none col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                          <ul
                            className="nav nav-pills nav-fill no-scroll"
                            id="MainPostArtTags"
                          >
                            {!this.state.Loading ? (
                              <>
                                {this.renderTags(rPost.post_tags.slice(0, 4))}
                              </>
                            ) : (
                              <></>
                            )}
                          </ul>
                        </div>
                        <div
                          className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12"
                          style={{ paddingLeft: "12px", paddingRight: "12px" }}
                        >
                          <div className="main-box-content">
                            <div
                              dangerouslySetInnerHTML={{
                                __html: rPost.post_content,
                              }}
                            ></div>
                          </div>
                        </div>
                        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                          <h2 className={`titleNew`}>ARTICLE TAGS</h2>
                          <ul
                            className="nav nav-pills nav-fill art-tags"
                            id="postArticleTags"
                          >
                            {!this.state.Loading ? (
                              <>{this.renderTags(rPost.post_tags)}</>
                            ) : (
                              <></>
                            )}
                          </ul>
                        </div>
                      </div>
                      <div className="col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12">
                        <div className="side-video-box ">
                          <div className="top-adverties"></div>
                          <h2 className="titleNew">TRENDING Articles</h2>
                          <div
                            className="row"
                            style={{
                              paddingLeft: "12px",
                              paddingRight: "12px",
                            }}
                          >
                            {Posts?.slice(0, 3).map((post, index) => {
                              const {
                                ID,
                                post_category,
                                post_image,
                                post_title,
                                post_slug,
                                publish_date,
                                post_type,
                                imgs,
                                short_description,
                              } = post;
                              const postSlugVerified = post_slug.includes(
                                "https://latv.com/"
                              )
                                ? post_slug.slice(17)
                                : post_slug;
                              return (
                                <div
                                  className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12"
                                  style={{ marginBottom: "15px" }}
                                >
                                  <div
                                    className={
                                      "square-duo full-border-" +
                                      rPost.randomColor
                                    }
                                    style={{
                                      margin: "auto",
                                      position: "relative",
                                    }}
                                  >
                                    <a
                                      href={
                                        !!post_category.find(
                                          (element) =>
                                            element.cat_name === "QUEER"
                                        )
                                          ? post_type === "watch"
                                            ? `..watch/${postSlugVerified}`
                                            : `../${postSlugVerified}/`
                                          : post_type === "watch"
                                          ? `../watch/${postSlugVerified}`
                                          : `../${postSlugVerified}/`
                                      }
                                    >
                                      <div>
                                        <img
                                          sizes="(max-width: 480px) 100vw, (max-width: 768px) 50vw, 33vw"
                                          srcSet={imgs
                                            .toReversed()
                                            .map((image, index) => {
                                              if (image[1] === undefined) {
                                                return [];
                                              }
                                              const Srcset = [];
                                              const Zize = image[1].toString();
                                              const link = image[0].toString();
                                              Srcset.push(
                                                link + " " + Zize + "w"
                                              );
                                              return Srcset;
                                            })}
                                          src={post_image.medium_image_url}
                                          // cache
                                          width="1000px"
                                          height="563px"
                                          className="img-fluid img-responsive w-100 img-sizing"
                                          alt="alt"
                                        />
                                        <div className="image-text-second">
                                          {/* <p>
                                      <a
                                        href="#"
                                        className={color + " slide-category"}
                                      >
                                        {post.post_category[0].cat_name}
                                      </a>
                                    </p> */}

                                          <a className="lh-initial">
                                            <h2
                                              className={
                                                "line-clamp second-post-title border-" +
                                                rPost.randomColor
                                              }
                                              dangerouslySetInnerHTML={{
                                                __html:
                                                  post_title !== null
                                                    ? post_title.length > 70
                                                      ? post_title.substr(
                                                          0,
                                                          70
                                                        ) + `...`
                                                      : post_title
                                                    : ``,
                                              }}
                                            ></h2>
                                          </a>
                                        </div>
                                      </div>
                                    </a>
                                  </div>
                                </div>
                              );
                            })}
                          </div>
                          <h2 className="titleNew">SEARCH</h2>
                          <div>
                            <form onSubmit={() => this.handleSearch()}>
                              <div className="container queer">
                                <div className="row">
                                  <div
                                    className="col-md-11"
                                    style={{ padding: "0" }}
                                  >
                                    <div className="form-group">
                                      <input
                                        type="text"
                                        className="form-control gray-back no-border"
                                        onChange={this.searchType}
                                        id="s"
                                        name="s"
                                        placeholder="Type here..."
                                        autoComplete="off"
                                      />
                                    </div>
                                  </div>
                                  <div className="col-md-1">
                                    <button
                                      type="submit"
                                      onClick={this.handleSearch}
                                      className="search-btn"
                                    >
                                      <i className="fa fa-search"></i>
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </form>
                          </div>
                          <h2 className="titleNew">TRENDING VIDEO</h2>
                          {/* <div className="side-video-img mt-4">
                          <TrendingVideo videoId={TrendingVideoId} />
                        </div> */}
                          <div className="side-video-img mt-4">
                            <MailchimpFormComponent
                              category={mainPostCategorySlug}
                            />
                          </div>
                          <h2 className="titleNew ">SHARE THIS CONTENT</h2>
                          <div
                            className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12"
                            style={{
                              paddingLeft: "12px",
                              paddingRight: "12px",
                            }}
                          >
                            <div className="social-sharing social-container-queer">
                              <FacebookShareButton
                                url={`${shareUrl}/${this.props.params.slug}`}
                                quote={"LATV - " + Post.post_title}
                                hashtag={
                                  Post.post_category[0] &&
                                  Post.post_category[0].cat_name !== null
                                    ? `#LATV-${Post.post_category[0].cat_name}`
                                    : `#LATV-${Post.author_name}`
                                }
                                className={"social-btn"}
                              >
                                <FacebookIcon size={42} />
                              </FacebookShareButton>
                              <TwitterShareButton
                                url={`${shareUrl}/${this.props.params.slug}`}
                                quote={"LATV -" + Post.post_title}
                                hashtag={
                                  Post.post_category[0] &&
                                  Post.post_category[0].cat_name !== null
                                    ? `#LATV-${Post.post_category[0].cat_name}`
                                    : `#LATV-${Post.author_name}`
                                }
                                className={"social-btn"}
                              >
                                <TwitterIcon size={42} />
                              </TwitterShareButton>
                              <LinkedinShareButton
                                url={`${shareUrl}/${this.props.params.slug}`}
                                quote={"LATV -" + Post.post_title}
                                hashtag={
                                  Post.post_category[0] &&
                                  Post.post_category[0].cat_name !== null
                                    ? `#LATV-${Post.post_category[0].cat_name}`
                                    : `#LATV-${Post.author_name}`
                                }
                                className={"social-btn"}
                              >
                                <LinkedinIcon size={42} />
                              </LinkedinShareButton>
                              <EmailShareButton
                                url={`${shareUrl}/${this.props.params.slug}`}
                                quote={"LATV -" + Post.post_title}
                                hashtag={
                                  Post.post_category[0] &&
                                  Post.post_category[0].cat_name !== null
                                    ? `#LATV-${Post.post_category[0].cat_name}`
                                    : `#LATV-${Post.author_name}`
                                }
                                className={"social-btn"}
                              >
                                <EmailIcon size={42} />
                              </EmailShareButton>
                            </div>
                          </div>
                          {/* <InstaFeeds token={this.insta_token} limit={9} category={'network'}/> */}
                          <div>
                            <div
                              className="top-adverties"
                              style={
                                {
                                  // position: "sticky",
                                  // top: 0,
                                }
                              }
                            >
                              <div id="bannerAd_sas_119313_11111"></div>
                            </div>
                            <div className="top-adverties">
                              <div id="bannerAd_sas_119311_11111"></div>
                            </div>
                            <div className="top-adverties">
                              <div id="bannerAd_sas_119312_11111"></div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                        <h2 className="titleNew">
                          {LatestPosts.length >= 1 ? `RELATED ARTICLES` : ``}
                        </h2>
                        <div
                          className="row"
                          style={{ paddingLeft: "12px", paddingRight: "12px" }}
                        >
                          {LatestPosts.slice(0, 3).map((post, index) => {
                            const {
                              ID,
                              post_category,
                              post_image,
                              post_title,
                              post_slug,
                              publish_date,
                              post_type,
                              imgs,
                              short_description,
                            } = post;
                            const postSlugVerified = post_slug.includes(
                              "https://latv.com/"
                            )
                              ? post_slug.slice(17)
                              : post_slug;
                            return (
                              <div
                                className="col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12"
                                style={{ marginBottom: "15px" }}
                              >
                                <div
                                  className={"square-duo full-border-" + color}
                                  style={{
                                    margin: "auto",
                                    position: "relative",
                                  }}
                                >
                                  <a
                                    href={
                                      !!post_category.find(
                                        (element) =>
                                          element.cat_name === "QUEER"
                                      )
                                        ? post_type === "watch"
                                          ? `/queer/watch/${postSlugVerified}`
                                          : `/queer/${postSlugVerified}/`
                                        : post_type === "watch"
                                        ? `/watch/${postSlugVerified}`
                                        : `/${postSlugVerified}/`
                                    }
                                  >
                                    <div>
                                      <img
                                        sizes="(max-width: 480px) 100vw, (max-width: 768px) 50vw, 33vw"
                                        srcSet={imgs
                                          .toReversed()
                                          .map((image, index) => {
                                            if (image[1] === undefined) {
                                              return [];
                                            }
                                            const Srcset = [];
                                            const Zize = image[1].toString();
                                            const link = image[0].toString();
                                            Srcset.push(
                                              link + " " + Zize + "w"
                                            );
                                            return Srcset;
                                          })}
                                        src={post_image.medium_image_url}
                                        // cache
                                        width="1000px"
                                        height="563px"
                                        className="img-fluid img-responsive w-100 img-sizing"
                                        alt="alt"
                                      />
                                      <div className="image-text-second">
                                        {/* <p>
                                      <a
                                        href="#"
                                        className={color + " slide-category"}
                                      >
                                        {post.post_category[0].cat_name}
                                      </a>
                                    </p> */}

                                        <a className="lh-initial">
                                          <h2
                                            className={
                                              "line-clamp second-post-title border-" +
                                              color
                                            }
                                            dangerouslySetInnerHTML={{
                                              __html:
                                                post_title !== null
                                                  ? post_title.length > 70
                                                    ? post_title.substr(0, 70) +
                                                      `...`
                                                    : post_title
                                                  : ``,
                                            }}
                                          ></h2>
                                        </a>
                                      </div>
                                    </div>
                                  </a>
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                      <SpecialsCarousel title={"Specials"} />
                      <div
                        style={{ backgroundColor: "#b87598", height: "200px" }}
                      ></div>
                    </>
                  ))}
                </InfiniteScroll>
              </>
            ) : (
              <>
                <section id="main" className="container-xl">
                  <div className="row mt-5">
                    <div className="col-md-8 col-12">
                      <div className="row p-3">
                        <div
                          className="img-fluid image-placeholder placeholder img-sizing p-0"
                          alt=""
                        ></div>
                      </div>
                    </div>
                    <div className="col-md-4 col-12 p-3">
                      <div className="row mb-3">
                        <div
                          className="img-fluid image-placeholder placeholder img-sizing p-0"
                          alt=""
                        ></div>
                      </div>
                      <div className="row mb-2">
                        <div
                          className="img-fluid image-placeholder placeholder img-sizing p-0"
                          alt=""
                        ></div>
                      </div>
                      <div className="row">
                        <div
                          className="img-fluid image-placeholder placeholder img-sizing p-0"
                          alt=""
                        ></div>
                      </div>
                    </div>
                  </div>
                  <div className="row mt-5 mb-5">
                    <div className="row col-md-2 col-12"></div>
                    <div className="row col-md-8 col-12 ">
                      <div className="row mb-3">
                        <div
                          className="img-fluid image-placeholder placeholder img-sizing p-0"
                          alt=""
                        ></div>
                      </div>
                      <div className="row mb-3">
                        <h2 className="title-placeholder placeholder"></h2>
                      </div>
                      <div className="row mb-2">
                        <div className="main-box-content text-placeholder placeholder"></div>
                      </div>
                      <div className="row">
                        <div className="main-box-content text-placeholder placeholder"></div>
                      </div>
                    </div>
                    <div className="row col-md-2 col-12"></div>
                  </div>
                </section>
              </>
            )}
          </div>
        </section>
      </>
    );
  }
}
export default withParams(QueerArticleDetail);
