export const lotameLoad = (state) => {
    // console.log("lotame load")
    // let path_name = window.location.pathname
    // let url = window.location.href;
    let article_tags = [];
    let article_cats = [];
    let int = [];
    if( state.mainCategory !== state.category ){
      article_cats = [`${state.mainCategory}`,`${state.category}`];
      int = [`author: ${state.articleTitle}`, `main category: ${state.category}`,`${state.mainCategory}`,`${state.category}`]
    }else{
      article_cats = [`${state.mainCategory}`];
      int = [`author: ${state.articleTitle}`, `main category: ${state.mainCategory}`,`${state.mainCategory}`]
    }
    state.articleTags.forEach((a) => { article_tags.push(a) } );
    // state.subCategories.forEach((s) => { article_cats.push(s) } );
    // state.subCategories.forEach((s) => { int.push(s) } );
    let data = {
      behaviors: {
        int: int,
        med: [`article category : ${state.category}`]
      },
      ruleBuilder: {
        article_tags: article_tags,
        article_cats: article_cats,
        article_title: [`${state.articleTitle}`],
        article_author: [`${state.articleAuthor}`],
      },
    }
    console.log(data)
    let dataString = JSON.stringify(data);
    const lotameFunction = 
    `
      <script type="text/javascript">
        window.lotame_16314.cmd.push(function() {
          window.lotame_16314.page(${dataString});
        });
      </script>
    `
    ;
    return lotameFunction;
  }