import playbutton from "../../images/play-button.png";
import React, { Component } from "react";
import ImageResize from "../ImageResize/ImageResize.js";
import { Link } from "react-router-dom";

const GridClipComponent = ({ post, state, excerpt }) => {
  const {
    ID,
    post_category,
    post_image,
    post_title,
    post_slug,
    publish_date,
    short_description,
  } = post;
  return (
    <>
      <div className="col" key={ID}>
        <a href={"../watch/" + post_slug + "/"}>
          <div className="fromOurSeries card">
            {post_image.full_image_url !== null ? (
              <div className="clips-thumbnail">
                <ImageResize
                  state={state}
                  serie={post}
                  class="card-img-top img-sizing"
                  alter={post.serieName ? post.serieName : "alter"}
                />
                <div>
                  <img
                    src={playbutton}
                    className="img-fluid"
                    alt={"play-button"}
                  />
                </div>
              </div>
            ) : (
              <>
                <img
                  src="https://dummyimage.com/1920x1080/ff0000/fff.png&text=Article+Image+1"
                  className="card-img-top img-sizing"
                  alt="..."
                ></img>
              </>
            )}
            <div className="card-body p-0 pt-1">
              <h5
                className="card-title"
                dangerouslySetInnerHTML={{
                  __html:
                    post_title && post_title !== null
                      ? post_title.length > 42
                        ? post_title.substr(0, 42) + `...`
                        : post_title
                      : ``,
                }}
              ></h5>
            </div>
          </div>
        </a>
      </div>
    </>
  );
};

export default GridClipComponent;
