import React, { Component } from "react";
import mediastaticpages from "../apiConnections/mediastaticpages";
import axiosstaticpages from "../apiConnections/axiosstaticpages";
import axios from "../apiConnections/axios";
import $ from "jquery";

export default class BoothPageComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      Loading: true,
      Loadingcss: true,
      title: "",
      content: "",
      renderedcontent: "",
      cssfile: "",
    };
  }
  componentDidMount() {
    this.loadBooth();
  }
  // componentWillUnmount() {
  //   window.location.reload();
  // }

  loadBooth = async () => {
    this.setState({ Loading: true });
    //var pageslug = this.props.match.params.slug;
    await axiosstaticpages
      .get(`/pages/?slug=booth&timestamp=${new Date().getTime()}`, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json;charset=UTF-8",
        },
      })
      .then((res) => {
        this.setState({
          title: res.data[0].title.rendered,
          content: res.data[0].content.rendered,
          Loading: false,
        });
        axios
          .get(
            `/get-page-by-slug/?slug=booth&timestamp=${new Date().getTime()}`,
            {
              headers: {
                Accept: "application/json",
                "Content-Type": "application/json;charset=UTF-8",
              },
            }
          )
          .then((res2) => {
            this.setState({
              cssfile: res2.data.data.cssfile,
              Loadingcss: false,
            });
          });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  render() {
    const { Loading, content, Loadingcss, cssfile } = this.state;
    setTimeout(function () {
      var div = document.createElement("span");
      div.innerHTML = content;
      if (Loadingcss !== true) {
        $(document).ready(function () {
          $(
            `<link rel="preload" href="${
              mediastaticpages.mediaUrl
            }/wp-content/uploads/visualcomposer-assets/assets-bundles/${cssfile}.source.css?timestamp=${new Date().getTime()}" as="style" onload="this.onload=null;this.rel='stylesheet'">`
          ).appendTo("head");
          $(
            `<noscript><link rel="stylesheet" href="${
              mediastaticpages.mediaUrl
            }/wp-content/uploads/visualcomposer-assets/assets-bundles/${cssfile}.source.css?timestamp=${new Date().getTime()}"></noscript>`
          ).appendTo("head");
          $(`<style>
                iframe#googleForm { min-height: 765px !important; }
               </style>`).appendTo("head");

          //   $("<link/>", {
          //     rel: "stylesheet",
          //     type: "text/css",
          //     href: `https://contentadmin.latv.com/wp-content/uploads/visualcomposer-assets/assets-bundles/${cssfile}.source.css?timestamp=${new Date().getTime()}`
          //  }).appendTo("head");
        });
      }

      if (Loading !== true) {
        if (content !== "" && content !== null && content !== "undefined") {
          $("#content-rendered").html("");
          $("#content-rendered").append(content);
          $("#app-download").remove();
        } else {
          $("#content-rendered").html("");
        }
      }
    }, 500);
    return (
      <>
        <section
          id="read-section allother"
          className="read-section mt-0 mt-md-5 "
        >
          <div className="vcv-content--blank vcv-content--boxed">
            <article
              id=""
              className="page type-page status-publish hentry entry"
            >
              <div className="entry-content" id="content-rendered">
                {/* <div id="content-rendered"> */}
                {/* dangerouslySetInnerHTML={{ __html: content }}> */}
                {/* </div> */}
              </div>
            </article>
          </div>
        </section>
      </>
    );
  }
}
