import React, { Component } from "react";
import axios from "../../apiConnections/axios";
import LoadingPost from "../../images/loading.jpg";
// import PostsPlaceholderComponent from '../Placeholders/PostsPlaceholderComponent';
// import { isMobileOnly } from "react-device-detect";

import Pagination from "react-js-pagination";
// import Img from 'react-cool-img';
// import "../../CustomCss/postsLoad.css";
import _ from "lodash";
import GridComponent2 from "../SharedComponents/GridComponent2";
export default class ContextlyLoadComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      LoadingPosts: true,
      Posts: [],
      PoststotalPages: 1,
      PostsactivePage: 1,
      //   itemscount:2,
      pagination: "no",
    };
  }
  componentDidMount() {
    // console.log(localStorage.getItem('contextlyID'))
    this.setState({
      itemscount: this.props.itemscount,
      pagination: this.props.pagination,
    });
    // console.log(this.props.itemscount);
    // console.log(this.state);
    if (
      localStorage.getItem("contextlyID") !== "undefined" &&
      localStorage.getItem("contextlyID") != null
    ) {
      this.loadPosts(localStorage.getItem("contextlyID"));
    }
  }
  //   loadPosts = async (pageNumber) => {
  //     var category = this.props.category;
  //     var apipath = `/get-post-by-category?page_no=${pageNumber}&category=${category}&timestamp=${new Date().getTime()}`;
  //     await axios.get(apipath, {
  //         headers: {'Accept': 'application/json',
  //                   'Content-Type': 'application/json;charset=UTF-8'}
  //     })
  //     .then(res => {
  //         this.setState({
  //             Posts: res.data.data,
  //             LoadingPosts: false,
  //             PoststotalPages: res.data.pagecount,
  //             PostsactivePage: pageNumber
  //         });
  //     })
  //     .catch((error) => {
  //         console.log(error)
  //     })
  // }
  loadPosts = async (PostID) => {
    // console.log(this.state);
    // /get-post-by-category/?category=food
    await axios
      .get(
        `/get-releted-post-from-contextly/?PostId=${PostID}&itemscount=${
          this.props.itemscount
        }$&timestamp=${new Date().getTime()}`,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json;charset=UTF-8",
          },
        }
      )
      .then((res) => {
        // this.setState({ LatestPosts: res.data.data });
        //   console.log("Latest1: "+JSON.stringify(res.data.data));
        // this.setState({ totalPages: res.data.SinglePagecount});
        // this.setState({ LoadingAll: false });
        // $('[data-toggle="tooltip"]').tooltip();
        this.setState({
          Posts: res.data.data,
          LoadingPosts: false,
          PoststotalPages: res.data.pagecount,
          PostsactivePage: 1,
          // itemscount: res.data.data.length
        });
        //   console.log(this.state);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  handlePostsPageChange(pageNumber) {
    this.loadPosts(pageNumber);
    this.setState({ LoadingPosts: true });
    this.setState({ PostspageNumber: pageNumber });
  }

  render() {
    let Bsize = "";
    const { LoadingPosts, Posts, pagination, itemscount } = this.state;
    return (
      <>
        {Posts && Posts !== null ? (
          <>
            <h2 className="blue-text underline">{this.props.title}</h2>
          </>
        ) : (
          ``
        )}

        <div className="row shortcode">
          <GridComponent2
            itemsCount={3}
            state={this.state}
            columns={1}
            pagination={false}
            excerpt={false}
            pageNumber="1"
            Posts={Posts}
            Loading={LoadingPosts}
            gridAds={false}
            clips={false}
            seriesSpecials={true}
          />
          {/* {!LoadingPosts && Posts && Posts !== null ? (
            Posts.slice(0, itemscount).map((post) => {
              const {
                ID,
                post_category,
                post_image,
                post_title,
                post_slug,
                publish_date,
                imgs,
              } = post;
              return (
                <div className="side-box-articles" key={ID}>
                  <a href={`${post_slug}`}>
                    <div className="row clearfix mb-3">
                      <div className="col-xl-12 col-lg-12 col-md-12 col-3 p-0">
                        <div className="side-read-img">
                          <img
                            placeholder={LoadingPost}
                            sizes={imgs.toReversed().map((image, index) => {
                              if (image[1] === undefined) {
                                return [];
                              }
                              const Size = [
                                "(-webkit-min-device-pixel-ratio: 2) 50vw",
                              ];
                              const size = image[1].toString();
                              if (index === 0) {
                                Size.push(
                                  "(min-width " + size + "px) " + size + "w"
                                );
                                Bsize = size - 1;
                              } else {
                                Size.push(
                                  "(max-width " + Bsize + "px) " + size + "w"
                                );
                                Bsize = size - 1;
                              }
                              return Size;
                            })}
                            srcset={imgs.toReversed().map((image, index) => {
                              if (image[1] === undefined) {
                                return [];
                              }
                              const Srcset = [];
                              const Zize = image[1].toString();
                              const link = image[0].toString();
                              Srcset.push(link + " " + Zize + "w");
                              return Srcset;
                            })}
                            src={post_image.full_image_url}
                            loading="lazy"
                            className="img-fluid img-responsive side-read-img-pic"
                            alt={post_image.image_alt}
                          />
                        </div>
                      </div>
                      <div className="col-xl-12 col-lg-12 col-md-12 col-9">
                        <div className="sideRead-Content">
                          <div className="row">
                            <div className="col-xl-12 col-lg-12 col-md-12 col-12 pl-1">
                              <div className="side-read-heading ml-2">
                                <span
                                  className="side-read-category"
                                  data-toggle="tooltip"
                                  title={
                                    post_category[0] &&
                                    post_category[0].cat_name !== null
                                      ? post_category[0].cat_name
                                      : ``
                                  }
                                >
                                  {post_category[0] &&
                                  post_category[0].cat_name !== null
                                    ? post_category[0].cat_name.length > 14
                                      ? post_category[0].cat_name.substr(
                                          0,
                                          14
                                        ) + `...`
                                      : post_category[0].cat_name
                                    : ``}
                                </span>{" "}
                                <span className="side-read-heading-span">
                                  {publish_date}
                                </span>
                              </div>
                              <div
                                className="side-box-content ml-2"
                                data-toggle="tooltip"
                                title={post_title}
                              >
                                {post_title !== null
                                  ? post_title.length > 70
                                    ? post_title.substr(0, 70) + `...`
                                    : post_title
                                  : ``}
                              </div>
                            </div>
                            <div className="col-xl-12 col-lg-12 col-md-12 col-0 pl-0">
                              <div className="side-box-footer">
                                <>
                                  READ MORE{" "}
                                  <i className="fa fa-arrow-right"></i>
                                </>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </a>
                </div>
              );
            })
          ) : (
            <>
              {Posts !== null ? (
                <>
                  {_.times(itemscount, (i) => (
                    <></>
                    // <PostsPlaceholderComponent key={i}/>
                  ))}
                </>
              ) : (
                ``
              )}
            </>
          )} */}
        </div>
        {Posts !== null && pagination === "yes" ? (
          <div className="center pt-2">
            <Pagination
              activePage={this.state.PostsactivePage}
              itemsCountPerPage={1}
              totalItemsCount={this.state.PoststotalPages}
              pageRangeDisplayed={5}
              onChange={this.handlePostsPageChange.bind(this)}
              itemClass="page-item"
              linkClass="page-link"
              innerClass="pagination watch-page-pagination"
              hideFirstLastPages
              prevPageText="<"
              nextPageText=">"
            />
          </div>
        ) : (
          ``
        )}
      </>
    );
  }
}
