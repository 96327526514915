import React, { Component } from "react";
import { osName } from "react-device-detect";

export default class DownloadPageComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      Loading: true,
      Loadingcss: true,
      title: "Dopwnload LATV+ App",
      content: "Redirecting to the LATV+ App",
      renderedcontent: "Redirecting to the LATV+ App",
      cssfile: "",
    };
  }
  componentDidMount() {
    this.redirectDownload();
  }
  //   componentWillUnmount() {
  //     window.location.reload();
  //   }
  redirectDownload = async () => {
    this.setState({ Loading: true });
    if (osName === "iOS") {
      window.location.replace(
        "https://apps.apple.com/us/app/latv-mobile/id1064872621"
      );
    } else if (osName === "Android") {
      window.location.replace(
        "https://play.google.com/store/apps/details?id=com.brightcove.latv&hl=en_US&gl=US"
      );
    } else {
      window.location.replace("https://watch.latination.com");
    }
  };

  render() {
    return (
      <>
        <section
          id="read-section allother"
          className="read-section mt-0 mt-md-5 "
        >
          <div className="vcv-content--blank vcv-content--boxed">
            <article
              id=""
              className="page type-page status-publish hentry entry"
            >
              <div className="entry-content" id="content-rendered"></div>
            </article>
          </div>
        </section>
      </>
    );
  }
}
