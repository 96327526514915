import $ from "jquery";
import { CreateElementFromHTML } from "./CreateElementFromHTMLString";
import { lotameLoad } from "./LotameLoad";

export const appendLotameScript = (state) => {
  const scriptLotameTag = CreateElementFromHTML(lotameLoad(state));
  scriptLotameTag.defer = true;
  var div = document.createElement("div");
  div.innerHTML = scriptLotameTag;
  console.log(scriptLotameTag);
  // $('#lotameTag').append(scriptLotameTag)
};
