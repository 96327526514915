import loadingSVG from "../../images/loadingSVG.svg";
import React, { Component, useEffect } from "react";
import ImageResize from "../ImageResize/ImageResize.js";
// import { Link } from "react-router-dom";
import GridArticleComponent from "../SharedComponents/GridArticleComponent.js";
import GridComponent from "./GridComponent.js";
import $ from "jquery";
import GridClipComponent from "./GridClipComponent.js";
import GridComponentSeriesSpecials from "./GridComponentSeriesSpecials.js";
// import loadPosts from '../../CustomHooks/LoadPosts.js';
let clips = false;

const GridComponent2 = ({
  addClass,
  itemsCount,
  state,
  columns,
  pagination,
  excerpt,
  loadType,
  pageNumber,
  mainCat,
  Posts,
  Loading,
  intervalo,
  gridAds,
  sidescroll,
  clips,
  seriesSpecials,
}) => {
  // const newState = loadPosts(pageNumber, loadType ,itemsCount, mainCat, state);
  // const Posts = newState.Posts;
  // const Loading = newState.Loading;
  // const Loading = false;
  const colLg = columns;
  const colmd = Math.ceil(columns / 2);
  const colsm = 1;
  const itemComponents = [];
  let start = 0;
  if (loadType === "read") {
    start = 1;
  }

  const appendAdsToPost = (post) => {
    if (!post.adAppended) {
      // console.log("le va a hacer append a el post",post)
      post.adAppended = true;
      const pageId = 1701581;
      const siteId = 391880;

      const bot_content_formats = ["119280"];

      bot_content_formats.forEach((format) => {
        appendAd(post.randDivIdBot, format, pageId, siteId, "bot");
      });
    }
  };

  const scrollToBottom = async () => {
    if (!sidescroll || window.screen.width < 500) {
      return;
    }
    const element = document.getElementById("art" + 2);
    await element.scrollIntoView({
      behavior: "smooth",
      block: "nearest",
      inline: "nearest",
    });
  };

  const appendAd = (divId, format, pageId, siteId, type) => {
    var rndId = parseInt(Math.random() * 1000000);
    rndId = `sas_${format}_${rndId}`;
    var art = document.createElement("div");
    // console.log(art)
    $(art).addClass(`${type}-adverties`);
    var smartDiv = document.createElement("div");
    smartDiv.id = "bannerAd_" + rndId;
    var option = "{ resetTimestamp: true }";
    var smartTag = document.createElement("script");
    var code =
      `sas.cmd.push(function() {sas.call('std', {siteId:${siteId},pageId:${pageId},formatId:${format},tagId:'bannerAd_` +
      rndId +
      "'}, " +
      option +
      ");});";
    $(smartTag).text(code);
    art.appendChild(smartDiv);
    art.append(smartTag);
    $("#" + divId).append(art);
  };

  useEffect(() => {
    setTimeout(() => {
      scrollToBottom();
    }, 5000);
  }, []);

  for (let i = 0; i < 3 * columns; i++) {
    itemComponents.push(<GridArticleComponent key={i} />);
  }
  return (
    // <div className={`"row mt-3 row-cols-lg-${columns} row-cols-${columns} row-cols-sm-${columns} row-cols-md-${columns}  g-lg-3 g-2"`} data-masonry='{"percentPosition": true }'>
    <div
      className={`row-cols-lg-${columns} row-cols-md-${colmd} row-cols-sm-1 row-cols-1 row g-lg-3 g-2 mt-3 ${addClass}`}
      data-masonry='{"percentPosition": true }'
    >
      {!Loading && Posts != null ? (
        Posts.slice(start, itemsCount).map((post, index) => {
          post.randDivIdBot = parseInt(Math.random() * 1000000000);
          if ((index + 1 + intervalo) % intervalo === 0) {
            setTimeout(() => {
              appendAdsToPost(post);
            }, 2000);
          }

          return (
            <>
              <span id={sidescroll ? "art" + index : index}>
                {!clips && !seriesSpecials && (
                  <GridComponent post={post} state={state} excerpt={excerpt} />
                )}
                {clips && (
                  <GridClipComponent
                    post={post}
                    state={state}
                    excerpt={excerpt}
                  />
                )}
                {seriesSpecials && (
                  <GridComponentSeriesSpecials
                    post={post}
                    state={state}
                    excerpt={excerpt}
                  />
                )}
              </span>

              {gridAds &&
              index !== 0 &&
              (index + 1 + intervalo) % intervalo === 0 ? (
                // <GridArticleComponent />
                <div className={"col-"}>
                  <div className="bot-adverties">
                    <p style={{ visibility: "hidden" }}>sas ads bot</p>
                    <div id={post.randDivIdBot}> </div>
                  </div>
                </div>
              ) : (
                // <div className={"col-" + numColums}>
                //   <div className="bot-adverties">
                //     <p style={{ visibility: "hidden" }}>sas ads bot</p>
                //     <div id={post.randDivIdBot}> </div>
                //   </div>
                // </div>
                <></>
              )}
            </>
          );
        })
      ) : (
        <>{itemComponents}</>
      )}
    </div>
  );
};

export default GridComponent2;
