import React from "react";
import { Helmet } from "react-helmet";
import ReactDOM from "react-dom";
import SmartBanner from "react-smartbanner";
import "react-smartbanner/dist/main.css";
// import './SmartAppBannerStyles.css';

import { isAndroid, isIOS, isMobile, isSafari } from "react-device-detect";

class SmartAppBanner extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      iosAppId: 1064872621,
      iosIconUrl: require("../../images/download-icon.png"),
      // "https://is5-ssl.mzstatic.com/image/thumb/Purple112/v4/ff/11/f1/ff11f1e7-16ca-a951-6d0c-ff922eca1c67/LATVAppIcon-0-0-1x_U007emarketing-0-0-0-7-0-0-sRGB-0-0-0-GLES2_U002c0-512MB-85-220-0-0.png/460x0w.webp",
      androidAppId: "com.brightcove.latv",
      androidIconUrl: require("../../images/download-icon.png"),
      // "https://is5-ssl.mzstatic.com/image/thumb/Purple112/v4/ff/11/f1/ff11f1e7-16ca-a951-6d0c-ff922eca1c67/LATVAppIcon-0-0-1x_U007emarketing-0-0-0-7-0-0-sRGB-0-0-0-GLES2_U002c0-512MB-85-220-0-0.png/460x0w.webp",
      price: { ios: "LATV", android: "LATV" },
      storeText: { ios: "Series here", android: "Series here" },
    };
  }
  componentDidMount() {
    // setTimeout(function () {
    //   ReactDOM.render(<SmartBanner title={'LATV+'} author={'LATV Networks, LLC'} position="top" force="ios" daysHidden={0} daysReminder={0} />, document.getElementById('top-content'));
    // },1000);
  }

  render() {
    const {
      iosAppId,
      androidAppId,
      iosIconUrl,
      androidIconUrl,
      price,
      storeText,
    } = this.state;
    //const force = (isMobile && isAndroid) ? 'android' : 'ios';
    const force =
      isMobile && isAndroid ? "android" : isMobile && isIOS ? "ios" : "";
    setTimeout(function () {
      const root = ReactDOM.createRoot(document.getElementById("top-content"));
      root.render(
        <SmartBanner
          title={"LATV+"}
          author={"Watch your favorite"}
          position="top"
          force={force}
          button="GET"
          daysHidden={0}
          daysReminder={0}
          price={price}
          storeText={storeText}
        />
      );
    }, 2000);

    return (
      <Helmet>
        <meta name="apple-itunes-app" content={`app-id=${iosAppId}`} />
        <meta name="google-play-app" content={`app-id=${androidAppId}`} />
        <link rel="apple-touch-icon" href={iosIconUrl} />
        <link rel="android-touch-icon" href={androidIconUrl} />
      </Helmet>
    );
  }
}

export default SmartAppBanner;
