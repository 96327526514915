import loadingSVG from "../../images/loadingSVG.svg";
import React, { Component } from "react";
import ImageResize from "../ImageResize/ImageResize.js";
import { Link } from "react-router-dom";

const GridComponentSeriesSpecials = ({ post, state, excerpt }) => {
  const {
    ID,
    post_category,
    post_image,
    post_title,
    post_slug,
    publish_date,
    short_description,
    post_type,
  } = post;
  const LatvLongPrefix = post_slug.includes("https://latv.com");
  const articleHref =
    post_type === "watch"
      ? `../../watch/${post_slug}`
      : LatvLongPrefix
      ? `${post_slug}`
      : `/${post_slug}/`;
  return (
    <>
      <div className="col" key={ID}>
        <div className="fromOurSeries card">
          <a href={articleHref}>
            {post_image.full_image_url !== null ? (
              <>
                <ImageResize
                  state={state}
                  serie={post}
                  class="card-img-top img-sizing"
                  alter={post.serieName ? post.serieName : "alter"}
                />
              </>
            ) : (
              <>
                <img
                  src="https://dummyimage.com/1920x1080/ff0000/fff.png&text=Article+Image+1"
                  className="card-img-top img-sizing"
                  alt="..."
                ></img>
              </>
            )}
            <div className="card-img-overlay p-0">
              <div className="row">
                <div className="col-7 text-start">
                  <p className="ps-1">
                    {post_category[0] && post_category[0].cat_name !== null
                      ? post_category[0].cat_name.length > 16
                        ? post_category[0].cat_name.substr(0, 16) + `...`
                        : post_category[0].cat_name
                      : ``}
                  </p>
                </div>
                <div className="col-5 text-end">
                  <small className="pe-1">{publish_date}</small>
                </div>
              </div>
            </div>
            <div className="card-body p-0 pt-1">
              <h5
                className="card-title"
                dangerouslySetInnerHTML={{
                  __html:
                    post_title && post_title !== null
                      ? post_title.length > 42
                        ? post_title.substr(0, 42) + `...`
                        : post_title
                      : ``,
                }}
              ></h5>
              <p className="card-text text-body-secondary lh-1 mb-0 line-clamp">
                {short_description && excerpt && short_description !== null
                  ? short_description
                  : ``}
              </p>
              <p className="card-text text-body-secondary lh-1 mb-0 line-clamp">
                {post.excerpt && excerpt && post.excerpt !== null
                  ? post.excerpt
                  : ``}
              </p>
              <a href={articleHref}>
                <div className="main-box-footer">
                  READ MORE{" "}
                  <i className="fa fa-arrow-right d-none d-md-inline"></i>
                </div>
              </a>
            </div>
          </a>
        </div>
      </div>
    </>
  );
};

export default GridComponentSeriesSpecials;
