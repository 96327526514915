import React, { Component } from "react";
import axios from "../../apiConnections/axios";
import $ from "jquery";
import { useParams } from "react-router-dom";
// import Pagination from "react-js-pagination";
import GridComponent from "../SharedComponents/GridComponent.js";
import GridArticleComponent from "../SharedComponents/GridArticleComponent.js";
import GridComponent2 from "../SharedComponents/GridComponent2.js";
import Pagination from "react-js-pagination";
import loadingSVG from "../../images/loadingSVG.svg";
import InfiniteScroll from "react-infinite-scroll-component";

let flagApi = false;

function withParams(Component) {
  return (props) => <Component {...props} params={useParams()} />;
}

class SearchPostsComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      Loading: true,
      Posts: [],
      pageNumber: 1,
      searchSlug: "",
      status: "start",
      activePage: 1,
      totalPages: 1,
      mainPostCategory: "search",
      subCategories: [],
      articleTags: [],
      articleAuthor: "",
      articleTitle: "",
      search: this.props.params.slug,
      hasMore: true,
    };
  }
  componentDidMount() {
    if (!flagApi) {
      this.loadPosts(1);
      window.scrollTo(0, 0);
      $(".tooltip").hide();
      flagApi = true;
    }
  }
  componentDidUpdate(prevProps) {}
  componentWillUnmount() {
    // window.location.reload();
  }

  loadPosts = async (pageNumber) => {
    if (this.state.totalPages < this.state.pageNumber) {
      this.setState({ hasMore: false });
      return;
    }
    var customPageNum = this.state.pageNumber;
    // this.setState({ Loading: true });
    var search = this.state.search;
    var apipath;
    if (typeof this.props.category === "undefined") {
      apipath = `/search?page_no=${customPageNum}&q=${search}&timestamp=${new Date().getTime()}`;
      await axios
        .get(`${apipath}`, {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json;charset=UTF-8",
          },
        })
        .then((res) => {
          const paginationPosts = [].concat(res.data.data);
          this.setState({
            totalPages: res.data.pagecount,
            activePage: customPageNum,
            pageNumber: customPageNum + 1,
            Posts: this.state.Posts.concat(paginationPosts),
            Loading: false,
            searchSlug: search,
          });
          this.appendLotameScript();
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      apipath = `/search?page_no=${pageNumber}&q=${search}&category=queer&timestamp=${new Date().getTime()}`;
      await axios
        .get(`${apipath}`, {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json;charset=UTF-8",
          },
        })
        .then((res) => {
          const paginationPosts = [].concat(res.data.data);
          this.setState({
            totalPages: res.data.pagecount,
            activePage: customPageNum,
            pageNumber: customPageNum + 1,
            Posts: this.state.Posts.concat(paginationPosts),
            Loading: false,
            searchSlug: search,
          });
          this.appendLotameScript();
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  //   handlePageChange(pageNumber) {
  //     this.setState({ Loading: true, pageNumber: pageNumber  });
  //     this.loadPosts(pageNumber);
  // }

  /* Lotame Stuff*/
  createElementFromHTMLString(htmlString) {
    var div = document.createElement("div");
    div.innerHTML = htmlString.trim();
    // Change this to div.childNodes to support multiple top-level nodes.
    return div.firstChild;
  }

  appendLotameScript() {
    const scriptLotameTag = this.createElementFromHTMLString(this.lotameLoad());

    scriptLotameTag.defer = true;

    var div = document.createElement("div");
    div.innerHTML = scriptLotameTag;
    $("#lotameTag").append(scriptLotameTag);
    // document.getElementById('lotameTag').appendChild(scriptLotameTag);
    // document.body.appendChild(div);
  }

  lotameLoad() {
    let path_name = window.location.pathname;
    let url = window.location.href;
    let article_tags = [];
    let article_cats = [];
    this.state.articleTags.forEach((a) => {
      article_tags.push(a);
    });
    this.state.subCategories.forEach((s) => {
      article_cats.push(s);
    });
    let data = {
      behaviors: {
        int: [],
        med: [`article category : ${this.state.mainPostCategory}`],
      },
      ruleBuilder: {
        article_tags: article_tags,
        article_cats: article_cats,
        article_title: [`${this.state.articleTitle}`],
        article_author: [`${this.state.articleAuthor}`],
      },
    };
    let dataString = JSON.stringify(data);
    const lotameFunction = `
      <script type="text/javascript">
        window.lotame_16314.cmd.push(function() {
          window.lotame_16314.page(${dataString});
        });
      </script>
    `;
    return lotameFunction;
  }
  /* Lotame Stuff*/

  // handlePageChange(pageNumber) {
  //     this.loadPosts(pageNumber);
  //     this.setState({ Loading: true });
  //     this.setState({ pageNumber: pageNumber });
  //     window.scrollTo(0, 0);

  // }

  render() {
    let Bsize = "";
    const { Loading, Posts, searchSlug, search, hasMore } = this.state;

    return (
      <>
        <section id="read-section" className="read-section">
          <div className="container">
            <div className="row">
              <div className="col-xl-12 col-lg-12 col-md-12 col-12 mb-1">
                <h1
                  className="super-heading category-page-heading mb-4"
                  data-toggle="tooltip"
                  title={search}
                >
                  Search Results :{" "}
                  {search !== null && search !== ""
                    ? search.length > 30
                      ? search.substr(0, 30) + `...`
                      : search
                    : ``}
                </h1>
                {/* <h4 className="side-heading">{`Category : ${searchSlug}`}</h4> */}
              </div>

              {/* <GridComponent2 numPosts={9} state={this.state} columns={4} pagination={false} excerpt={true} loadType='home' pageNumber="1" Posts={Posts} Loading={this.state.Loading}/>

                          <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 center">
                          {(Posts!==null) ? (
                            <>
                            <Pagination
                                            activePage={this.state.activePage}
                                            itemsCountPerPage={1}
                                            totalItemsCount={this.state.totalPages}
                                            pageRangeDisplayed={5}
                                            onChange={this.handlePageChange.bind(this)}
                                            itemClass="page-item"
                                            linkClass="page-link"
                                            innerClass="pagination pagStyle mb-4"
                                            hideFirstLastPages
                                            prevPageText="<"
                                            nextPageText=">"
                                        />
                                        </>
                          ) : (``)}
                            </div> */}
              {Posts.length > 1 && (
                <InfiniteScroll
                  dataLength={Posts.length}
                  next={this.loadPosts}
                  hasMore={hasMore}
                  className="row"
                  loader={
                    <div
                      className="col-md-12"
                      style={{ textAlign: "center", padding: "20px" }}
                    >
                      <img
                        placeholder={loadingSVG}
                        src={loadingSVG}
                        className="img-fluid img-responsive  bottom-img-spinner img-sizing"
                        alt="alt"
                      />
                    </div>
                  }
                  endMessage={
                    <div className="col-md-12">
                      <p style={{ textAlign: "center" }}>
                        <b>Yay! You have seen it all</b>
                      </p>
                    </div>
                  }
                >
                  <GridComponent2
                    numPosts={9}
                    state={this.state}
                    columns={4}
                    pagination={false}
                    excerpt={true}
                    pageNumber="1"
                    Posts={Posts}
                    Loading={this.state.Loading}
                    gridAds={true}
                    intervalo={4}
                  />
                  {/* {Posts.map((post, index) => {
                        return (
                          
                          // <div className="col-xl-4 col-lg-4 col-md-4 col-12 mb-4" key={post.ID}>
                          //     <GridComponent post={post} state={this.state}/>
                          // </div> 
                      )
                      } */}
                </InfiniteScroll>
              )}
            </div>
            {/* <Sas97588Component/> */}
          </div>
        </section>
        <section id="ads-section" className="ads-section mt-0 mt-md-5 d-none">
          <div className="container-fluid" id="ads-rendered">
            {/* dangerouslySetInnerHTML={{ __html: content }}> */}
          </div>
        </section>
      </>
    );
  }
}
export default withParams(SearchPostsComponent);
