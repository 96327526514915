import React, { useState, useEffect } from "react";
import $ from "jquery";
import "../../NewCss/lgbtPlaceholder.css";
import { getPageId } from "../Ads/pagesId/smartPageId.js";
import { getAdFunction } from "../Ads/AdsFunctions/getAdFunction.js";
import { initializeSas } from "../Ads/AdsFunctions/initilizeSas.js";
const QueerSasSetup = () => {
  const [sasSetup, setSasSetup] = useState(false);
  const [sasSetupContent, setSasSetupContent] = useState("");

  useEffect(() => {
    initializeSas();
    // const getAdFunction = (pageId) => {
    //   if (sasSetup) {
    //     return;
    //   }
    //   const adFunction = `
    //     <script>
    //       var sas = sas || {};
    //       sas.cmd = sas.cmd || [];
    //       sas.cmd.push(function() {
    //           sas.setup({ networkid: 3928, domain: "https://www9.smartadserver.com", async: true });
    //       });
    //       sas.cmd.push(function() {
    //           sas.call("onecall", {
    //               siteId: 391880,
    //               pageId: ${pageId},
    //               formats: [
    //                 { id: 119311 }
    //                 ,{ id: 119312 }
    //                 ,{ id: 119313 }
    //                 ,{ id: 119314 }
    //                 ,{ id: 119315 }
    //                 ,{ id: 119316 }
    //                 ,{ id: 119619 }
    //                 ,{ id: 119620 }
    //                 ,{ id: 119621 }
    //                 ,{ id: 119280 }
    //               ],
    //               target: ''
    //           });
    //       });
    //     </script>

    //   `;
    //   setSasSetup(true);
    //   return adFunction;
    // };
    const pageId = getPageId("queer");
    const content = getAdFunction(pageId);
    setSasSetupContent(content);

    getAdFunction();
  }, [sasSetup]);

  useEffect(() => {
    if (sasSetupContent === "") {
      return;
    }
    var div = document.createElement("span");
    div.innerHTML = sasSetupContent;
    $("#ads-rendered").html("");
    $("#ads-rendered").append(sasSetupContent);
  }, [sasSetupContent]);

  return (
    <>
      <div>
        <section id="ads-section" className="ads-section mt-0 mt-md-5 d-none">
          <div className="container-fluid" id="ads-rendered"></div>
        </section>
      </div>
    </>
  );
};

export default QueerSasSetup;
