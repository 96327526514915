import React, { Component } from "react";
import axios from "../../apiConnections/axios";
import appStore from "../../images/ios_download.png";
import googlePlay from "../../images/play_download.png";
import $ from "jquery";
let flagApi = false;

class OriginalSeries extends Component {
  constructor(props) {
    super(props);
    this.state = {
      Loading: true,
    };
  }

  // CAROUSEL
  CarruselBehavior() {
    function Carousel() {
      let items = document.querySelectorAll(
        "#seriesCarousel.carousel .carousel-item"
      );
      items.forEach((el) => {
        const minPerSlide = 6;
        let next = el.nextElementSibling;
        for (var i = 1; i < minPerSlide; i++) {
          if (!next) {
            // wrap carousel by using first child
            next = items[0];
          }
          let cloneChild = next.cloneNode(true);
          el.appendChild(cloneChild.children[0]);
          next = next.nextElementSibling;
        }
      });
    }

    function checkCarouselExistence() {
      if ($("#seriesCarousel.carousel .carousel-item").length > 0) {
        Carousel();
      } else {
        setTimeout(checkCarouselExistence, 100);
      }
    }

    checkCarouselExistence();
  }

  componentDidMount = () => {
    if (!flagApi) {
      this.loadPosts();
      flagApi = true;
      this.CarruselBehavior();
    }
  };

  componentWillUnmount() {
    flagApi = false;
  }

  loadPosts = async () => {
    this.setState({ Loading: true });
    await axios
      .get(`/get-footer-slide-post?timestamp=${new Date().getTime()}`, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json;charset=UTF-8",
        },
      })
      .then((res) => {
        this.setState({ Posts: res.data.data, Loading: false });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  render() {
    const { Loading, Posts } = this.state;
    return (
      <>
        <div className="row mt-3">
          <div className="col-12">
            <h2>LATV ORIGINAL SERIES</h2>
            <hr className="latvNetwork"></hr>
          </div>
        </div>
        <div className="row mx-auto my-auto justify-content-center mt-3">
          <div
            id="seriesCarousel"
            className="carousel carousel-dark slide"
            data-bs-ride="carousel"
          >
            <div className="carousel-inner" role="listbox">
              <>
                {!Loading ? (
                  Posts.slice(0, 1).map((post, i) => {
                    return (
                      <div className="carousel-item active " key={i}>
                        <div className="col-lg-3 col-md-4 col-12 p-md-1 p-3">
                          <div className="card">
                            <a href={post.image_link_to}>
                              <img
                                className="img-fluid w-100 card-img-top img-sizing"
                                src={post.post_image.medium_image_url}
                                alt={"LATV" + i}
                                width="1000px"
                                height="563px"
                              />
                              <div className="card-body">
                                <h6 className="card-title">{post.serieName}</h6>
                              </div>
                            </a>
                          </div>
                        </div>
                      </div>
                    );
                  })
                ) : (
                  <></>
                )}
                {!Loading ? (
                  Posts.slice(1, Posts.length).map((post, i) => {
                    return (
                      <div className="carousel-item " key={i}>
                        <div className="col-lg-3 col-md-4 col-12 p-md-1 p-3">
                          <div className="card">
                            <a href={post.image_link_to}>
                              <img
                                className="img-fluid w-100 card-img-top img-sizing"
                                src={post.post_image.medium_image_url}
                                alt={"LATV" + i}
                                width="1000px"
                                height="563px"
                              />
                              <div className="card-body">
                                <h6 className="card-title">{post.serieName}</h6>
                              </div>
                            </a>
                          </div>
                        </div>
                      </div>
                    );
                  })
                ) : (
                  <></>
                )}
              </>
            </div>
            <a
              className="carousel-control-prev bg-transparent w-aut"
              href="#seriesCarousel"
              role="button"
              data-bs-slide="prev"
            >
              <span
                className="carousel-control-prev-icon"
                aria-hidden="true"
              ></span>
            </a>
            <a
              className="carousel-control-next bg-transparent w-aut"
              href="#seriesCarousel"
              role="button"
              data-bs-slide="next"
            >
              <span
                className="carousel-control-next-icon"
                aria-hidden="true"
              ></span>
            </a>
          </div>
        </div>

        <div className="d-none d-sm-none d-md-block d-lg-block d-xl-block row text-center mt-2 mt-sm-5 pb-2 pb-sm-5">
          <h3 className="d-flex">
            CLICK{" "}
            <a className="watchNow d-flex" href="/download">
              WATCH NOW
            </a>
            &nbsp;TO SEE OUR FULL EPISODES
          </h3>
        </div>
        <div className="d-block d-sm-block d-md-none row text-center mt-2 mt-sm-5 pb-2 pb-sm-5">
          <h3
            style={{ textAlign: `center` }}
            className="side-heading mt-4 mb-2"
          >
            FULL EPISODES ON LATV+
          </h3>
          <div
            className="col"
            style={{ textAlign: `center`, paddingTop: `15px` }}
          >
            <div className="row" style={{ display: `block` }}>
              <a
                href="https://apps.apple.com/us/app/latv-mobile/id1064872621"
                target={"_blank"}
                rel="noreferrer"
              >
                <img
                  src={appStore}
                  className="img-fluid w-50"
                  alt="LATV iOS App"
                />
              </a>
            </div>
            <div
              className="row"
              style={{ display: `block`, paddingTop: `5px` }}
            >
              <a
                href="https://play.google.com/store/apps/details?id=com.brightcove.latv&hl=en_US"
                target={"_blank"}
                rel="noreferrer"
              >
                <img
                  src={googlePlay}
                  className="img-fluid w-50"
                  alt="LATV Android App"
                />
              </a>
            </div>
          </div>

          {!this.state.matches ? (
            <div className="row">
              <div className="col-12">
                <div className="side-adverties ">
                  <div id={this.state.randomIdTopAd}> </div>
                </div>
              </div>
            </div>
          ) : (
            <></>
          )}
        </div>
      </>
    );
  }
}
export default OriginalSeries;
