import React, { Component } from "react";
import $ from "jquery";
import ReadSectionComponent from "./ReadSectionComponent";
import OriginalSeries from "./OriginalSeries";
import VideoSectionComponent from "./VideoSectionComponent";
import FromOurSeriesComponent from "./FromOurSeriesComponent";
import { CreateElementFromHTML } from "../../CustomHooks/CreateElementFromHTMLString";
import { getAdFunction } from "../Ads/AdsFunctions/getAdFunction.js";
import { initializeSas } from "../Ads/AdsFunctions/initilizeSas.js";
import { Helmet } from "react-helmet";
let flagApi = false;

class HomeComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      Loading: true,
      Posts: [],
      activePage: 1,
      totalPages: 1,
      randomIdBottomAd: 0,
      pageId: 1743691,
      siteId: 391880,
      mainPostId: "",
      mainPostCategory: "home",
      mainPostCategorySlug: "",
      subCategories: [],
      articleTags: [],
      articleAuthor: "",
      articleTitle: "",
      matches: window.matchMedia("(min-width: 768px)").matches,
    };
  }

  componentDidMount = () => {
    if (!flagApi) {
      initializeSas();
      const items = document.querySelectorAll(
        "#seriesCarousel.carousel .carousel-item"
      );
      items.forEach((el) => {
        const minPerSlide = 4;
        let next = el.nextElementSibling;
        for (var i = 1; i < minPerSlide; i++) {
          if (!next) {
            // wrap carousel by using first child
            next = items[0];
          }
          let cloneChild = next.cloneNode(true);
          el.appendChild(cloneChild.children[0]);
          next = next.nextElementSibling;
        }
      });
      const pageId = this.state.pageId;
      const content = getAdFunction(pageId);
      var div = document.createElement("span");
      div.innerHTML = content;
      $("#ads-rendered").html("");
      $("#ads-rendered").append(content);
      this.adAdds();
      this.StructuredDataBreadcrumbList();
      flagApi = true;
    }
  };

  adAdds() {
    const self = this;
    setTimeout(function () {
      const content = self.renderAd(100945);
      var div = document.createElement("span");
      div.innerHTML = content;
      $("#bannerAd_sas_100945_11111").html("");
      $("#bannerAd_sas_100945_11111").append(content);
    }, 100);
  }

  renderAd(adId) {
    const AdFunction = `
        <script>sas.cmd.push(function() 
        {sas.call('std', {siteId:391880,pageId:1743691,formatId:${adId},tagId:'bannerAd_sas_${adId}_11111'}, { resetTimestamp: true });});</script>
        `;
    return AdFunction;
  }

  StructuredDataBreadcrumbList = () => {
    const structuredData = {
      "@context": "https://schema.org",
      "@type": "BreadcrumbList",
      itemListElement: [
        {
          "@type": "ListItem",
          position: 1,
          item: {
            "@id": "https://latv.com/",
            name: "Home",
          },
        },
      ],
    };
    this.setState({
      breadcrumbsListScript: structuredData,
    });
  };

  // getAdFunction(pageId) {
  //   if (this.state.sasSetup) {
  //     return;
  //   }
  //   this.setState({ sasSetup: true });
  //   const adFunction = `
  //         <script>
  //           var sas = sas || {};
  //           sas.cmd = sas.cmd || [];
  //           sas.cmd.push(function() {
  //               sas.setup({ networkid: 3928, domain: "https://www9.smartadserver.com", async: true });
  //           });
  //           sas.cmd.push(function() {
  //               sas.call("onecall", {
  //                   siteId: 391880,
  //                   pageId: ${pageId},
  //                   formats: [
  //                     { id: 119311 }
  //                     ,{ id: 119312 }
  //                     ,{ id: 119313 }
  //                     ,{ id: 119314 }
  //                     ,{ id: 119315 }
  //                     ,{ id: 119316 }
  //                     ,{ id: 119619 }
  //                     ,{ id: 119620 }
  //                     ,{ id: 119621 }
  //                     ,{ id: 119280 }
  //                     ,{ id: 100945 }
  //                   ],
  //                   target: ''
  //               });
  //           });
  //         </script>
  //       `;
  //   return adFunction;
  // }

  render() {
    return (
      <>
        <Helmet>
          {/* <script type="application/ld+json">
            {JSON.stringify(articleScript)}
          </script>
          <script type="application/ld+json">
            {JSON.stringify(listScript)}
          </script> */}
          <script type="application/ld+json">
            {JSON.stringify(this.state.breadcrumbsListScript)}
          </script>
        </Helmet>
        <section id="main" className="container-xl">
          {/* <div className="col-lg-12 col-md-12 col-sm-12 mt-5 d-lg-block d-md-none d-sm-none d-none image-placeholder placeholder skin">
            <div id="bannerAd_sas_100945_11111"></div>
          </div> */}
          <ReadSectionComponent />
          <OriginalSeries />
          <VideoSectionComponent />
          <FromOurSeriesComponent />
        </section>
        <section id="pre-footer" className="mt-5">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="footer-title-big">THE LATINO VOICES</div>
                <div className="footer-title-small">REDEFINING CULTURE</div>
              </div>
            </div>
          </div>
          <div className="container-fluid newsletter pb-5">
            <div className="row">
              <h3 className="text-center mt-5">JOIN OUR NEWSLETTER</h3>
            </div>
            <div className="row mt-5">
              <div className="col-lg-8 offset-lg-2 col-md-10 offset-md-1 col-sm-8 offset-sm-2 col-8 offset-2">
                <form id="subscribe-form" method="post" className="row">
                  <div className="col-lg-5 col-md-5 col-sm-12">
                    <p>
                      Sign up for our newsletter to stay up with the latest in
                      culture
                    </p>
                  </div>
                  <div className=" col-lg-4 col-md-3 col-sm-12">
                    <div className="row">
                      <input
                        id="subscribe_email"
                        name="subscribe_email"
                        type="email"
                        placeholder="username@domain.tld"
                      ></input>
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-4 col-sm-12">
                    <button type="submit" className="newsletterBtn d-flex">
                      SUBSCRIBE
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </section>
        <section id="ads-section" className="ads-section mt-0 mt-md-5 d-none">
          <div className="container-fluid" id="ads-rendered"></div>
        </section>
      </>
    );
  }
}
export default HomeComponent;
