let isSasInitialized = false;

export function initializeSas() {
  if (!isSasInitialized) {
    window.sas = window.sas || {};
    window.sas.cmd = window.sas.cmd || [];
    window.sas.cmd.push(function () {
      window.sas.setup({
        networkid: 3928,
        domain: "https://www9.smartadserver.com",
        async: true,
      });
    });
    isSasInitialized = true; // Ensure it's only called once
  }
}
