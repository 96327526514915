export function getAdFunction(pageId) {
  const adFunction = `
    <script>
      window.sas = window.sas || {};
      window.sas.cmd = window.sas.cmd || [];

      window.sas.cmd.push(function() {
        window.sas.call("onecall", {
            siteId: 391880,
            pageId: ${pageId},
            formats: [
              { id: 119311 }
              ,{ id: 119312 }
              ,{ id: 119313 }
              ,{ id: 119314 }
              ,{ id: 119315 }
              ,{ id: 119316 }
              ,{ id: 119619 }
              ,{ id: 119620 }
              ,{ id: 119621 }
              ,{ id: 119280 }
            ],
            target: ''
        });
      });
    </script>
  `;
  return adFunction;
}

// export const getAdFunction = (pageId) => {
//   const adFunction = `
//       <script>
//         var sas = sas || {};
//         sas.cmd = sas.cmd || [];

//         if (!sas.isSetupDone) {
//           sas.cmd.push(function() {
//             sas.setup({ networkid: 3928, domain: "https://www9.smartadserver.com", async: true });
//             sas.isSetupDone = true;  // Add a flag to indicate that setup has been completed
//           });
//         }

//         sas.cmd.push(function() {
//               sas.setup({ networkid: 3928, domain: "https://www9.smartadserver.com", async: true });
//           });
//         sas.cmd.push(function() {
//             sas.call("onecall", {
//                 siteId: 391880,
//                 pageId: ${pageId},
//                 formats: [
//                   { id: 119311 }
//                   ,{ id: 119312 }
//                   ,{ id: 119313 }
//                   ,{ id: 119314 }
//                   ,{ id: 119620 }
//                 ],
//                 target: ''
//             });
//         });
//       </script>
//     `;
//   return adFunction;
// };
