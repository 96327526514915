import React from 'react'

const TrendingVideo = (props) => {
    const { videoId } = props
    const div = (
        <>
        <div className="mobile-trending-video">
            <div id={`botr_${videoId}_div`}></div>
        </div>
        </>
    )

    return (
        <React.Fragment>
            {div}
        </React.Fragment>
    );
}

export default TrendingVideo;