import React, { Component, useState, useEffect, useRef } from "react";
import { Button, Modal } from "react-bootstrap";
import ReactDOM from "react-dom";
import mediastaticpages from "../../apiConnections/mediastaticpages";
import axiosstaticpages from "../../apiConnections/axiosstaticpages";
import axios from "../../apiConnections/axios";
import $ from "jquery";
import { useParams } from "react-router-dom";
import SpecialsArticlesLoadComponent from "../SeriesComponents/SpecialsArticlesLoadComponent";
import ContextlyLoadComponent from "../SeriesComponents/ContextlyLoadComponent";
import Carousel from "../Carousel/CarouselComponent";
import ArticlesLoadComponent from "../SeriesComponents/ArticlesLoadComponent";

const LivingAndReadyComponent = ({ category }) => {
  const [loading, setLoading] = useState(true);
  const [title, setTitle] = useState("");
  const [content, setContent] = useState("");
  const [MainPostCategory, setMainPostCategory] = useState("");
  const [ArticlesAuthor, setArticlesAuthor] = useState("");
  const [CssFile, setCssFile] = useState("");
  const [LoadingCss, setLoadingCss] = useState();
  const [ContentArticles, setContentArticles] = useState("");
  const [LoadingArticles, setLoadingArticles] = useState();
  const [ContentGuests, setContentGuests] = useState("");
  const [LoadingGuests, setLoadingGuests] = useState();
  const [ContentExperts, setContentExperts] = useState("");
  const [LoadingExperts, setLoadingExperts] = useState();
  const [showHide, setShowHide] = useState(false);
  const [showHideNotify, setShowHideNotify] = useState(false);
  const [showHideSchedule, setShowHideSchedule] = useState(false);
  const [cname, setCname] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [message, setMessage] = useState("");
  const [nameError, setNameError] = useState("");
  const [messageError, setMessageError] = useState("");
  const [phoneError, setPhoneError] = useState("");
  const [emailError, setEmailError] = useState("");
  const handleModalShowHideRef = useRef();

  const width = window.innerWidth;

  const loadData = async () => {
    setLoading(true);
    var pageslug = category;
    await axiosstaticpages
      .get(`/pages/?slug=${pageslug}&timestamp=${new Date().getTime()}`, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json;charset=UTF-8",
        },
      })
      .then((res) => {
        setTitle(res.data[0].title.rendered);
        setContent(res.data[0].content.rendered);
        setLoading(false);
        setMainPostCategory(pageslug);
        setArticlesAuthor(res.data[0].author);
        appendContent(res.data[0].content.rendered);
        loadCreators("guest");
        loadCreators("expert");
        axios
          .get(
            `/get-page-by-slug/?slug=${pageslug}&timestamp=${new Date().getTime()}`,
            {
              headers: {
                Accept: "application/json",
                "Content-Type": "application/json;charset=UTF-8",
              },
            }
          )
          .then((res2) => {
            setCssFile(res2.data.data.cssfile);
            setLoadingCss(false);
            createSpan(res2.data.data.cssfile);
          });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const loadCss = async () => {
    var pageslug = category;
    await axios
      .get(
        `/get-page-by-slug/?slug=${pageslug}&timestamp=${new Date().getTime()}`,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json;charset=UTF-8",
          },
        }
      )
      .then((res2) => {
        setCssFile(res2.data.data.cssfile);
        setLoadingCss(false);
        createSpan(res2.data.data.cssfile);
      });
  };

  useEffect(() => {
    if (content !== "") {
      loadArticles(category);

      $(document).on("click", ".submitBTN", () => {
        setShowHide(!showHide);
      });

      $(document).on("click", "#modalNotifyMe", () => {
        setShowHideNotify(!showHide);
      });

      $(document).on("click", "#modalSchedule", () => {
        setShowHideSchedule(!showHide);
      });

      var refreshContentSc = document.createElement("script");
      $(refreshContentSc).text(
        "localStorage.setItem('startDate_sas_97466', 'start'); localStorage.setItem('temp_sas_97466', 'sas_97466'); localStorage.setItem('tempcount_sas_97466', 0);   setInterval(function(){ if(!$('#sas_97466').length){ return true; }; var countsAds=0; var top_of_element = $('#sas_97466').offset().top; var bottom_of_element = $('#sas_97466').offset().top + $('#sas_97466').outerHeight();var bottom_of_screen = $(window).scrollTop() + $(window).innerHeight();var top_of_screen = $(window).scrollTop(); if ((bottom_of_screen > top_of_element) && (top_of_screen < bottom_of_element)){ var NowDate = new Date(); var lastsyncdata=localStorage.getItem('startDate_sas_97466');  if(lastsyncdata=='start'){ console.log(lastsyncdata); localStorage.setItem('startDate_sas_97466', new Date());  return true;} var lastsync =new Date(localStorage.getItem('startDate_sas_97466')); var Diffseconds = (NowDate.getTime() - lastsync.getTime()) / 1000;  var loopCount=localStorage.getItem('tempcount_sas_97466'); var tempGlobalScrollAdsIdsGet = localStorage.getItem('temp_sas_97466'); if(loopCount<5 && Diffseconds>30){ sas.refresh('sas_97466');  localStorage.setItem('tempcount_sas_97466', (Number(localStorage.getItem('tempcount_sas_97466'))+1)); localStorage.setItem('startDate_sas_97466', new Date()); }}},1000)"
      );
      $("#CustomAdsRefreshscript").append(refreshContentSc);

      var refreshContentSc1 = document.createElement("script");
      $(refreshContentSc1).text(
        "localStorage.setItem('startDate_sas_97469', 'start'); localStorage.setItem('temp_sas_97469', 'sas_97469'); localStorage.setItem('tempcount_sas_97469', 0);   setInterval(function(){ if(!$('#sas_97469').length){ return true; }; var countsAds=0; var top_of_element = $('#sas_97469').offset().top; var bottom_of_element = $('#sas_97469').offset().top + $('#sas_97469').outerHeight();var bottom_of_screen = $(window).scrollTop() + $(window).innerHeight();var top_of_screen = $(window).scrollTop(); if ((bottom_of_screen > top_of_element) && (top_of_screen < bottom_of_element)){ var NowDate = new Date(); var lastsyncdata=localStorage.getItem('startDate_sas_97469'); if(lastsyncdata=='start'){ localStorage.setItem('startDate_sas_97469', new Date()); return true;} var lastsync =new Date(localStorage.getItem('startDate_sas_97469')); var Diffseconds = (NowDate.getTime() - lastsync.getTime()) / 1000;  var loopCount=localStorage.getItem('tempcount_sas_97469'); var tempGlobalScrollAdsIdsGet = localStorage.getItem('temp_sas_97469'); if(loopCount<5 && Diffseconds>30){ sas.refresh('sas_97469'); localStorage.setItem('tempcount_sas_97469', (Number(localStorage.getItem('tempcount_sas_97469'))+1)); localStorage.setItem('startDate_sas_97469', new Date()); }}},1000)"
      );
      $("#CustomAdsRefreshscript").append(refreshContentSc1);

      var refreshContentSc2 = document.createElement("script");
      $(refreshContentSc2).text(
        "localStorage.setItem('startDate_sas_97587', 'start'); localStorage.setItem('temp_sas_97587', 'sas_97587'); localStorage.setItem('tempcount_sas_97587', 0);   setInterval(function(){ if(!$('#sas_97587').length){ return true; }; var countsAds=0; var top_of_element = $('#sas_97587').offset().top; var bottom_of_element = $('#sas_97587').offset().top + $('#sas_97587').outerHeight();var bottom_of_screen = $(window).scrollTop() + $(window).innerHeight();var top_of_screen = $(window).scrollTop(); if ((bottom_of_screen > top_of_element) && (top_of_screen < bottom_of_element)){ var NowDate = new Date(); var lastsyncdata=localStorage.getItem('startDate_sas_97587'); if(lastsyncdata=='start'){ localStorage.setItem('startDate_sas_97587', new Date()); return true;} var lastsync =new Date(localStorage.getItem('startDate_sas_97587')); var Diffseconds = (NowDate.getTime() - lastsync.getTime()) / 1000;  var loopCount=localStorage.getItem('tempcount_sas_97587'); var tempGlobalScrollAdsIdsGet = localStorage.getItem('temp_sas_97587'); if(loopCount<5 && Diffseconds>30){ sas.refresh('sas_97587');  localStorage.setItem('tempcount_sas_97587', (Number(localStorage.getItem('tempcount_sas_97587'))+1)); localStorage.setItem('startDate_sas_97587', new Date()); }}},1000)"
      );
      $("#CustomAdsRefreshscript").append(refreshContentSc2);

      var refreshContentSc3 = document.createElement("script");
      $(refreshContentSc3).text(
        "localStorage.setItem('startDate_sas_97588', 'start'); localStorage.setItem('temp_sas_97588', 'sas_97588'); localStorage.setItem('tempcount_sas_97588', 0);   setInterval(function(){ if(!$('#sas_97588').length){ return true; }; var countsAds=0; var top_of_element = $('#sas_97588').offset().top; var bottom_of_element = $('#sas_97588').offset().top + $('#sas_97588').outerHeight();var bottom_of_screen = $(window).scrollTop() + $(window).innerHeight();var top_of_screen = $(window).scrollTop(); if ((bottom_of_screen > top_of_element) && (top_of_screen < bottom_of_element)){ var NowDate = new Date(); var lastsyncdata=localStorage.getItem('startDate_sas_97588'); if(lastsyncdata=='start'){ localStorage.setItem('startDate_sas_97588', new Date());  return true;} var lastsync =new Date(localStorage.getItem('startDate_sas_97588')); var Diffseconds = (NowDate.getTime() - lastsync.getTime()) / 1000;  var loopCount=localStorage.getItem('tempcount_sas_97588'); var tempGlobalScrollAdsIdsGet = localStorage.getItem('temp_sas_97588'); if(loopCount<5 && Diffseconds>30){ sas.refresh('sas_97588'); localStorage.setItem('tempcount_sas_97588', (Number(localStorage.getItem('tempcount_sas_97588'))+1)); localStorage.setItem('startDate_sas_97588', new Date()); }}},1000)"
      );
      $("#CustomAdsRefreshscript").append(refreshContentSc3);

      var refreshContentSc4 = document.createElement("script");
      $(refreshContentSc4).text(
        "localStorage.setItem('startDate_sas_97721', 'start'); localStorage.setItem('temp_sas_97721', 'sas_97721'); localStorage.setItem('tempcount_sas_97721', 0);   setInterval(function(){ if(!$('#sas_97721').length){ return true; }; var countsAds=0; var top_of_element = $('#sas_97721').offset().top; var bottom_of_element = $('#sas_97721').offset().top + $('#sas_97721').outerHeight();var bottom_of_screen = $(window).scrollTop() + $(window).innerHeight();var top_of_screen = $(window).scrollTop(); if ((bottom_of_screen > top_of_element) && (top_of_screen < bottom_of_element)){ var NowDate = new Date(); var lastsyncdata=localStorage.getItem('startDate_sas_97721'); if(lastsyncdata=='start'){ localStorage.setItem('startDate_sas_97721', new Date()); return true;} var lastsync =new Date(localStorage.getItem('startDate_sas_97721')); var Diffseconds = (NowDate.getTime() - lastsync.getTime()) / 1000;  var loopCount=localStorage.getItem('tempcount_sas_97721'); var tempGlobalScrollAdsIdsGet = localStorage.getItem('temp_sas_97721'); if(loopCount<5 && Diffseconds>30){ sas.refresh('sas_97721');  localStorage.setItem('tempcount_sas_97721', (Number(localStorage.getItem('tempcount_sas_97721'))+1)); localStorage.setItem('startDate_sas_97721', new Date()); }}},1000)"
      );
      $("#CustomAdsRefreshscript").append(refreshContentSc4);
      var refreshContentSc5 = document.createElement("script");
      $(refreshContentSc5).text(
        "localStorage.setItem('startDate_sas_97722', 'start'); localStorage.setItem('temp_sas_97722', 'sas_97722'); localStorage.setItem('tempcount_sas_97722', 0);   setInterval(function(){ if(!$('#sas_97722').length){ return true; }; var countsAds=0; var top_of_element = $('#sas_97722').offset().top; var bottom_of_element = $('#sas_97722').offset().top + $('#sas_97722').outerHeight();var bottom_of_screen = $(window).scrollTop() + $(window).innerHeight();var top_of_screen = $(window).scrollTop(); if ((bottom_of_screen > top_of_element) && (top_of_screen < bottom_of_element)){ var NowDate = new Date(); var lastsyncdata=localStorage.getItem('startDate_sas_97722'); if(lastsyncdata=='start'){ localStorage.setItem('startDate_sas_97722', new Date()); return true;} var lastsync =new Date(localStorage.getItem('startDate_sas_97722')); var Diffseconds = (NowDate.getTime() - lastsync.getTime()) / 1000;  var loopCount=localStorage.getItem('tempcount_sas_97722'); var tempGlobalScrollAdsIdsGet = localStorage.getItem('temp_sas_97722'); if(loopCount<5 && Diffseconds>30){ sas.refresh('sas_97722');  localStorage.setItem('tempcount_sas_97722', (Number(localStorage.getItem('tempcount_sas_97722'))+1)); localStorage.setItem('startDate_sas_97722', new Date()); }}},1000)"
      );
      $("#CustomAdsRefreshscript").append(refreshContentSc5);

      var refreshContentSc6 = document.createElement("script");
      $(refreshContentSc6).text(
        "localStorage.setItem('startDate_sas_97723', 'start'); localStorage.setItem('temp_sas_97723', 'sas_97723'); localStorage.setItem('tempcount_sas_97723', 0);   setInterval(function(){ if(!$('#sas_97723').length){ return true; }; var countsAds=0; var top_of_element = $('#sas_97723').offset().top; var bottom_of_element = $('#sas_97723').offset().top + $('#sas_97723').outerHeight();var bottom_of_screen = $(window).scrollTop() + $(window).innerHeight();var top_of_screen = $(window).scrollTop(); if ((bottom_of_screen > top_of_element) && (top_of_screen < bottom_of_element)){ var NowDate = new Date(); var lastsyncdata=localStorage.getItem('startDate_sas_97723'); if(lastsyncdata=='start'){ localStorage.setItem('startDate_sas_97723', new Date());  return true;} var lastsync =new Date(localStorage.getItem('startDate_sas_97723')); var Diffseconds = (NowDate.getTime() - lastsync.getTime()) / 1000;  var loopCount=localStorage.getItem('tempcount_sas_97723'); var tempGlobalScrollAdsIdsGet = localStorage.getItem('temp_sas_97723'); if(loopCount<5 && Diffseconds>30){ sas.refresh('sas_97723');  localStorage.setItem('tempcount_sas_97723', (Number(localStorage.getItem('tempcount_sas_97723'))+1)); localStorage.setItem('startDate_sas_97723', new Date()); }}},1000)"
      );
      $("#CustomAdsRefreshscript").append(refreshContentSc6);

      var refreshContentSc7 = document.createElement("script");
      $(refreshContentSc7).text(
        "localStorage.setItem('startDate_sas_974692', 'start'); localStorage.setItem('temp_sas_974692', 'sas_974692'); localStorage.setItem('tempcount_sas_974692', 0);   setInterval(function(){ if(!$('#sas_974692').length){ return true; }; var countsAds=0; var top_of_element = $('#sas_974692').offset().top; var bottom_of_element = $('#sas_974692').offset().top + $('#sas_974692').outerHeight();var bottom_of_screen = $(window).scrollTop() + $(window).innerHeight();var top_of_screen = $(window).scrollTop(); if ((bottom_of_screen > top_of_element) && (top_of_screen < bottom_of_element)){ var NowDate = new Date(); var lastsyncdata=localStorage.getItem('startDate_sas_974692'); if(lastsyncdata=='start'){ localStorage.setItem('startDate_sas_974692', new Date());  return true;} var lastsync =new Date(localStorage.getItem('startDate_sas_974692')); var Diffseconds = (NowDate.getTime() - lastsync.getTime()) / 1000;  var loopCount=localStorage.getItem('tempcount_sas_974692'); var tempGlobalScrollAdsIdsGet = localStorage.getItem('temp_sas_974692'); if(loopCount<5 && Diffseconds>30){ sas.refresh('sas_974692');  localStorage.setItem('tempcount_sas_974692', (Number(localStorage.getItem('tempcount_sas_974692'))+1)); localStorage.setItem('startDate_sas_974692', new Date()); }}},1000)"
      );
      $("#CustomAdsRefreshscript").append(refreshContentSc7);

      var refreshContentSc8 = document.createElement("script");
      $(refreshContentSc8).text(
        "localStorage.setItem('startDate_sas_97587_2', 'start'); localStorage.setItem('temp_sas_97587_2', 'sas_97587_2'); localStorage.setItem('tempcount_sas_97587_2', 0);   setInterval(function(){ if(!$('#sas_97587_2').length){ return true; }; var countsAds=0; var top_of_element = $('#sas_97587_2').offset().top; var bottom_of_element = $('#sas_97587_2').offset().top + $('#sas_97587_2').outerHeight();var bottom_of_screen = $(window).scrollTop() + $(window).innerHeight();var top_of_screen = $(window).scrollTop(); if ((bottom_of_screen > top_of_element) && (top_of_screen < bottom_of_element)){ var NowDate = new Date(); var lastsyncdata=localStorage.getItem('startDate_sas_97587_2'); if(lastsyncdata=='start'){ localStorage.setItem('startDate_sas_97587_2', new Date()); return true;} var lastsync =new Date(localStorage.getItem('startDate_sas_97587_2')); var Diffseconds = (NowDate.getTime() - lastsync.getTime()) / 1000;  var loopCount=localStorage.getItem('tempcount_sas_97587_2'); var tempGlobalScrollAdsIdsGet = localStorage.getItem('temp_sas_97587_2'); if(loopCount<5 && Diffseconds>30){  sas.refresh('sas_97587_2');  localStorage.setItem('tempcount_sas_97587_2', (Number(localStorage.getItem('tempcount_sas_97587_2'))+1)); localStorage.setItem('startDate_sas_97587_2', new Date()); }}},1000)"
      );
      $("#CustomAdsRefreshscript").append(refreshContentSc8);
      return;
    }
    loadData();
    loadCss();
  }, [content]);

  const loadArticles = async (cat) => {
    setLoadingArticles(true);
    //la respuesta sin el items count -1 trae solo 9 articulos para paginar, seria cuestion de que cuando sean visualizados, hacer el nuevo request de la siguiente página
    await axios
      .get(
        `/get-post-by-category/?category=${cat}&itemscount=-1&timestamp=${new Date().getTime()}`,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json;charset=UTF-8",
          },
        }
      )
      .then((res) => {
        setContentArticles(res.data.data);
        setLoadingArticles(false);
        appendArticles(res.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const loadCreators = async (tag) => {
    if (tag === "guest") {
      setLoadingGuests(true);
    } else if (tag === "expert") {
      setLoadingExperts(true);
    }

    await axios
      .get(`/get-creators-by-tag/?tag=${tag}`, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json;charset=UTF-8",
        },
      })
      .then((res) => {
        // const strRes = JSON.stringify(res.data.data)
        if (tag === "guest") {
          setContentGuests(res.data.data);
          setLoadingGuests(false);
          appendGuests(res.data.data);
        } else if (tag === "expert") {
          setContentExperts(res.data.data);
          setLoadingExperts(false);
          appendExperts(res.data.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getArticle = (article) => {
    var Link;
    if (article.post_type === "watch") {
      Link = `/watch/${article.post_slug}/`;
    } else {
      Link = `/${article.post_slug}/`;
    }
    return (
      <div style={{ padding: 8 }}>
        <div class="article-element">
          <a href={Link}>
            <p class="helvFont article-title">{article.post_title}</p>
            <img
              srcSet={article.imgs.toReversed().map((image, index) => {
                if (image[1] === undefined) {
                  return [];
                }
                const Srcset = [];
                const Zize = image[1].toString();
                const link = image[0].toString();
                Srcset.push(link + " " + Zize + "w");
                return Srcset;
              })}
              style={{ width: "100%" }}
              width="1000px"
              height="563px"
              src={article.post_image.medium_image_url}
              alt={article.post_image.image_title}
              className="img-sizing"
            />
            <p class="helvFont article-content">{article.short_description}</p>
          </a>
        </div>
      </div>
    );
  };

  const getCreator = (creator, type) => {
    return (
      <div style={{ padding: 8 }}>
        <div class="creator-element">
          <a href={"/creator/" + type + "/" + creator.post_slug + "/"}>
            <p class="creator-image-container">
              <img
                style={{ width: "100%" }}
                src={creator.post_image.medium_image_url}
                alt={creator.post_image.image_title}
                className="img-sizing"
                width="1000px"
                height="563px"
              />
            </p>
            <p class="helvFont creator-title">{creator.post_title}</p>
          </a>
        </div>
      </div>
    );
  };

  const createSpan = (cssfile) => {
    $(document).ready(function () {
      $(
        `<link rel="preload" href="${
          mediastaticpages.mediaUrl
        }/wp-content/uploads/visualcomposer-assets/assets-bundles/${cssfile}.source.css?timestamp=${new Date().getTime()}" as="style" onload="this.onload=null;this.rel='stylesheet'">`
      ).appendTo("head");
      $(
        `<noscript><link rel="stylesheet" href="${
          mediastaticpages.mediaUrl
        }/wp-content/uploads/visualcomposer-assets/assets-bundles/${cssfile}.source.css?timestamp=${new Date().getTime()}"></noscript>`
      ).appendTo("head");
    });
  };

  const appendContent = (content) => {
    $("#content-rendered-digital-series").html("");
    $("#content-rendered-digital-series").append(content);
    $("#posts_section").addClass("posts_section");
    var header_part = document.getElementById("header_part");
    if (typeof header_part != "undefined" && header_part != null) {
      var clone = $("#header_part").parent().clone();
      $("#header_part").remove();
      clone.appendTo("#header_part_render");
    }
  };

  const appendArticles = (contentArticles) => {
    let carouselShow = 3; //Desktop
    if (width < 600) {
      carouselShow = 1;
    } //Mobile
    if (width >= 600 && width < 1025) {
      carouselShow = 2;
    } //Tablet
    $("#lyrArticles").html("");
    let articlesCarusel = [];
    contentArticles.forEach((article) => {
      articlesCarusel.push(getArticle(article));
    });
    ReactDOM.render(
      <Carousel
        show={carouselShow}
        infiniteLoop={false}
        children={articlesCarusel}
      />,
      document.getElementById("lyrArticles")
    );
  };

  const appendGuests = (contentGuests) => {
    let carouselShow = 3; //Desktop
    if (width < 600) {
      carouselShow = 1;
    } //Mobile
    if (width >= 600 && width < 1025) {
      carouselShow = 2;
    } //Tablet
    $(".guestSlider").html("");
    let guestCarousel = [];
    contentGuests.forEach((guest) => {
      guestCarousel.push(getCreator(guest, "guest"));
    });
    ReactDOM.render(
      <Carousel
        show={carouselShow}
        infiniteLoop={false}
        children={guestCarousel}
      />,
      document.getElementById("guestSlider")
    );
  };

  const appendExperts = (contentExperts) => {
    let carouselShow = 3; //Desktop
    if (width < 600) {
      carouselShow = 1;
    } //Mobile
    if (width >= 600 && width < 1025) {
      carouselShow = 2;
    } //Tablet
    $(".expertSlider").html("");
    let expertCarousel = [];
    contentExperts.forEach((expert) => {
      expertCarousel.push(getCreator(expert, "expert"));
    });
    ReactDOM.render(
      <Carousel
        show={carouselShow}
        infiniteLoop={false}
        children={expertCarousel}
      />,
      document.getElementById("expertSlider")
    );
  };

  const handleValidation = () => {
    let formIsValid = true;

    //Name
    if (!cname || typeof cname === "undefined") {
      formIsValid = false;
      setNameError("Name should not be empty.");
    } else if (typeof cname !== "undefined" && cname !== "") {
      var pattern = new RegExp(/^(?:[a-zA-Z][a-zA-Z\s]*)?$/);

      if (!pattern.test(cname)) {
        formIsValid = false;
        setNameError("Name should not contain any special chars.");
      }
    }
    //Message
    if (!message || typeof message === "undefined") {
      formIsValid = false;
      setMessageError("Message should not be empty.");
    }

    //Phone
    if (!phone || typeof phone === "undefined") {
      formIsValid = false;
      setPhoneError("Phone No. should not be empty.");
    } else if (typeof phone !== "undefined") {
      if (!phone.match(/^\d+$/)) {
        formIsValid = false;
        setPhoneError("Phone No. should be only in numbers.");
      }
    } else if (phone.length < 9 || phone.length > 15) {
      formIsValid = false;
      setPhoneError("Invalid Phone Number");
    }

    //Email
    if (!email) {
      formIsValid = false;
      setEmailError("Email address should not be empty.");
    } else if (typeof email !== "undefined") {
      let lastAtPos = email.lastIndexOf("@");
      let lastDotPos = email.lastIndexOf(".");
      pattern = new RegExp(
        /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
      );

      if (!pattern.test(email)) {
        formIsValid = false;
        setEmailError("Invalid Email address");
      } else if (
        !(
          lastAtPos < lastDotPos &&
          lastAtPos > 0 &&
          email.indexOf("@@") === -1 &&
          lastDotPos > 2 &&
          email.length - lastDotPos > 2
        )
      ) {
        formIsValid = false;
        setEmailError("Invalid Email address");
      }
    }
    return formIsValid;
  };

  const submitFile = (e) => {
    if (e) {
      e.preventDefault();
    }

    if (handleValidation()) {
      const contactinfo = {
        // name: this.state.cname,
        // email: this.state.email,
        // phone: this.state.phone,
        // message: this.state.message,
        name: cname,
        email: email,
        phone: phone,
        message: message,
      };

      axios
        .post(`/send-mail`, contactinfo)
        .then((res) => {
          setCname("");
          setEmail("");
          setPhone("");
          setMessage("");
          handleModalShowHide();
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      return;
    }
  };

  const handleNameChange = (event) => {
    var cname = event.target.value;

    if (!cname || typeof cname === "undefined") {
    } else if (typeof cname !== "undefined" && cname !== "") {
      var pattern = new RegExp(/^(?:[a-zA-Z][a-zA-Z\s]*)?$/);

      if (!pattern.test(cname)) {
        setNameError("Name should not contain any special chars.");
        // this.setState({
        //   nameError: "Name should not contain any special chars.",
        // });
      } else {
        setNameError(null);
        setCname(event.target.value);
        // this.setState({ nameError: null });
        // this.setState({ cname: event.target.value });
      }
    }
  };
  const handleEmailChange = (event) => {
    var email = event.target.value;
    //Email
    if (!email) {
      setEmailError("Email address should not be empty.");
      // this.setState({
      //   emailError: "Email address should not be empty.",
      // });
    } else if (typeof email !== "undefined") {
      let lastAtPos = email.lastIndexOf("@");
      let lastDotPos = email.lastIndexOf(".");
      var pattern = new RegExp(
        /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
      );

      if (!pattern.test(email)) {
        setEmailError("Invalid Email address");
        // this.setState({
        //   emailError: "Invalid Email address",
        // });
      } else if (
        !(
          lastAtPos < lastDotPos &&
          lastAtPos > 0 &&
          email.indexOf("@@") === -1 &&
          lastDotPos > 2 &&
          email.length - lastDotPos > 2
        )
      ) {
        setEmailError("Invalid Email address");
        // this.setState({
        //   emailError: "Invalid Email address",
        // });
      } else {
        setEmailError(null);
        setEmail(event.target.value);
        // this.setState({ emailError: null });
        // this.setState({ email: event.target.value });
      }
    }
  };
  const handlePhoneChange = (event) => {
    //Phone
    var phone = event.target.value;

    if (!phone || typeof phone === "undefined") {
      setPhoneError("Phone No. should not be empty");
      // this.setState({
      //   phoneError: "Phone No. should not be empty",
      // });
    } else if (typeof phone !== "undefined") {
      if (!phone.match(/^\d+$/)) {
        setPhoneError("Phone No. should be only in number");
        // this.setState({
        //   phoneError: "Phone No. should be only in number",
        // });
      } else if (phone.length < 9 || phone.length > 15) {
        setPhoneError("Invalid Phone Number");
        // this.setState({
        //   phoneError: "Invalid Phone Number",
        // });
      } else {
        setPhoneError(null);
        setPhone(event.target.value);
        // this.setState({ phoneError: null });
        // this.setState({ phone: event.target.value });
      }
    }
  };
  const handleMessageChange = (event) => {
    var message = event.target.value;

    if (!message || typeof message === "undefined") {
      setMessageError("File URL Should not be empty.");
      // this.setState({
      //   messageError: "File URL Should not be empty.",
      // });
    } else {
      setMessageError(null);
      setMessage(event.target.value);
      // this.setState({ messageError: null });
      // this.setState({ message: event.target.value });
    }
  };

  useEffect(() => {
    window.handleModalShowHide = handleModalShowHideRef.current;
  }, []);

  const handleModalShowHide = () => {
    setShowHide(!showHide);
    // this.setState({ showHide: !this.state.showHide });
  };
  const handleModalShowHideNotify = () => {
    setShowHideNotify(!showHide);
    // this.setState({ showHideNotify: !this.state.showHideNotify });
  };
  const handleModalShowHideSchedule = () => {
    setShowHideSchedule(!showHide);
    // this.setState({ showHideSchedule: !this.state.showHideSchedule });
  };

  return (
    <>
      <section
        id="read-section digitalseries"
        className="read-section mt-0 mt-md-5"
      >
        <div className="vcv-content--blank vcv-content--boxed">
          <article id="" className="page type-page status-publish hentry entry">
            <div className="entry-content" id="content-rendered-digital-series">
              {/* <div id="content-rendered"> */}
              {/* dangerouslySetInnerHTML={{ __html: content }}> */}
              {/* </div> */}
            </div>
          </article>
        </div>

        <div id="CustomAdsRefreshscript"></div>
      </section>
      <Modal show={showHide} dialogClassName="my-modal" animation={false}>
        <Modal.Header closeButton onClick={handleModalShowHide}>
          <Modal.Title>Submit File</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div
            className="wpforms-container wpforms-container-full"
            id="wpforms-504"
          >
            <form onSubmit={() => submitFile}>
              <div className="wpforms-field-container">
                <div>
                  <label
                    className="wpforms-field-label wpforms-label-hide"
                    htmlFor="wpforms-504-field_0"
                  >
                    Name <span className="wpforms-required-label">*</span>{" "}
                    <span className="label-danger">{nameError}</span>
                  </label>
                </div>
                <div>
                  <input
                    type="text"
                    onChange={handleNameChange}
                    id="wpforms-504-field_0"
                    className="form-control"
                    name="wpforms[fields][0]"
                    placeholder="Name *"
                    required=""
                  />
                </div>
                <div>
                  <label
                    className="wpforms-field-label wpforms-label-hide"
                    htmlFor="wpforms-504-field_1"
                  >
                    Email <span className="wpforms-required-label">*</span>{" "}
                    <span className="label-danger">{emailError}</span>
                  </label>
                </div>
                <div>
                  <input
                    type="email"
                    onChange={handleEmailChange}
                    id="wpforms-504-field_1"
                    className="form-control"
                    name="wpforms[fields][1]"
                    placeholder="Email *"
                    required=""
                  />
                </div>
                <div>
                  <label
                    className="wpforms-field-label wpforms-label-hide"
                    htmlFor="wpforms-504-field_3"
                  >
                    Phone <span className="wpforms-required-label">*</span>{" "}
                    <span className="label-danger">{phoneError}</span>
                  </label>
                </div>
                <div>
                  <input
                    type="number"
                    onChange={handlePhoneChange}
                    id="wpforms-504-field_3"
                    className="form-control"
                    name="wpforms[fields][3]"
                    placeholder="Phone *"
                  />
                </div>
                <div>
                  <label
                    className="wpforms-field-label wpforms-label-hide"
                    htmlFor="wpforms-504-field_2"
                  >
                    File URL <span className="wpforms-required-label">*</span>{" "}
                    <span className="label-danger">{messageError}</span>
                  </label>
                </div>
                <div>
                  <input
                    type="text"
                    className="form-control"
                    onChange={handleMessageChange}
                    placeholder="Drive, Dropbox, etc."
                    autoComplete="off"
                  />
                </div>
              </div>
            </form>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={() => submitFile()}>
            Ok
          </Button>
        </Modal.Footer>
      </Modal>
      {/* <Modal
        show={this.state.showHideSchedule}
        dialogClassName="my-modal"
        animation={false}
      >
        <Modal.Header
          closeButton
          onClick={() => this.handleModalShowHideSchedule()}
        >
          <Modal.Title>Schedule</Modal.Title>
        </Modal.Header>
        <Modal.Body className="modal-body my-modal-schedule-body">
          <div dangerouslySetInnerHTML={{ __html: scheduleContent }} />
        </Modal.Body>
      </Modal> */}
    </>
  );
};

export default LivingAndReadyComponent;
