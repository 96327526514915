const idDict = {
  afrolatino: 1701580,
  "afrolatino-beauty": 1701585,
  "afrolatino-culture": 1701586,
  "afrolatino-entertainment": 1701587,
  "afrolatino-fashion": 1701588,
  "afrolatino-food": 1701589,
  "afrolatino-health": 1701590,
  "afrolatino-lifestyle": 1701591,
  "afrolatino-music": 1701592,
  "afrolatino-sports": 1701593,
  "afrolatino-news": 1734025,
  culture: 1701581,
  "culture-art": 1701595,
  "culture-beauty": 1701596,
  "culture-culture": 1701597,
  "culture-entertainment": 1701598,
  "culture-entrepreneurship": 1701599,
  "culture-fashion": 1701600,
  "culture-food": 1701601,
  "culture-health": 1701602,
  "culture-lifestyle": 1701603,
  "culture-music": 1701604,
  "culture-news": 1701605,
  "culture-science-and-technology": 1701606,
  "culture-sports": 1701607,
  latina: 1701582,
  "latina-beauty": 1701608,
  "latina-culture": 1701609,
  "latina-entertainment": 1701610,
  "latina-entrepreneurship": 1701611,
  "latina-fashion": 1701612,
  "latina-food": 1701613,
  "latina-health": 1701614,
  "latina-lifestyle": 1701615,
  "latina-music": 1701616,
  "latina-news": 1701617,
  "latina-science-and-technology": 1701618,
  "latina-sports": 1701619,
  queer: 1701583,
  "queer-beauty": 1701620,
  "queer-culture": 1701621,
  "queer-entertainment": 1701622,
  "queer-fashion": 1701623,
  "queer-food": 1701624,
  "queer-health": 1701625,
  "queer-lifestyle": 1701626,
  "queer-music": 1701627,
  "queer-news": 1701628,
  "queer-sports": 1701629,
  lgbtq: 1701583,
  "lgbtq-beauty": 1701620,
  "lgbtq-culture": 1701621,
  "lgbtq-entertainment": 1701622,
  "lgbtq-fashion": 1701623,
  "lgbtq-food": 1701624,
  "lgbtq-health": 1701625,
  "lgbtq-lifestyle": 1701626,
  "lgbtq-music": 1701627,
  "lgbtq-news": 1701628,
  "lgbtq-sports": 1701629,
  livingyready: 1794826,
};

export const getPageId = (pageSlug) => {
  const lowerSlug = pageSlug.toLowerCase();
  return idDict[lowerSlug];
};
