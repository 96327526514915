import React, { Component } from "react";
import appStore from "../../images/ios_download.png";
import googlePlay from "../../images/play_download.png";
// import MailchimpNewFormComponent from "./Mailchimp/MailchimpNewFormComponent";

class SubscribeComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      matches: window.matchMedia("(min-width: 769px)").matches,
    };
  }

  componentDidMount() {
    const handler = (e) => this.setState({ matches: e.matches });
    window.matchMedia("(min-width: 769px)").addEventListener("change", handler);
  }

  render() {
    return (
      <div className="container queer gradientPink sub-container">
        <div className="center">
          <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
            <h2 className="sub-title">WANT TO SEE OUR FULL EPISODES?</h2>
            {this.state.matches ? (
              <a href={`/download/`}>
                <button className="latvAppBtn d-flex subscribeButton3">
                  WATCH NOW
                </button>
              </a>
            ) : (
              <>
                <div className="row buttons">
                  <div className="col-6">
                    <a
                      href="https://apps.apple.com/us/app/latv-mobile/id1064872621"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img
                        src={appStore}
                        className="img-fluid "
                        // style={{ width: "40%" }}
                        alt="LATV iOS App"
                      />
                    </a>
                  </div>
                  <div className="col-6">
                    <a
                      href="https://play.google.com/store/apps/details?id=com.brightcove.latv&hl=en_US"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img
                        src={googlePlay}
                        className="img-fluid "
                        // style={{ width: "40%" }}
                        alt="LATV Android App"
                      />
                    </a>
                  </div>
                </div>
              </>
            )}
          </div>
          {/* <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
            <MailchimpNewFormComponent category={"/queer"} type={"content"} />
          </div> */}
        </div>
      </div>
    );
  }
}

export default SubscribeComponent;
